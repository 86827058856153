import { useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  assignNewTokenToStorageAndStore,
  logout,
  selectAuth,
} from '@store/slices';
import SessionTimerBar from './SessionTimerBar';
import { Toast, Sentry, HotToast } from '@utils';
import { Api } from '@api';
import { BUILD_USAGE } from '@constants';
import { User } from '@types';

import styles from './index.module.scss';

type Props = {
  className?: string;
};

export default function SessionExtendFloatingButton({ className }: Props) {
  const { user, token } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const [showSessionTimerBar, setShowSessionTimerBar] = useState(false);
  const [isExtendWaiting, setIsExtendWaiting] = useState(false);
  const visible = useSessionExtendFloatingButtonVisible(user?.expirationTime);

  const handleButtonClick = useCallback(() => {
    setShowSessionTimerBar(!showSessionTimerBar);
  }, [showSessionTimerBar]);

  const handleSessionTimerBarOpenTrigger = useCallback(() => {
    setShowSessionTimerBar(true);
  }, []);

  const handleSessionTimerBarClose = useCallback(() => {
    setShowSessionTimerBar(false);
  }, []);

  const handleExtendClick = useCallback(async () => {
    if (!token || !user) {
      return dispatch(logout());
    }

    //#region 1. 현재 local school id parse
    //TODO: localSchoolId 읽는 것과 관련해서 석현님과 논의 필요 - 창덕
    let currentLocalSchoolId: null | number;
    try {
      currentLocalSchoolId =
        jwtDecode<User.TDecodedToken>(token).local_school_id;
    } catch (error) {
      HotToast.error('로컬 캠퍼스 ID를 읽을 수 없습니다.');
      Sentry.captureMessage('failed_to_read_localschool_id', {
        extra: {
          token,
          error,
        },
      });
      return;
    }
    //#endregion

    //#region 2. refreshToken API 호출
    let newToken: string;
    setIsExtendWaiting(true);
    try {
      newToken = (
        await Api.auth.refreshToken({
          token,
          localSchoolId: currentLocalSchoolId,
        })
      ).data?.token;
      setIsExtendWaiting(false);
    } catch (error: any) {
      Sentry.captureException(error);
      HotToast.error(
        `${
          error?.response?.data?.message || error?.message || '토큰 갱신 실패'
        }`,
      );
      setIsExtendWaiting(false);
      return;
    }
    //#endregion

    // 3. 업데이트된 token을 store에 저장
    dispatch(
      assignNewTokenToStorageAndStore({ shallow: true, token: newToken }),
    );
  }, [dispatch, token, user]);

  useSessionTimerBarOpenTrigger(
    handleSessionTimerBarOpenTrigger,
    user?.expirationTime,
  );

  if (!user || user.expirationTime === undefined || !visible) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.sessionExtendFloatingButtonWrapper,
        className,
      )}
    >
      <button
        onClick={handleButtonClick}
        className={classNames({
          [styles.active]: showSessionTimerBar,
        })}
      >
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="50" height="50" rx="3" fill="#442D00" />
          <path
            d="M25 35C22.6794 35 20.4538 34.0781 18.8128 32.4372C17.1719 30.7963 16.25 28.5707 16.25 26.25C16.25 23.9294 17.1719 21.7038 18.8128 20.0628C20.4538 18.4219 22.6794 17.5 25 17.5C27.3206 17.5 29.5462 18.4219 31.1872 20.0628C32.8281 21.7038 33.75 23.9294 33.75 26.25C33.75 28.5707 32.8281 30.7963 31.1872 32.4372C29.5462 34.0781 27.3206 35 25 35ZM25 15C22.0163 15 19.1548 16.1853 17.045 18.2951C14.9353 20.4048 13.75 23.2663 13.75 26.25C13.75 29.2337 14.9353 32.0952 17.045 34.205C19.1548 36.3147 22.0163 37.5 25 37.5C27.9837 37.5 30.8452 36.3147 32.955 34.205C35.0647 32.0952 36.25 29.2337 36.25 26.25C36.25 23.2663 35.0647 20.4048 32.955 18.2951C30.8452 16.1853 27.9837 15 25 15V15ZM25.625 20H23.75V27.5L29.6875 31.0625L30.625 29.525L25.625 26.5625V20ZM19.85 14.2375L18.25 12.325L12.5 17.1375L14.1125 19.05L19.85 14.2375ZM37.5 17.15L31.75 12.325L30.1375 14.2375L35.8875 19.0625L37.5 17.15Z"
            fill="#FFD000"
          />
        </svg>
      </button>
      {showSessionTimerBar && (
        <SessionTimerBar
          disabledExtendButton={isExtendWaiting}
          expirationTime={user.expirationTime}
          onCloseClick={handleSessionTimerBarClose}
          onExtendClick={handleExtendClick}
        />
      )}
    </div>
  );
}

function useSessionExtendFloatingButtonVisible(expirationTime?: number) {
  const [visible, setVisible] = useState(
    expirationTime === undefined
      ? false
      : Date.now() - expirationTime < MINIMUM_VISIBLE_TIME,
  );
  useEffect(() => {
    if (expirationTime === undefined) {
      setVisible(false);
      return;
    }

    let visible = Date.now() - expirationTime < MINIMUM_VISIBLE_TIME;
    setVisible(visible);
    const interval = window.setInterval(() => {
      const newVisible = Date.now() - expirationTime < MINIMUM_VISIBLE_TIME;
      if (newVisible !== visible) {
        visible = newVisible;
        setVisible(visible);
      }
    }, 250);
    return () => {
      window.clearInterval(interval);
    };
  }, [expirationTime]);

  return visible;
}

function useSessionTimerBarOpenTrigger(
  callback: () => void,
  expirationTime?: number,
) {
  useEffect(() => {
    if (expirationTime === undefined) {
      return;
    }
    let remainTime = expirationTime - Date.now();
    window.setInterval(() => {
      const newRemainTime = expirationTime - Date.now();
      if (
        SESSION_TIMER_BAR_OPEN_TRIGGER_MILLISECONDS.some(
          (ms) => remainTime > ms && ms >= newRemainTime,
        )
      ) {
        callback();
      }

      remainTime = newRemainTime;
    }, 250);
    return () => {};
  }, [callback, expirationTime]);
}

const SESSION_TIMER_BAR_OPEN_TRIGGER_MILLISECONDS = (function () {
  switch (BUILD_USAGE) {
    case 'kdc':
      return [30 * 60 * 1000, 20 * 60 * 1000, 10 * 60 * 1000];
    default:
      return [60 * 60 * 1000, 30 * 60 * 1000, 10 * 60 * 1000];
  }
})();

const MINIMUM_VISIBLE_TIME = 90 * 60 * 1000;
