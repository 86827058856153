import React from 'react';
import { Text, Card, Link } from '../../../atoms';
import './recentNode.scss';

export const RecentNode = ({ recentNode, onClick, ...props }) => {
  console.log('0823 recentNode', recentNode);
  const nodeSchedule = recentNode.node_schedule;
  const nodeTitle = nodeSchedule.node_title;

  return (
    <div className="recent-node" onClick={() => onClick(recentNode)}>
      <div className="recent-node__title">
        <Text label="진행중인 강의" opacity={0.65} />
      </div>
      <div className="recent-node__content">
        <Card>
          <div className="content__bottom">
            <div className="content__maintitle">
              <Text label={nodeTitle} size="md" />
            </div>
            <div className="content__link">
              <Link variant="underline" hasArrow />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

RecentNode.defaultProps = {
  recentNode: {
    node_schedule: {
      node_title: 'Node Title Placeholder',
    },
  },
};
