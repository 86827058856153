import { instance } from '../provider';

export const updateUserRubricEvaluation = (nodeProgrsId, scoreList) => {
  let body = new FormData();
  body.append('node_progrs_id', nodeProgrsId);
  scoreList.forEach((data, index) => {
    body.append(`rubric_${index + 1}_score`, data.score);
  });

  return instance.post(`/api-evaluation/reUpdateUserEval/`, body);
};

/**
 *
 * @param {Number} userId
 * @returns
 */
export const deleteUserAllMacAddress = (userId) => {
  return instance.post(`/api-dashboard/user/${userId}/clear_user_macaddr/`);
};
