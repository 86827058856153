import classNames from 'classnames';
import { Text, Card, Divider, Progress } from '../../atoms';
import { ProjectRubric } from '../../organisms';
import { StepBadge } from './StepBadge';
import './stepcard.scss';
//TODO: ...props assign된 곳 없음
export const StepCard = ({ node, onClick, ...props }) => {
  console.log('0823 nodeCard ', node);
  const { status, node_schedule } = node;

  const nodeSchedule = node_schedule;

  const disabledStyle = {
    color: '#A3A3A3',
  };

  const isProjectNode = node.node_schedule.node_type.is_project;

  return (
    <div
      className={classNames('step-wrap', { 'step--disabled': status === 'A' })}
    >
      <Card onClick={() => onClick(node)}>
        <div className="step-content">
          <div className="step-content__title">
            <Text
              label={nodeSchedule.node_title}
              style={status === 'A' && disabledStyle}
              size="lg"
            />
            <StepBadge label="project" />
          </div>
          <div className="step-content__status">
            {status === 'A' && (
              <Text
                label="이전 노드를 완료해주세요"
                style={disabledStyle}
                size="xs"
              />
            )}
            {status !== 'A' && (
              <Progress size="md" progress={node.progrs_pct} />
            )}
          </div>
        </div>
        {isProjectNode && (
          <div>
            <Divider direction="horizontal" />
            <ProjectRubric node={node} comment={node.comment} />
          </div>
        )}
      </Card>
    </div>
  );
};

StepCard.defaultProps = {
  step: {},
};
