import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import ReactGA from 'react-ga';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import { HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import store from '@store';
import { Sentry } from '@utils';
import { BUILD_USAGE, GA_KEY, META_PIXEL_ID } from './constants';
import { Datadog } from '@utils/monitoring';

Sentry.initSentry();
Datadog.initDataDog();
ReactGA.initialize(GA_KEY);

if (BUILD_USAGE === 'kdc') {
  if (META_PIXEL_ID) {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(META_PIXEL_ID);
        ReactPixel.pageView();
      });
  }
}

const app = (
  // <React.StrictMode>
  <Provider store={store}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
    <Toaster />
    <ToastContainer />
  </Provider>
  // </React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
