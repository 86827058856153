import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Title, Button, Text } from '../../atoms';
import placeholderImage from './imagePlaceholder.png';
import styled from 'styled-components';
import './purchaseConfirmTemplate.scss';
import { isMobileState } from '../../../mobile-detector';
import { useRecoilValue } from 'recoil';

export const PurchaseConfirmTemplate = ({
  imageUrl,
  courseTitle,
  courseRoundNumber,
  courseRound,
  coursePrice,
  hrdUrl,
  onClick,
}) => {
  const imageRef = useRef();
  const [isImageLoading, setIsImageLoading] = useState(false);
  const complete = imageRef.current && imageRef.current.complete;
  const isMobile = useRecoilValue(isMobileState);

  return (
    <div className="purchaseConfirm">
      <div className="purchaseConfirm__title">
        {isMobile ? (
          <>
            <Title label="Thank you!" size="lg" />
            <strong>구매가 완료되었습니다.</strong>
            <p>관리자가 승인한 후, 선택한 기간에만 수강할 수 있습니다.</p>
          </>
        ) : (
          <Title label="Let's go!" size="lg" />
        )}
      </div>
      <div className="purchaseConfirm__content">
        <div className="purchaseConfirm__image">
          {!complete && <img src={placeholderImage} alt="placeholder" />}
          <img
            src={imageUrl}
            alt="course"
            style={!complete ? { visibility: 'hidden' } : {}}
            ref={imageRef}
            onLoad={() => setIsImageLoading(true)}
          />
        </div>

        <div className="purchaseConfirm__detail">
          <div className="purchaseConfirm__message">
            {!isMobile && <Text size="md" label="구매완료 및 추가신청 안내" />}
            <div className="alert-message-box">
              본 과정은 고용노동부 지원과정으로 HRD-NET 추가신청이 필요합니다.
            </div>
            <Button
              variant="primary"
              onClick={() => window.open(hrdUrl, '_blank')}
            >
              {isMobile
                ? `HRD-NET 등록하러가기`
                : `${courseTitle}과정 ${courseRoundNumber}회차 HRD-NET 등록하러가기`}
            </Button>
          </div>
          <div className="purchaseConfirm__info">
            <div className="courseTitle">{courseTitle}</div>
            <div className="courseRound">{courseRound}</div>
            <div className="coursePrice--flex">
              <div className="priceLabel">
                <div>결제금액</div>
              </div>
              <div className="priceNumber">{coursePrice}원</div>
            </div>
          </div>
          <Button variant="primary" size="lg" onClick={onClick}>
            확인
          </Button>
        </div>
      </div>
    </div>
  );
};

PurchaseConfirmTemplate.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  courseTitle: PropTypes.string.isRequired,
  courseRound: PropTypes.string.isRequired,
  coursePrice: PropTypes.string.isRequired,
};

PurchaseConfirmTemplate.defaultProps = {
  imageUrl:
    'https://d2b196b11hmtij.cloudfront.net/media/courses/2021/07/20/MicrosoftTeams-image_16.png',
  courseTitle: '캐글러로 가는 데이터분석 첫걸음',
  courseRound: '1회차 (2021.09.18 ~ 2021.11.18)',
  coursePrice: '30,000',
};
