import PropTypes from 'prop-types';

import ArrowRight from '../../assets/arrowRight.svg';

import './link.scss';

export const Link = ({ variant, color, label, hasArrow }) => {
  if (variant === 'underline') {
    return (
      <div className="link-wrap">
        <div className="link-underline" style={{ color }}>
          {label} {hasArrow && <img src={ArrowRight} alt="ArrowRight" />}
        </div>
      </div>
    );
  }
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
};

Link.defaultProps = {
  label: 'Continue',
  hasArrow: false,
};
