import React from 'react';
import './table.scss';

const Header = ({ headList }) => {
  return (
    <thead className="aui-thead">
      <tr>
        {headList.map((item, index) => (
          <th key={index}>{item}</th>
        ))}
      </tr>
    </thead>
  );
};
Header.defaultProps = {
  headList: [],
};

const Wrap = ({ children, textAlign = 'left' }) => {
  return (
    <table className="aui-table" style={{ textAlign }}>
      {children}
    </table>
  );
};

const Body = ({ children }) => {
  return <tbody className="aui-tbody">{children}</tbody>;
};

const Row = ({ children }) => {
  return <tr className="aui-trow">{children}</tr>;
};

const Cell = ({ index, children }) => {
  return (
    <td key={index} className="aui-tcell">
      {children}
    </td>
  );
};

const Footer = ({ children }) => (
  <tfoot className="aui-tfoot">{children}</tfoot>
);

export { Header, Wrap, Body, Row, Cell, Footer };
