import PropTypes from 'prop-types';
import classNames from 'classnames';

import './textbox.scss';

const dummyText = (
  <>
    국가는 전통문화의 계승·발전과 민족문화의 창달에 노력하여야 한다. 이 헌법시행
    당시에 이 헌법에 의하여 새로 설치될 기관의 권한에 속하는 직무를 행하고 있는
    기관은 이 헌법에 의하여 새로운 기관이 설치될 때까지 존속하며 그 직무를
    행한다.
    <br />
    <br />
    통신·방송의 시설기준과 신문의 기능을 보장하기 위하여 필요한 사항은 법률로
    정한다. 법률안에 이의가 있을 때에는 대통령은 제1항의 기간내에 이의서를 붙여
    국회로 환부하고, 그 재의를 요구할 수 있다. 국회의 폐회중에도 또한 같다.
    <br />
    국가는 전통문화의 계승·발전과 민족문화의 창달에 노력하여야 한다. 이 헌법시행
    당시에 이 헌법에 의하여 새로 설치될 기관의 권한에 속하는 직무를 행하고 있는
    기관은 이 헌법에 의하여 새로운 기관이 설치될 때까지 존속하며 그 직무를
    행한다.
    <br />
    통신·방송의 시설기준과 신문의 기능을 보장하기 위하여 필요한 사항은 법률로
    정한다. 법률안에 이의가 있을 때에는 대통령은 제1항의 기간내에 이의서를 붙여
    국회로 환부하고, 그 재의를 요구할 수 있다. 국회의 폐회중에도 또한 같다.
  </>
);

export const TextBox = ({
  className,
  fitContent,
  variant,
  size,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'sb-textbox',
        `sb-textbox--${variant}`,
        `sb-textbox--${size}`,
        { 'sb-textbox--fit-content': fitContent },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

TextBox.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  fitContent: PropTypes.bool,
};

TextBox.defaultProps = {
  variant: 'primary',
  size: 'lg',
  children: dummyText,
};
