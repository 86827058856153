import { SVGProps } from 'react';

export const CourseImagePlaceholder: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="246"
      height="50"
      viewBox="0 0 246 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1341_1883)">
        <path d="M246 45.0854H225.095V49.9999H246V45.0854Z" fill="black" />
        <path
          d="M199.777 45.0854H178.872V49.9999H199.777V45.0854Z"
          fill="black"
        />
        <path
          d="M199.777 22.5459H178.872V27.4604H199.777V22.5459Z"
          fill="black"
        />
        <path d="M199.777 0H178.872V4.91448H199.777V0Z" fill="black" />
        <path d="M219.371 0H214.415V50H219.371V0Z" fill="black" />
        <path d="M172.983 0H168.027V50H172.983V0Z" fill="black" />
        <path
          d="M153.306 22.5459H132.401V27.4604H153.306V22.5459Z"
          fill="black"
        />
        <path d="M153.306 0H132.401V4.91448H153.306V0Z" fill="black" />
        <path d="M126.512 0H121.556V50H126.512V0Z" fill="black" />
        <path
          d="M106.829 22.5459H85.9243V27.4604H106.829V22.5459Z"
          fill="black"
        />
        <path d="M106.829 0H85.9243V4.91448H106.829V0Z" fill="black" />
        <path d="M80.041 0H75.085V50H80.041V0Z" fill="black" />
        <path d="M59.4428 0H54.4868V50H59.4428V0Z" fill="black" />
        <path
          d="M18.7727 7.40972L29.4349 31.4374H19.2157L17.1128 36.3518H31.6087L37.6635 49.9999L43.0448 49.9296L21.4958 1.32373L18.7727 7.40972Z"
          fill="black"
        />
        <path
          d="M15.9196 13.6187L0 49.9296L5.38134 49.9999L18.7727 19.4L15.9196 13.6187Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1341_1883">
          <rect width="246" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
