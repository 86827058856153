import { toast, ToastPosition, ToastOptions } from 'react-hot-toast';

interface TOptions {
  position: ToastPosition | undefined;
  icon?: string | undefined;
  duration?: number | undefined;
}

type TMessage = string | JSX.Element | any;

class Toast {
  options: ToastOptions;
  constructor() {
    this.options = {
      position: 'top-center',
      duration: 3000,
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
      },
    };
  }
  public success(message: TMessage, options?: ToastOptions) {
    toast.success(message, { ...this.options, ...options });
  }

  public error(message: TMessage, options?: ToastOptions) {
    toast.error(message, { ...this.options, ...options });
  }

  public custom(message: TMessage) {
    toast.custom(message, this.options);
  }

  public loading(message: TMessage) {
    toast.loading(message, this.options);
  }

  public dismiss(toastId?: string) {
    toast.dismiss(toastId);
  }
}

export default new Toast();
