import React from 'react';
import PropTypes from 'prop-types';
import './radio.scss';

export const Radio = ({ onChange }) => {
  return (
    <div className="radio__wrapper">
      <div className="radio__title">성별</div>
      <div className="radio__group">
        <input
          className="radio__input"
          type="radio"
          value="M"
          name="gender"
          id="M"
          onChange={onChange}
        />
        <label className="radio__label" htmlFor="M">
          남성
        </label>
        <input
          className="radio__input"
          type="radio"
          value="F"
          name="gender"
          id="F"
          onChange={onChange}
        />
        <label className="radio__label" htmlFor="F">
          여성
        </label>
      </div>
    </div>
  );
};

Radio.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  gender: PropTypes.string,
};

Radio.defaultProps = {};
