import { ACCESS_TOKEN } from '../constants';

export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const getBearerToken = () => {
  return `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
};
