import React from 'react';
import { BUILD_USAGE } from '../constants';
import { TRoute } from './TRoute';

const AccountPage = React.lazy(() => import('@legacy/kdc/Account/AccountPageV2'));
const AskContainerPage = React.lazy(() => import('../legacy/pages/Forum/Ask/AskContainer'));
const AttendancePage = React.lazy(() => import('@pages/Attendance'));
const SelectCampus = React.lazy(() => import('@pages/SelectCampus'));
const ClassroomDetailPage = React.lazy(() => import('@pages/kdc/ClassroomDetail'));
const ClassroomPage = React.lazy(() => import('@legacy/kdc/Classroom/ClassroomPageV2'));
const FindPassword = React.lazy(() => import('@pages/FindPassword'));
const ForumPage = React.lazy(() => import('@legacy/pages/Forum/ForumPage'));
const ForumSearchPage = React.lazy(() => import('@legacy/pages/Forum/Search/ForumSearch'));
const HijackPage = React.lazy(() => import('@pages/Hijack'));
const NodesPage = React.lazy(() => import('@pages/NodeDetail'));
const NoticePage = React.lazy(() => import('@legacy/pages/Notice/NoticeContainer'));
const PrintPage = React.lazy(() => import('@pages/kdc/Print'));
const RedirectPage = React.lazy(() => import('@pages/RedirectPage'));
const ResetPassword = React.lazy(() => import('@pages/ResetPassword'));
const StepsPage = React.lazy(() => import('@pages/StepPage'));

const TopicContainerPage = React.lazy(() => import('@legacy/pages/Forum/ForumContainer'));
const TopicDetailContainerPage = React.lazy(() => import('@legacy/pages/Forum/Topic/TopicDetailContainer'));

const TopicDetailPage = React.lazy(() => import('@legacy/pages/Forum/Topic/TopicDetailPage'));

const IntegratedPages = {
  SignIn: React.lazy(() => import('@pages/Integrated/SignIn')),
  SignUp: React.lazy(() => import('@pages/Integrated/SignUp')),
  TermsOfUse: React.lazy(() => import('@pages/Integrated/TermsAndConditions/TermsOfUsePage')),
  PrivacyPolicy: React.lazy(() => import('@pages/Integrated/TermsAndConditions/PrivatePolicyPage')),
  RefundPolicy: React.lazy(() => import('@pages/Integrated/TermsAndConditions/RefundPolicyPage')),
  marketingAgreement: React.lazy(() => import('@pages/Integrated/TermsAndConditions/MarketingAgreementPage')),
};
const RegisterInvitedUserPage = React.lazy(() => import('@pages/Integrated/RegisterInvitedUser'));
/** 공지사항 */
const NoticeBoard = React.lazy(() => import('@pages/Notice/Board'));
const NoticePost = React.lazy(() => import('@pages/Notice/Post'));

const ContentsSamplePage = React.lazy(() => import('@pages/lms/ContentsSample'));

export const NoticeBoardPageRoute: TRoute = { path: '/notice', component: NoticeBoard, private: true };
export const NoticePostPageRoute: TRoute = { path: '/notice/:postId', component: NoticePost, private: true };

/** 비밀번호 */
export const FindPasswordPageRoute: TRoute = { path: '/find-password', component: FindPassword, exact: true, publicOnly: true };
export const ResetPasswordPageRoute: TRoute = { path: '/reset-password', component: ResetPassword, exact: true, publicOnly: true };

/** Release Note 페이지 */
export const ReleaseNotePageRoute: TRoute = { path: '/release', component: NoticePage, private: true };

/** Node상세 페이지 */
export const NodesPageRoute: TRoute = {
  path: '/course/:courseId/node/:nodeId',
  component: NodesPage,
  private: true,
};

/** Classroom page */
export const ClassroomPageRoute: TRoute = { path: '/classroom', component: ClassroomPage, private: true };
export const ClassroomDetailPageRoute: TRoute = { path: '/classroom/:id', component: ClassroomDetailPage, private: true };

/** 수료증 발급 페이지 */
export const PrintPageRoute: TRoute = { path: '/achieve/print', component: PrintPage };

/** Step 페이지 */
export const StepsPageRoute: TRoute = {
  path: '/course/:courseId/node/:nodeId/step/:stepId',
  component: StepsPage,
  private: true,
};
export const ReadonlyStepsPageRoute: TRoute = {
  path: '/course/:courseId/node/:nodeId/step/:stepId/readonly',
  component: StepsPage,
  private: true,
};

/** Account 설정 페이지 */
export const AccountPageRoute: TRoute = { path: '/account', component: AccountPage };

export const TopicContainerPageRoute: TRoute = { path: '/forum', component: TopicContainerPage, private: true };
export const TopicDetailPageRoute: TRoute = { path: '/forum/:id', component: TopicDetailPage, private: true };

export const ForumPageRoute: TRoute = { path: '/forum2', component: ForumPage, private: true };
export const TopicDetailContainerPageRoute: TRoute = { path: '/forum2/:id', component: TopicDetailContainerPage, private: true };

export const ForumSearchPageRoute: TRoute = { path: '/search/forum', component: ForumSearchPage, private: true };
export const AskContainerPageRoute: TRoute = { path: '/ask', component: AskContainerPage, private: true };
export const HijackPageRoute: TRoute = { path: '/hijack', component: HijackPage };
export const RedirectPageRoute: TRoute = { path: '/redirect', component: RedirectPage };

export const AttendancePageRoute: TRoute = { path: '/attendance', component: AttendancePage, private: true };

/**SECTION 통합 로그인 페이지 */
export const IntegratedSignInPageRoute: TRoute = { path: BUILD_USAGE === 'kdc' ? '/signin' : '/', component: IntegratedPages.SignIn, publicOnly: true };
export const IntegratedSignUpPageRoute: TRoute = { path: '/signup', component: IntegratedPages.SignUp, publicOnly: true };
export const SelectCampusPageRoute: TRoute = { path: '/select-campus', component: SelectCampus, exact: true };
// term_agreement_yn === false 일 때 가는 회원 정보 등록 페이지
export const RegisterInvitedUserPageRoute: TRoute = { path: '/register-invited-user', component: RegisterInvitedUserPage, exact: true };
// !SECTION

/**SECTION 약관 페이지
 * - 개인정보처리방침
 * - 이용약관
 * - 마케팅이용동의
 * - 환불정책(KDC)
 */
export const IntergratedTermsPageRoute: TRoute = { path: '/terms-of-use', component: IntegratedPages.TermsOfUse };
export const IntergratedPrivacyPolicyPageRoute: TRoute = { path: '/private-policy', component: IntegratedPages.PrivacyPolicy };
export const IntergratedRefundPolicyPageRoute: TRoute = { path: '/refund-policy', component: IntegratedPages.RefundPolicy };
export const IntergratedMarketingPageRoute: TRoute = { path: '/marketing', component: IntegratedPages.marketingAgreement };
// !SECTION

/**
 * SECTION LMS 컨텐츠 샘플 페이지
 */
export const ContentsSamplePageRoute: TRoute = { path: '/sample/:slug', component: ContentsSamplePage, private: true };
// !SECTION
