import { SVGProps } from 'react';

export const BannerGoDesktopIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="199"
      height="83"
      viewBox="0 0 199 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.3105 0.383789C48.9662 0.383789 55.014 1.53332 60.4538 3.83239C65.9576 6.13145 70.5334 9.26074 74.1813 13.2202C77.8931 17.1797 80.517 21.6182 82.053 26.5357H62.8537C61.1258 23.5341 58.4699 21.1073 54.886 19.2553C51.3661 17.3394 47.3023 16.3815 42.6945 16.3815C38.0226 16.3815 33.7988 17.4671 30.0229 19.6385C26.2471 21.7459 23.2712 24.7156 21.0953 28.5473C18.9834 32.3153 17.9274 36.5941 17.9274 41.3838C17.9274 46.1735 18.9834 50.5162 21.0953 54.4118C23.2712 58.2436 26.2471 61.2452 30.0229 63.4165C33.7988 65.5878 38.0226 66.6735 42.6945 66.6735C46.5983 66.6735 50.1502 65.971 53.35 64.566C56.5499 63.1611 59.1418 61.309 61.1258 59.01C63.1737 56.647 64.5176 54.1564 65.1576 51.538H41.2545V36.7857H83.7809V41.3838C83.7809 49.1751 81.957 56.2 78.3091 62.4586C74.7252 68.6533 69.7654 73.5068 63.4297 77.0193C57.1579 80.5956 50.1182 82.3838 42.3105 82.3838C34.3748 82.3838 27.207 80.5956 20.8073 77.0193C14.4715 73.5068 9.47971 68.6533 5.83185 62.4586C2.24799 56.2 0.456055 49.1751 0.456055 41.3838C0.456055 33.5925 2.24799 26.5995 5.83185 20.4048C9.47971 14.1462 14.4715 9.26074 20.8073 5.74827C27.207 2.17195 34.3748 0.383789 42.3105 0.383789Z"
        fill={props.color}
      />
      <path
        d="M130.092 82.3838C122.093 82.3838 114.861 80.5956 108.397 77.0193C101.997 73.5068 96.9736 68.6533 93.3258 62.4586C89.6779 56.2 87.854 49.1751 87.854 41.3838C87.854 33.5925 89.6779 26.5995 93.3258 20.4048C96.9736 14.1462 101.997 9.26074 108.397 5.74827C114.861 2.17195 122.093 0.383789 130.092 0.383789C138.092 0.383789 145.292 2.17195 151.692 5.74827C158.155 9.26074 163.211 14.1462 166.859 20.4048C170.507 26.5995 172.331 33.5925 172.331 41.3838C172.331 49.1751 170.507 56.2 166.859 62.4586C163.211 68.6533 158.155 73.5068 151.692 77.0193C145.292 80.5956 138.092 82.3838 130.092 82.3838ZM130.092 66.6735C134.764 66.6735 138.988 65.5878 142.764 63.4165C146.54 61.2452 149.484 58.2436 151.596 54.4118C153.771 50.5162 154.859 46.1735 154.859 41.3838C154.859 36.5302 153.771 32.1875 151.596 28.3558C149.484 24.524 146.54 21.5224 142.764 19.3511C138.988 17.1797 134.764 16.0941 130.092 16.0941C125.421 16.0941 121.197 17.1797 117.421 19.3511C113.645 21.5224 110.669 24.524 108.493 28.3558C106.381 32.1875 105.325 36.5302 105.325 41.3838C105.325 46.1735 106.381 50.5162 108.493 54.4118C110.669 58.2436 113.645 61.2452 117.421 63.4165C121.197 65.5878 125.421 66.6735 130.092 66.6735Z"
        fill={props.color}
      />
      <path
        d="M194.04 54.6034H182.137L177.721 2.39547H198.456L194.04 54.6034ZM188.088 61.5964C191.032 61.5964 193.464 62.5544 195.384 64.4702C197.304 66.3861 198.264 68.8129 198.264 71.7506C198.264 74.6883 197.304 77.0832 195.384 78.9352C193.464 80.8511 191.032 81.809 188.088 81.809C185.145 81.809 182.713 80.8511 180.793 78.9352C178.873 77.0832 177.913 74.6883 177.913 71.7506C177.913 68.8129 178.873 66.3861 180.793 64.4702C182.713 62.5544 185.145 61.5964 188.088 61.5964Z"
        fill={props.color}
      />
    </svg>
  );
};
