import { SVGProps } from 'react';

export const StarIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.7044 0.706657L16.7327 6.24727C16.9372 6.62133 17.3169 6.88069 17.748 6.94069L24.1325 7.8292C25.2182 7.98021 25.6925 9.25787 24.9354 9.99128L20.4821 14.3038C20.1814 14.595 20.0538 15.0146 20.141 15.4258L21.4312 21.5153C21.6505 22.5508 20.5475 23.3405 19.5646 22.8516L13.7841 19.9765C13.3937 19.7824 12.9353 19.7824 12.558 19.9765L6.9708 22.8516C6.02074 23.3405 4.86459 22.5508 5.0143 21.5153L5.89505 15.4258C5.95461 15.0146 5.79883 14.595 5.47854 14.3038L0.735196 9.99108C-0.0714414 9.25766 0.317209 7.98 1.39274 7.82899L7.71754 6.94049C8.14458 6.88049 8.50681 6.62112 8.68617 6.24707L11.3419 0.706453C11.7934 -0.235518 13.1896 -0.235518 13.7044 0.706657Z" />
    </svg>
  );
};
