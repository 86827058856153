import React from 'react';
import { Card, Text } from '../../../atoms';
import { TrophyIcon, ClapIcon } from '../../../assets';

import Button from '../../../../aui/Button';

import './completionbanner.scss';
import classNames from 'classnames';
import { BUILD_USAGE } from '@constants';

type TCompletionBannerProps = {
  isKCredit: boolean;
  title?: string;
  onClick: (_: any) => void;

  // NOTE: hasCompletedCourse: 유무에 따라 배너 활/비활성.
  hasCompletedCourse?: boolean;
};

const defaultText = `${
  BUILD_USAGE === 'kdc' ? '전체 강의 진행(100%)' : '전체 강의 중 80% 이상 진행'
}을 완료하시면, 수강종료일 이후에 수료증을 발급 받으실 수 있습니다!`;

const CompletionBanner: React.FC<TCompletionBannerProps> = ({
  isKCredit,
  title,
  onClick,
  hasCompletedCourse = false,
  ...props
}) => {
  const bannerText = hasCompletedCourse
    ? `축하드립니다! ${title}의 모든 강의를 끝마쳤습니다! 수료증을 발급받을 수
  있습니다!`
    : defaultText;

  return (
    <Card>
      <div
        className={classNames(
          'completion-banner',
          isKCredit ? 'kdigital' : 'online',
        )}
      >
        <div className="completion-banner-icon">
          {hasCompletedCourse && <TrophyIcon className="trophy-icon" />}
          {!hasCompletedCourse && <ClapIcon className="trophy-icon" />}
        </div>
        <div className={classNames('completion-banner-text')}>
          <p>{bannerText}</p>
        </div>
        <div className={'completion-banner-button'}>
          <Button disabled={!hasCompletedCourse} onClick={onClick}>
            수료증 발급
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default CompletionBanner;
