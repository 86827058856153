import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

type Props = {
  expirationTime: number;
  disabledExtendButton?: boolean;
  onExtendClick: () => void;
  onCloseClick: () => void;
};

export default function SessionTimerBar({
  disabledExtendButton,
  expirationTime,
  onExtendClick,
  onCloseClick,
}: Props) {
  const [remainTimeText, setRemainTimeText] = useState(
    buildExpirationTimeMessage(expirationTime),
  );

  useEffect(() => {
    let remainTimeText = buildExpirationTimeMessage(expirationTime);
    setRemainTimeText(remainTimeText);
    const handleTick = () => {
      const newRemainTimeText = buildExpirationTimeMessage(expirationTime);
      if (newRemainTimeText !== remainTimeText) {
        setRemainTimeText(newRemainTimeText);
        remainTimeText = newRemainTimeText;
      }
    };
    const interval = window.setInterval(handleTick, 250);
    return () => {
      window.clearInterval(interval);
    };
  }, [expirationTime]);

  return (
    <div className={styles.sessionTimerBar}>
      <p>
        로그인 유지 시간이 <strong>{remainTimeText}</strong> 남았습니다.
        <br />
        만료시 자동으로 로그아웃 됩니다.
      </p>
      <button
        disabled={disabledExtendButton}
        className={styles.extendButton}
        onClick={onExtendClick}
      >
        로그인시간 연장하기
      </button>
      <CloseButton onClick={onCloseClick} />
    </div>
  );
}

function buildExpirationTimeMessage(expirationTime: number) {
  let ms = Math.max(expirationTime - Date.now(), 0);
  if (ms >= ONE_HOUR_MS) {
    const hour = Math.floor(ms / ONE_HOUR_MS);
    ms %= ONE_HOUR_MS;
    const min = Math.floor(ms / ONE_MINUTE_MS);
    return `${hour}시간 ${min}분`;
  } else if (ms >= ONE_MINUTE_MS) {
    const min = Math.floor(ms / ONE_MINUTE_MS);
    ms %= ONE_MINUTE_MS;
    const sec = Math.floor(ms / ONE_SECOND_MS);
    return `${min}분 ${sec}초`;
  }
  return `${Math.floor(ms / ONE_SECOND_MS)}초`;
}

function CloseButton({ onClick }: { onClick: () => void }) {
  return (
    <button className={styles.closeButton} onClick={onClick}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1.07138L1.07137 0L3.75 2.67863L6.42862 0L7.5 1.07138L4.82137 3.75L7.5 6.42863L6.42862 7.5L3.75 4.82137L1.07137 7.5L0 6.42863L2.67863 3.75L0 1.07138Z"
          fill="#1A1500"
        />
      </svg>
    </button>
  );
}

const ONE_SECOND_MS = 1000;
const ONE_MINUTE_MS = 60 * ONE_SECOND_MS;
const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
