import React from 'react';
import { isMobileState } from './';
import { useRecoilValue } from 'recoil';

const withMobileDetector = (Component) => {
  return (props) => {
    const isMobile = useRecoilValue(isMobileState);
    return <Component {...props} isMobile={isMobile} />;
  };
};

export default withMobileDetector;
