export const white = '#FFF';
export const black = '#000';

export const gray100 = '#fff';
export const gray200 = '#eee';
export const gray400 = '#aaa';
export const gray500 = '#888';
export const gray900 = '#000';

export const yellow300 = '#FFE675';
export const yellow500 = '#FFCD05';
export const yellow800 = '#FFD000';
export const yellow900 = '#1A1500';

export const cyan800 = '#001957';

export const green800 = '#005733';

export const statusSuccess = '#35B31C';
export const statusAlert = '#FB8D28';
export const statusDanger = '#C80000';
