import React from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, loading, disabled, ...rest }, ref) => {
    return (
      <button
        className={classNames([styles.button, className, { loading }])}
        aria-busy={loading}
        aria-disabled={disabled || loading}
        disabled={disabled || loading}
        {...rest}
        ref={ref}
        tabIndex={0}
      >
        {children}
      </button>
    );
  },
);

export default Button;
