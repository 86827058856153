/**
 * @author brandonwie
 * @created Oct 4, 2022
 * 두개 이상의 request가 결합된 thunk actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Sentry } from '@utils';
import { http } from '@http';
import { Http } from '@types';
import { MESSAGE, BUILD, BUILD_USAGE } from '@constants';

/****************************************************************
 *! getFingerprint + postLogin
 *! Back-End에서 관련 로직이 정리될 때까지 전부 다 하나로 쓰는 것으로
 ***************************************************************/

interface AsyncThunkConfig {
  rejectValue: string;
  rejectMeta: string;
}
export const fetchLogin = createAsyncThunk<
  Http.Response.TLogin,
  Http.Request.ILogin,
  AsyncThunkConfig
>('auth/fetchLogin', async (userCredentials, thunkApi) => {
  const data = {
    username: userCredentials.username,
    password: userCredentials.password,
  };

  try {
    const res = await http.auth.postLogin(data);
    return res.data;
  } catch (error) {
    let message: string;
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        return thunkApi.rejectWithValue(error.response.data.detail);
      } else if (error.response?.status === 403) {
        return thunkApi.rejectWithValue(error.response.data.detail);
      } else {
        message = MESSAGE.AUTH.SERVER_LOGIN_FAIL;
        Sentry.captureMessageWithAxiosError(
          '[slices/auth.ts][fetchLogin] login()',
          error,
        );
      }
    } else {
      message = MESSAGE.AUTH.UNEXPECTED_LOGIN_FAIL;
      Sentry.captureMessageWithAxiosError(
        '[slices/auth.ts][fetchLogin] login()',
        error,
      );
    }
    return thunkApi.rejectWithValue(message);
  }
});

/****************************************************************
 * !getAccountInfo + getAttendance (KDC일 경우에만)
 ***************************************************************/
// NOTE 실제 login에 쓰이는 account + attendance 콤보
//! /account/ + /auth-sms/attendance/ http request
export const fetchAccountAndAttendance = createAsyncThunk<
  Http.Response.TDispatchReturnTypeForAccount,
  void,
  AsyncThunkConfig
>('auth/fetchAccountAndAttendance', async (_, thunkApi) => {
  let data;
  try {
    const res = await http.user.getAccountInfo();
    data = { ...res.data, hasAttendanceChecked: true };

    console.log(
      '%c [result] fetchAccoungetAccountInfotAndAttendance',
      'color: #00ff00;',
      { data },
    );
  } catch (error) {
    let message: string;
    Sentry.captureMessageWithAxiosError(
      '[slices/auth.ts][fetchAccountAndAttendance] getAccountInfo()',
      error,
    );
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        message = MESSAGE.AUTH.SERVER_FETCH_ACCOUNT_FAIL;
      } else {
        message = MESSAGE.AUTH.FETCH_ACCOUNT_FAIL;
      }
    } else {
      message = MESSAGE.AUTH.UNEXPECTED_FETCH_ACCOUNT_FAIL;
    }
    return thunkApi.rejectWithValue(message);
  }
  if (BUILD_USAGE !== BUILD.USAGE.K_DIGITAL) return data;
  try {
    const res = await http.user.getAttendance();
    data = { ...data, hasAttendanceChecked: res.data.check_attendance };
  } catch (error) {
    let message: string;
    if (axios.isAxiosError(error)) {
      Sentry.captureMessageWithAxiosError(
        '[slices/auth.ts][fetchAccountAndAttendance] getAttendance()',
        error,
      );
      if (error.response?.status === 500) {
        message = MESSAGE.AUTH.SERVER_FETCH_ATTENDANCE_FAIL;
      } else {
        message = MESSAGE.AUTH.FETCH_ATTENDANCE_FAIL;
      }
    } else {
      message = MESSAGE.AUTH.UNEXPECTED_FETCH_ATTENDANCE_FAIL;
    }
    return thunkApi.rejectWithValue(message);
  }
  return data;
});
