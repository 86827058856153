import { format, fromUnixTime } from 'date-fns';
import { ko } from 'date-fns/locale';

export const getFormattedDate = (
  date: string | Date | number = new Date(),
  customFormat: string = 'YYYY.MM.DD',
  options: {
    locale?: Object;
  } = { locale: ko },
) => {
  return format(new Date(date), customFormat, options);
};

export const getFormattedDateTimeFromUnix = (
  date: string | number,
  customFormat: string = 'HH:mm',
) => {
  const unixDate = fromUnixTime(
    typeof date === 'string' ? parseInt(date) : date,
  );

  return format(unixDate, customFormat);
};

export const leadZero = (number: number) => {
  let s = number + '';
  while (s.length < 2) s = '0' + s;
  return s;
};

export const calculateRemainTime = (requireTime: string) => {
  return requireTime.split(':').map((item) => {
    return leadZero(parseInt(item));
  });
};

export const getCondintiallyFormatedRequiredTime = ({
  hour,
  minute,
  seconds,
}: {
  hour: string;
  minute: string;
  seconds: string;
}) => {
  const h = parseInt(hour);
  const m = parseInt(minute);

  if (h <= 0) {
    if (m <= 0) {
      return `${seconds}초`;
    } else {
      return `${minute}분 ${seconds}초`;
    }
  }
  return `${hour}시간 ${minute}분 ${seconds}초`;
};

//NOTE: 원래 1024/1024인데 편의상 1000/1000으로 계산
export const convertByteToMB = (bytes: number) => {
  const mb = bytes / 1000 / 1000;
  return mb.toFixed(2);
};
