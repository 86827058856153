import classNames from 'classnames';

import ErrorIcon from '../../assets/error-message.svg';
import SuccessIcon from '../../assets/success-message.svg';
import './textfield.scss';

interface TextFieldProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'size'
  > {
  isAnimated?: boolean;
  label?: string;
  size: 'xs' | 'sm' | 'md' | 'lg';
  variant: 'default' | 'primary' | 'secondary';
  fill?: boolean;
  errorMessage?: string;
  successMessage?: string;
  isMessageExist?: boolean;
}

/**
 * Variant UI component for user interaction
 */
export const TextField = ({
  children,
  disabled,
  isAnimated,
  label,
  name,
  onChange,
  placeholder,
  size,
  autoComplete,
  variant,
  onFocus,
  value,
  type,
  fill,
  errorMessage,
  successMessage,
  isMessageExist,
  //children is for inner buttons
  ...props
}: TextFieldProps) => {
  return (
    <div
      className={classNames('sb-textfield__wrapper', `sb-textfield--${size}`, {
        fill,
      })}
    >
      {label && label.length > 0 && !isAnimated && (
        <label htmlFor={name} className="sb-textfield__label">
          {label}
        </label>
      )}
      <input
        type={type}
        className={classNames([
          'sb-textfield',
          `sb-textfield--${variant}`,
          `sb-textfield--${size}`,
          { 'sb-textfield--animated': isAnimated },
          { error: errorMessage },
          { success: successMessage },
        ])}
        {...props}
        placeholder={placeholder}
        id={name}
        name={name}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      {children}
      {/* Animation이 들어가는 textfield label */}
      {label && label.length > 0 && isAnimated && (
        <div className="animated-label__wrapper">
          <label htmlFor={name} className="sb-textfield__label--animated">
            {label}
          </label>
        </div>
      )}
      {isMessageExist && (
        <div className="sb-textfield__message-container">
          {(errorMessage || successMessage) && (
            <span
              className={classNames(
                `sb-textfield__message--${errorMessage ? 'error' : 'success'}`,
                '!flex items-center',
              )}
            >
              <img
                src={errorMessage ? ErrorIcon : SuccessIcon}
                alt={`${errorMessage ? 'error' : 'success'}-icon`}
                className={`sb-textfield__${
                  errorMessage ? 'error' : 'success'
                }-icon`}
              />{' '}
              {errorMessage ? errorMessage : successMessage}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

TextField.defaultProps = {
  // NOTE 아무 타입도 지정하지 않을 경우 text로 설정됩니다.
  type: 'text',
  variant: 'default',
  size: 'md',
  label: 'TEXTFIELD',
  placeholder: '',
  name: 'name',
  onChange: () => {},
  children: null,
  errorMessage: '',
  successMessage: '',
  isAnimated: false,
  fill: null,
  isMessageExist: true,
};
