import { User } from '@types';
import store from '@store';

const CURSOR_S3_URL =
  'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/img/cursor';
const CURSOR_FILE_TYPE = 'png';
export const getCursorURL = (type: User.TPersona) => {
  return `${CURSOR_S3_URL}/${type}.${CURSOR_FILE_TYPE}`;
};

export const getCursorStyle = () => {
  const { auth } = store.getState();
  const userPersona = auth?.user?.testType?.persona as User.TPersona;
  const cursorURL = getCursorURL(userPersona);
  return { cursor: `url(${userPersona ? cursorURL : ''}), auto` };
};
