import classNames from 'classnames';
import { MotifLoader } from '@components/MotifLoader';
import styles from './index.module.scss';

export interface LoadingPageProps {
  className?: string;
  message?: string;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ className, message }) => {
  return (
    <div className={classNames(styles.loadingPage, className)}>
      <MotifLoader text="AIFFEL" />
      {message && <p className={classNames(styles.message)}>{message}</p>}
    </div>
  );
};

export default LoadingPage;
