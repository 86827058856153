import classNames from 'classnames';
import './badge.scss';

type TBadgeProps = {
  text: string;
  bordered?: boolean;
  borderColor?: string;
  bgColor?: string;
  extraClassName?: string;
};
export const Badge = ({
  text,
  borderColor,
  bordered,
  bgColor,
  extraClassName,
}: TBadgeProps) => {
  return (
    <span
      className={classNames(
        'aui-badge',
        'rounded-[30px] border',
        extraClassName,
      )}
      style={{
        borderColor: bgColor,
        backgroundColor: bordered ? '#fff' : bgColor,
        color: bordered ? bgColor : '#fff',
      }}
    >
      {text}
    </span>
  );
};
