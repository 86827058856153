import { selector } from 'recoil';
import mobileTriggerWidthState from '../atom/mobileTriggerWidthState';
import windowSizeState from '../atom/windowSizeState';

const isMobileState = selector({
  key: 'isMobileState',
  get: ({ get }) => {
    return get(windowSizeState).width <= get(mobileTriggerWidthState);
  },
});

export default isMobileState;
