import React from 'react';

import TextField, { TextFieldProps } from '@online/aui/Textfield';
import classNames from 'classnames';

import Button from '@online/aui/Button';
import { Eye, EyeSlash } from 'phosphor-react';

import './index.scss';

interface TextFieldWithButtonProps extends TextFieldProps {
  type: 'email' | 'password' | 'text' | 'tel';
  password?: boolean;
  show?: boolean;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonLabel?: string;
  buttonDisabled?: boolean;
  buttonClassName?: string;
  required?: boolean;
}

const TextFieldWithButton = React.forwardRef<
  HTMLInputElement,
  TextFieldWithButtonProps
>(
  (
    {
      type,
      buttonLabel,
      buttonDisabled,
      buttonOnClick,
      buttonClassName,
      labelCollapsed,
      password,
      required = false,
      ...rest
    },
    ref,
  ) => {
    const [show, setShow] = React.useState<boolean>(false);
    return (
      <div
        className={classNames('textfield-with-button__wrapper', {
          'label-collapsed': labelCollapsed,
        })}
      >
        <TextField
          required={required}
          labelCollapsed={labelCollapsed}
          ref={ref}
          {...rest}
          type={show ? 'text' : type}
        >
          {!password && (
            <Button
              id={'input-button'}
              className={buttonClassName}
              disabled={buttonDisabled}
              onClick={buttonOnClick}
            >
              {buttonLabel}
            </Button>
          )}
          {password && show && (
            <Eye
              size={28}
              onClick={() => setShow(false)}
              className={'eye-icon'}
            />
          )}
          {password && !show && (
            <EyeSlash
              size={28}
              onClick={() => setShow(true)}
              className={'eye-icon'}
            />
          )}
        </TextField>
      </div>
    );
  },
);

export default TextFieldWithButton;
