import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './index.module.scss';

export interface SidebarProps {
  routeGroups: TSidebarRouteGroup[];
  className?: string;
}

export type TSidebarRouteGroup = {
  header: string;
  routes: { path: string; title: string }[];
};

const Sidebar: React.FC<SidebarProps> = ({ className, routeGroups }) => {
  return (
    <div className={classNames(styles.sidebar, className)}>
      {routeGroups
        .filter(({ routes }) => routes.length > 0)
        .map((routeGroup, key) => (
          <ul key={key}>
            <li>
              <h2>{routeGroup.header}</h2>
            </li>
            <li>
              {routeGroup.routes.map((route, key) => (
                <NavLink
                  key={key}
                  // FIXME 창덕님 여기 isActive는 어디서 넘어오는건가요? (석현)
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                  to={route.path}
                >
                  {route.title}
                </NavLink>
              ))}
            </li>
          </ul>
        ))}
    </div>
  );
};

export default Sidebar;
