import { useEffect, useMemo } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { MessageBannerProps } from '../MessageBanner';
import { globalMessageBannerProps } from './recoil/atom';

export default function GlobalMessageBanner(props: MessageBannerProps) {
  const setGlobalMessageBannerProps = useSetRecoilState(globalMessageBannerProps);
  const resetGlobalMessageBannerProps = useResetRecoilState(globalMessageBannerProps);

  useEffect(() => {
    setGlobalMessageBannerProps(props);
    return () => {
      resetGlobalMessageBannerProps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
}

export function useGlobalMessageBannerProps() {
  return useRecoilValue(globalMessageBannerProps);
}
