import classNames from 'classnames';
import React, { HTMLAttributes, ReactChild } from 'react';
// import { UseFormRegisterReturn } from 'react-hook-form';
// import ErrorIcon from './icons/error.svg';
// import SuccessIcon from './icons/success.svg';
import './index.scss';

export interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  type?: 'email' | 'password' | 'text' | 'tel';
  label?: string;
  value?: string;
  animated?: boolean; // label 애니메이션
  withMessage?: boolean; // input 아래 부분에 message 출력을 위한 공간 생성
  message?: ReactChild | null;
  errorMessage?: string | null;
  successMessage?: string | null;
  labelCollapsed?: boolean; // label이 아예 없는 상태 (input이 빈 칸 없이 위로 당겨짐)
  disabled?: boolean;
  password?: boolean;
  autoComplete?: 'off' | 'on' | 'new-password';
  required?: boolean;
}

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      errorMessage,
      successMessage,
      message,
      placeholder = 'placeholder',
      type = 'text',
      label = 'label',
      animated = false,
      withMessage = false,
      children,
      disabled,
      labelCollapsed = false,
      password = false,
      autoComplete,
      required = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={classNames([
          'textfield__container',
          { 'textfield__container--animated': animated },
        ])}
      >
        {/* labelCollapsed가 활성되어 있으면 레이블이 차지하던 공간도 없어진다 */}
        {!labelCollapsed && !animated && (
          <label htmlFor={id} className="textfield__label">
            {label}{' '}
            {required && (
              <span className="required text-error">{`(* 필수)`}</span>
            )}
          </label>
        )}
        {/* button has to have input wrapper as parent otherwise label and message div will interfere with the absolute location values */}
        <input
          ref={ref}
          type={type}
          className={classNames(
            'textfield',
            {
              error: errorMessage,
              success: successMessage,
              'textfield--animated': animated,
            },
            'sm:leading-6" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-k-400 focus:!shadow-none focus:ring-1 focus:ring-inset focus:ring-k-700 sm:text-sm',
          )}
          id={id}
          disabled={disabled}
          placeholder={placeholder} // 기본 placeholder가 들어가지 않으면 label이 항상 위로 올라간 상태에 머문다.
          tabIndex={0}
          autoComplete={autoComplete}
          {...rest}
        />
        {children}
        {/* Animation이 들어가는 input label */}
        {!labelCollapsed && animated && (
          <label htmlFor={id} className={'textfield__label--animated'}>
            {label}
          </label>
        )}
        {withMessage && (
          <div className="textfield__message-container">
            {(errorMessage || successMessage || message) && (
              <div
                className={classNames({
                  'error-message': errorMessage,
                  'success-message': successMessage,
                  'common-message': message,
                })}
              >
                {/* <img
                  aria-label={errorMessage ? 'error icon' : 'success icon'}
                  src={errorMessage ? ErrorIcon : SuccessIcon}
                  alt={`${errorMessage ? 'error' : 'success'}-icon`}
                  className={`textfield__${
                    errorMessage ? 'error' : 'success'
                  }-icon`}
                /> */}
                <span>
                  {errorMessage
                    ? errorMessage
                    : successMessage
                    ? successMessage
                    : message}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default TextField;
