import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { http } from '@http';
import { Sentry, HotToast } from '@utils';
import { MESSAGE } from '@constants';
import { Http } from '@types';

interface AsyncThunkConfig {
  rejectValue: string;
  rejectMeta: string;
}
/****************************************************************
 *! Login (FingerprintJS 없는 pure한 로그인)
 *! 현재는 KDC, Online에서 사용
 ***************************************************************/
export const login = createAsyncThunk<
  Http.Response.TLogin,
  Http.Request.ILogin,
  AsyncThunkConfig
>('auth/postLogin', async (d, thunkAPI) => {
  try {
    const res = await http.auth.postLogin(d);
    return res.data;
  } catch (error) {
    console.log({ error });
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue(MESSAGE.AUTH.LOGIN_FAIL);
    } else {
      HotToast.error(MESSAGE.AUTH.UNEXPECTED_LOGIN_FAIL);
      return thunkAPI.rejectWithValue(MESSAGE.AUTH.UNEXPECTED_LOGIN_FAIL);
    }
  }
});
/****************************************************************
 *! Refresh token with campus ID
 ***************************************************************/
export const refreshTokenWithCampusId = createAsyncThunk<
  Http.Response.TLogin,
  Http.Request.TRefreshToken,
  AsyncThunkConfig
>('auth/refresh-token', async (data, thunkApi) => {
  try {
    const res = await http.auth.postRefreshToken(data);
    return res.data;
  } catch (error) {
    let message: string;
    Sentry.captureMessageWithAxiosError(
      '[slices/auth.ts][fetchTokenWithCampusId] refreshToken()',
      { error },
    );
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 403) {
        message = MESSAGE.AUTH.LOGIN_FAIL;
      } else {
        message = MESSAGE.AUTH.SERVER_LOGIN_FAIL;
      }
    } else {
      message = MESSAGE.AUTH.UNEXPECTED_LOGIN_FAIL;
    }
    HotToast.error(message);
    return thunkApi.rejectWithValue(message);
  }
});

// NOTE: Redux Toolkit 공식 문서에서는 에러 발생 시 rejectWithValue를 쓰거나 Promise.reject를 사용할 것을 권장하고 있다.

/****************************************************************
 *! Fetch user info after Type B token is issued
 * 해당 action은 토큰의 local_school_id가 자연수인 경우(= 타입 B토근 = 하나의 캠퍼스 아이디를 가지고 있는 토큰)에만 dispatch 될 수 있도록 한다.
 ***************************************************************/
export const fetchAccountInfo = createAsyncThunk<
  Http.Response.TGetAccount,
  void,
  AsyncThunkConfig
>('auth/fetchAccountInfo', async (_, thunkApi) => {
  try {
    const res = await http.user.getAccountInfo();
    return res.data;
  } catch (error) {
    let message: string;
    Sentry.captureMessageWithAxiosError(
      '[slices/auth.ts][fetchAccountInfo] getAccountInfo()',
      { error },
    );
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 500) {
        message = MESSAGE.AUTH.SERVER_FETCH_FINGERPRINT_FAIL;
      } else {
        message = MESSAGE.AUTH.FETCH_FINGERPRINT_FAIL;
      }
    } else {
      message = MESSAGE.AUTH.UNEXPECTED_FETCH_ACCOUNT_FAIL;
    }
    return thunkApi.rejectWithValue(message);
  }
});
