import routes from '@routes';
import { TRoute } from '@routes/TRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardPageLayout from '../DashboardPageLayout';
import PublicOnly from './PublicOnly';
import RequiredAuth from './RequireAuth';

type LayoutRoute = {
  layout: React.ReactNode;
  subRoutes: TRoute[];
};

function isLayoutRoute(
  something: LayoutRoute | TRoute,
): something is LayoutRoute {
  return (something as LayoutRoute).layout !== undefined;
}

const dashboardLayoutRouteGroups = [
  // 학생
  {
    header: 'Explorer',
    routes: [
      {
        title: '오늘의탐험',
        route: routes.lms.TodayPageRoute,
      },
      {
        title: '나의발자취',
        route: routes.lms.StagePageRoute,
      },
    ],
  },
  // 에디터
  {
    header: 'Editor',
    routes: [
      {
        title: '오늘의탐험',
        route: routes.lms.TodayForEditorPageRoute,
      },
      {
        title: '나의발자취',
        route: routes.lms.StageForEditorPageRoute,
      },
    ],
  },
  // 코치
  {
    header: 'Discover',
    routes: [
      {
        title: '오늘의탐험',
        route: routes.lms.CoachTodayPageRoute,
      },
      {
        title: '나의발자취',
        route: routes.lms.CoachStagePageRoute,
      },
      {
        title: '',
        route: routes.lms.CoachRubricPageRoute,
        display: false,
      },
      {
        title: '',
        route: routes.lms.AssessmentPageRoute,
        display: false,
      },
    ],
  },
  {
    header: 'User',
    routes: [
      {
        title: '프로필',
        route: routes.lms.ProfilePageRoute,
      },
      {
        title: '계정설정',
        route: routes.common.AccountPageRoute,
      },
      {
        title: '출결관리',
        route: routes.lms.AttendancePageRoute,
        attendanceOnly: true,
      },
    ],
  },
  {
    header: 'Users Management',
    routes: [
      {
        title: 'Users',
        route: routes.lms.DashboardUserRoute,
      },
      {
        title: '',
        route: routes.lms.DashboardUserDetailRoute,
        display: false,
      },
      {
        title: 'Cloud',
        route: routes.lms.DashboardCloudRouteV2,
      },
    ],
  },
  {
    header: 'Courses Management',
    routes: [
      {
        title: 'Courses',
        route: routes.lms.DashboardCourseRoute,
      },
      {
        title: 'Course List',
        route: routes.lms.DashboardCourseListRoute,
        display: false,
      },
      {
        title: 'Course Detail',
        route: routes.lms.DashboardCourseDetailRoute,
        display: false,
      },
      {
        title: 'Enrol Detail',
        route: routes.lms.DashboardEnrolDetailRoute,
        display: false,
      },
    ],
  },
];

const renderingRoutes: (LayoutRoute | TRoute)[] = [
  /// NOTE: Campus + kdc/online interrated login
  routes.common.IntegratedSignInPageRoute,
  routes.common.IntergratedPrivacyPolicyPageRoute,
  routes.common.IntergratedTermsPageRoute,
  routes.online.TermsAndExtraInfoPageRoute,
  routes.common.SelectCampusPageRoute,
  routes.common.RegisterInvitedUserPageRoute,

  routes.common.NodesPageRoute,
  routes.common.StepsPageRoute,
  routes.common.ReadonlyStepsPageRoute,
  routes.common.ReleaseNotePageRoute,
  routes.common.FindPasswordPageRoute,
  routes.common.ResetPasswordPageRoute,

  routes.common.ForumPageRoute,
  routes.common.TopicDetailPageRoute,

  routes.common.TopicContainerPageRoute,
  routes.common.TopicDetailContainerPageRoute,

  routes.common.ForumSearchPageRoute,
  routes.common.AskContainerPageRoute,
  routes.common.HijackPageRoute,
  routes.common.RedirectPageRoute,
  routes.common.ContentsSamplePageRoute,

  routes.lms.DashboardCloudRouteV2,
  {
    layout: LMSDashboard,
    subRoutes: dashboardLayoutRouteGroups.reduce<TRoute[]>(
      (prev, { routes }) => prev.concat(routes.map(({ route }) => route)),
      [],
    ),
  },

  routes.lms.NotFoundPageRoute,
];

export default function LMSRoutes() {
  return (
    <Routes>
      {renderingRoutes.map((route, key) => {
        if (isLayoutRoute(route)) {
          return (
            <Route element={<LMSDashboard />} key={key}>
              {route.subRoutes.map((route, key) => {
                return renderRoute(route, key);
              })}
            </Route>
          );
        }
        return renderRoute(route, key);
      })}
    </Routes>
  );
}

function renderRoute(route: TRoute, key?: string | number) {
  return (
    <Route
      key={key}
      path={route.path}
      element={
        route.private || route.allowedPermissions ? (
          <RequiredAuth allowedRoles={route.allowedPermissions}>
            <route.component />
          </RequiredAuth>
        ) : route.publicOnly ? (
          <PublicOnly>
            <route.component />
          </PublicOnly>
        ) : (
          <route.component />
        )
      }
    />
  );
}

function LMSDashboard() {
  return (
    <DashboardPageLayout
      routeGroups={dashboardLayoutRouteGroups.map((group) => ({
        header: group.header,
        routes: group.routes,
      }))}
    />
  );
}
