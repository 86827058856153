import { instance } from '../provider';

export const getListForIssues = async () => {
  try {
    const res = await instance.get('/api-evaluation/issues/');
    if (res.status === 200) {
      return res;
    } else {
      console.log('[API][LEGACY][getListForIssues] bad response:', res);
      return { error: { message: '이슈목록을 불러오는데 실패했습니다.' } };
    }
  } catch (err) {
    console.log(
      '[API][LEGACY][getListForIssues] error.response:',
      err.response,
    );
    return { error: { message: '이슈목록을 불러오는데 실패했습니다.' } };
  }
};

/**
 *
 * @param {Array<number>} ratingArray
 */
export const updateAssessmentResult = async (
  nodeProgressId,
  ratingArray,
  message,
) => {
  const formData = new FormData();
  const booleanToNumber = (bool) => (bool === true ? 1 : 0);
  formData.append('node_progrs_id', nodeProgressId);
  formData.append('rubric_1_score', booleanToNumber(ratingArray[0]));
  formData.append('rubric_2_score', booleanToNumber(ratingArray[1]));
  formData.append('rubric_3_score', booleanToNumber(ratingArray[2]));
  formData.append('comment', message);

  try {
    const res = await instance.post(
      `/api-evaluation/updateUserEval/`,
      formData,
    );
    if (res) {
      console.log('TEST - updateAssessmentResult res:', res);
      return res;
    }
  } catch (err) {
    console.log(
      '[API][LEGACY][updateAssessmentResult] err.response:',
      err.response,
    );
    return { error: { message: '제출이 정상적으로 처리되지 않았습니다.' } };
  }
};
