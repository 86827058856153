import NotFoundPage from '@pages/NotFoundPage';
import React from 'react';
import { TRoute } from './TRoute';

/****************
 * Lazy Imports
 ***************/

/**
 * NOTE: KDC 랜딩 페이지
 */
const LandingPage = React.lazy(() => import('@pages/kdc/Landing'));

/**
 * NOTE: 회원가입/로그인 관련 페이지
 */
const TermsPage = React.lazy(() => import('@pages/kdc/Terms'));
const AttendancePage = React.lazy(() => import('@pages/kdc/AttendancePage'));

const FindClassPage = React.lazy(() => import('@pages/kdc/FindClass'));

const CheckNBCPage = React.lazy(() => import('@pages/kdc/CheckNBCPage'));

const CourseApplyPage = React.lazy(() => import('@pages/CourseApply'));
const CourseAPplyConfirmPage = React.lazy(() => import('@pages/kdc/CourseApplyConfirm'));

const PurchasePage = React.lazy(() => import('@pages/kdc/Purchase'));
const PurchaseConfirmPage = React.lazy(() => import('@pages/kdc/PurchaseConfirm/PurchaseConfirmPage'));

const AssessmentPage = React.lazy(() => import('@legacy/kdc/Assessment/AssessmentPage'));
// 상세페이지
const PythonBasicPage = React.lazy(() => import('@homepage/pages/PythonBasic'));
const MetaverseAiBasicPage = React.lazy(() => import('@homepage/pages/MetaverseAiBasic'));
const MlBasicPage = React.lazy(() => import('@homepage/pages/MlBasic'));
const DataAbcPage = React.lazy(() => import('@homepage/pages/DataAbc'));
const DlTrainingPage = React.lazy(() => import('@homepage/pages/DlTraining'));
const DlTheoryPage = React.lazy(() => import('@homepage/pages/DlTheory'));
const KagglerDaPage = React.lazy(() => import('@homepage/pages/KagglerDa'));

/****************
 * Route Objects
 ***************/
/**
 * NOTE: GNB 작업으로 kdc 랜딩 + kdc lms 합치기 위해 라우팅 재정의 10/06 - Tax
 */
export const LandingPageRoute: TRoute = { path: '/', component: LandingPage, publicOnly: true };
export const TermsPageRoute: TRoute = { path: '/kdc/terms', component: TermsPage };
export const AttendanceRoute: TRoute = { path: '/attendance', component: AttendancePage };

export const AssessmentPageRoute: TRoute = { path: '/assesment', component: AssessmentPage };

export const FindClassPageRoute: TRoute = { path: '/findclass', component: FindClassPage };

export const CheckNBCPageRoute: TRoute = { path: '/checknbc', component: CheckNBCPage, private: true };
export const CourseApplyPageRoute: TRoute = { path: '/apply/:courseId/:slug', component: CourseApplyPage, private: true };
export const CourseAPplyConfirmPageRoute: TRoute = { path: '/apply/success/:courseId/:slug/:roundId', component: CourseAPplyConfirmPage, private: true };

export const PurchasePageRoute: TRoute = { path: '/purchase/:courseId/:slug', component: PurchasePage };
export const PurchaseConfirmPageRoute: TRoute = { path: '/purchase/success/:courseId/:slug/:roundId', component: PurchaseConfirmPage };

export const NotFoundPageRoute: TRoute = { path: '*', component: NotFoundPage };

// 상세페이지
export const PythonBascisRoute: TRoute = { path: '/courses/python-basic', component: PythonBasicPage };
export const MetaverseAiBasicRoute: TRoute = { path: '/courses/metaverse-ai-basic', component: MetaverseAiBasicPage };
export const MlBasicRoute: TRoute = { path: '/courses/ML-basic', component: MlBasicPage };
export const DataAbcRoute: TRoute = { path: '/courses/DA-abc', component: DataAbcPage };
export const DlTrainingRoute: TRoute = { path: '/courses/DL-training', component: DlTrainingPage };
export const DlTheoryRoute: TRoute = { path: '/courses/DL-theory', component: DlTheoryPage };
export const KagglerDaRoute: TRoute = { path: '/courses/kaggler-DA', component: KagglerDaPage };
