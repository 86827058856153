import { BaseTypography, TypoProps } from '../Base';

export const Body1 = (props: TypoProps) => {
  const { children, ...restProps } = props;

  return (
    <BaseTypography md="Body1" {...restProps}>
      {children}
    </BaseTypography>
  );
};
