import React from 'react';
import PropTypes from 'prop-types';
import { Title, CheckBox, TextBox, Button, Steps } from '../../atoms';
import { CheckBoxWithTextBox } from '../../molecules';
import WarningIcon from '../../assets/warning.svg';

import './termstemplate.scss';

// NOTE 나중에 objects in array 타입으로 data를 넘기면 CheckBoxWithTextBox가 자동으로 생성되도록 메커니즘을 만들어야 한다.

export const TermsTemplate = ({
  basic,
  personal,
  acs,
  mkt_agreement_yn,
  isAllChecked,
  isCompleted,
  onCheckBoxWithTextBoxClick,
  onMainCheckBoxClick,
  onNextClick,
  onPrevClick,
  termsOfUse,
  termsOfPerson,
  termsOfAcs,
  termsOfMarketing,
}) => {
  return (
    <div className="sb-terms">
      <Title variant="primary" size="lg" label="환영합니다!" />
      <Steps
        className="only-mobile"
        steps={['약관동의', '회원정보 등록', '회원가입 완료']}
        activeIndex={0}
      />
      <TextBox variant="secondary" size="lg">
        <img src={WarningIcon} alt="warning icon" /> 현재 아이펠(AIFFEL)
        내일배움클래스는 누구나 회원가입 할 수 있으나,{' '}
        <span>
          내일배움카드가 있는 회원만 강의의 90%를 할인받아 신청할 수 있습니다.
        </span>{' '}
        공개 강의 서비스가 개설되면 메일로 소식을 전해드립니다.
      </TextBox>
      <CheckBoxWithTextBox
        id="basic"
        name="basic"
        label="아이펠(AIFFEL) 온라인 이용 약관"
        checked={basic}
        onCheckBoxClick={onCheckBoxWithTextBoxClick}
      >
        {termsOfUse}
      </CheckBoxWithTextBox>

      <CheckBoxWithTextBox
        id="personal"
        name="personal"
        label="아이펠(AIFFEL) 온라인 개인정보 수집 동의"
        checked={personal}
        onCheckBoxClick={onCheckBoxWithTextBoxClick}
      >
        {termsOfPerson}
      </CheckBoxWithTextBox>

      <CheckBoxWithTextBox
        id="acs"
        name="acs"
        label="ACS 정보 제공 동의"
        checked={acs}
        onCheckBoxClick={onCheckBoxWithTextBoxClick}
      >
        {termsOfAcs}
      </CheckBoxWithTextBox>

      <CheckBoxWithTextBox
        id="mkt_agreement_yn"
        name="mkt_agreement_yn"
        label="마케팅 정보 수신 동의 (선택)"
        checked={mkt_agreement_yn}
        onCheckBoxClick={onCheckBoxWithTextBoxClick}
      >
        {termsOfMarketing}
      </CheckBoxWithTextBox>
      {/* NOTE Check All button */}
      <CheckBox
        id="isAllChecked"
        name="isAllChecked"
        variant="secondary"
        label="전체동의"
        // manipulate check button
        checked={isAllChecked}
        // 전체동의용 handler
        onCheckBoxClick={onMainCheckBoxClick}
      />
      <Button
        variant="primary"
        size="lg"
        onClick={onNextClick}
        disabled={!isCompleted}
      >
        다음으로
      </Button>
      <Button size="lg" onClick={onPrevClick} customClassName="only-mobile">
        이전으로
      </Button>
    </div>
  );
};

TermsTemplate.propTypes = {
  basic: PropTypes.bool,
  personal: PropTypes.bool,
  acs: PropTypes.bool,
  mkt_agreement_yn: PropTypes.bool,
  isAllChecked: PropTypes.bool,
  isCompleted: PropTypes.bool,
  onCheckBoxWithTextBoxClick: PropTypes.func,
  onMainCheckBoxClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
};

TermsTemplate.defaultProps = {};
