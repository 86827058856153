import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckBox, TextBox } from '../../atoms';

import './checkboxwithtextbox.scss';

export const CheckBoxWithTextBox = ({
  variant,
  size,
  label,
  id,
  name,
  checked,
  children,
  onCheckBoxClick,
  isError,
}) => {
  // NOTE arrow 클릭은 해당 molecules에서 handle한다.
  const [isExpanded, setIsExpanded] = useState(false);
  const onArrowClick = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="sb-checkbox-with-textbox">
      <CheckBox
        id={id}
        name={name}
        label={label}
        variant={variant}
        size={size}
        onArrowClick={onArrowClick}
        isExpanded={isExpanded}
        onCheckBoxClick={onCheckBoxClick}
        checked={checked}
        isError={isError}
      />
      {isExpanded && (
        <TextBox variant={variant} size={size}>
          {children}
        </TextBox>
      )}
    </div>
  );
};

CheckBoxWithTextBox.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  onArrowClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
};

CheckBoxWithTextBox.defaultProps = {
  variant: 'primary',
  size: 'lg',
  label: '아이펠 온라인 이용 약관',
};
