import {
  toast as toaster,
  Flip,
  ToastTransitionProps,
  ToastPosition,
  Theme,
} from 'react-toastify';

interface TOptions {
  position: ToastPosition | undefined;
  theme: Theme | undefined;
  autoClose?: number | undefined;
  transition?: ({
    children,
    position,
    preventExitTransition,
    done,
    nodeRef,
    isIn,
  }: ToastTransitionProps) => JSX.Element;
}

type TMessage = string | JSX.Element;

class Toast {
  options: TOptions;
  constructor() {
    this.options = {
      position: toaster.POSITION.TOP_RIGHT,
      theme: 'colored',
      transition: Flip,
    };
  }
  public success(message: TMessage) {
    toaster.success(message, this.options);
  }

  public error(message: TMessage) {
    toaster.error(message, this.options);
  }

  public warn(message: TMessage) {
    toaster.warn(message, this.options);
  }

  public info(message: TMessage) {
    toaster.info(message, this.options);
  }
}

export default new Toast();
