import { ReactNode } from 'react';

type TReferralText = {
  id: number;
  text: string;
  icon?: string | ReactNode;
  color?: string;
  backgroundColor?: string;
  link: string;
};

export const REFERRAL_TEXT_KOR_LIST: TReferralText[] = [
  {
    id: 1,
    icon: <span className={'text-2xl'}></span>,
    text: '⭐ 최신 글로벌 SW/AI 소식의 핵심만 쏙쏙! ⭐',
    backgroundColor: '#F7585C',
    color: '#fff',
    link: 'https://bit.ly/moduletter-archive',
  },
  {
    id: 2,
    icon: <span className={'text-2xl'}></span>,
    text: '모두레터 구독하고, 월요일 아침에 만나요 :)',
    backgroundColor: '#F9ED69',
    color: '#000',
    link: 'https://bit.ly/moduletter-archive',
  },
];
