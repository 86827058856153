import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowDown from '../../assets/arrowdown.svg';
import ArrowUp from '../../assets/arrowup.svg';
import './checkbox.scss';

// NOTE checkboxs' name attributes should be the same
export const CheckBox = ({
  type = 'checkbox',
  className,
  variant,
  size,
  label,
  id,
  name,
  isExpanded,
  checked,
  onCheckBoxClick,
  onArrowClick,
  isError,
}) => {
  return (
    <div
      className={classNames(
        'sb-checkbox',
        `sb-checkbox--${variant}`,
        `sb-checkbox--${size}`,
        className,
      )}
    >
      <div className="sb-checkbox__body">
        <input
          type={type}
          id={id}
          name={name}
          onChange={onCheckBoxClick}
          checked={checked}
        />
        <label htmlFor={id} className={classNames({ error: isError })}>
          {label}
        </label>
      </div>
      {variant === 'primary' && (
        <div className="sb-checkbox__button" onClick={onArrowClick}>
          <img
            src={isExpanded ? ArrowUp : ArrowDown}
            alt={isExpanded ? 'arrow up' : 'arrow down'}
          />
        </div>
      )}
    </div>
  );
};

CheckBox.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isExpanded: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  onArrowClick: PropTypes.func,
  checked: PropTypes.bool,
};

CheckBox.defaultProps = {
  variant: 'primary',
  size: 'lg',
  label: 'checkbox',
  id: 'checkbox',
  name: 'checkbox',
  isExpanded: false,
  onCheckboxClick: () => {},
  onArrowClick: () => {},
  checked: false,
};
