import PropTypes from 'prop-types';
import React from 'react';
import { RequiredDot } from '../RequiredDot';
import './roundSelect.scss';

export const RoundSelect = ({
  roundList,
  isContainerActive,
  roundId,
  displayedValue,
  handleOnClickRoundOptions,
  handleOnClickRoundSelect,
  isError,
  required,
}) => {
  return (
    <div className="sb-round-select">
      <div className="title flex items-center text-[12px] text-y-800 md:text-[16px]">
        회차선택 {required && <RequiredDot />}
      </div>
      <div className="selectBox">
        <div
          className={[
            'optionsContainer',
            `${isContainerActive ? 'active' : ''}`,
          ]
            .join(' ')
            .trim()}
        >
          {roundList.map((round, index) => {
            const { id, round_number, start_date, end_date, is_available } =
              round;
            return (
              <div
                className={
                  is_available ? 'roundOption' : 'roundOption unavailable'
                }
                key={index.toString()}
                onClick={(e) => handleOnClickRoundOptions(e, round)}
                value={id.toString()}
              >
                <input
                  className="radio"
                  type="radio"
                  id={round_number.toString()}
                  name="round"
                  value={id.toString()}
                />
                {/* 실제로 LABEL을 클릭하는 것이므로 value는 label에도 걸어놓는다. */}
                <label
                  className="roundLabel"
                  htmlFor={round_number.toString()}
                  value={id.toString()}
                >
                  {round_number.toString()}회차 ({start_date} ~ {end_date})
                  {!is_available && ' - 신청불가'}
                </label>
              </div>
            );
          })}
        </div>
        <div
          className={['roundSelected', `${isError && 'error'}`]
            .join(' ')
            .trim()}
          value={roundId}
          onClick={handleOnClickRoundSelect}
        >
          {displayedValue}
        </div>
      </div>
    </div>
  );
};

RoundSelect.propTypes = {
  isActive: PropTypes.bool,
};

RoundSelect.defaultProps = {
  isActive: false,
  roundList: [],
};
