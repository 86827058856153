import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';
import classNames from 'classnames';

type Props = {
  variant: 'primary' | 'secondary';
  color?: string;
  backgroundColor?: string;
  size: 'sm' | 'md' | 'lg';
  label: string;
};

export const Title = ({
  backgroundColor,
  color,
  label,
  size,
  variant,
  ...props
}: Props) => {
  return (
    <div
      className={classNames(
        'sb-title',
        `sb-title--${variant}`,
        `sb-title--${size}`,
      )}
      style={{ color, backgroundColor }}
      {...props}
    >
      {label}
    </div>
  );
};

// propTypes
Title.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  variant: PropTypes.oneOf(['primary', 'secondary']),
  /**
   * What background color to use
   */
  color: PropTypes.string,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

// defaultProps
Title.defaultProps = {
  backgroundColor: null,
  variant: 'primary',
  size: 'lg',
  label: 'Title',
};
