import modalsState, { Modal } from './recoil/atom/modalsState';

const modalNexus: any = {};

let modals: Modal[] = [];

export default modalNexus;

export function pushModal<T = any>(modal: Modal<T>) {
  checkModalContainerRendered();
  modals = [...modals, modal];
  modalNexus.set(modalsState, modals);
}

export function removeModal(key: string) {
  checkModalContainerRendered();
  modals = modals.filter(({ key: modalKey }) => modalKey !== key);
  modalNexus.set(modalsState, modals);
}

function checkModalContainerRendered() {
  if (!modalNexus.get || !modalNexus) {
    throw new Error('ModalContainer not rendered!!!');
  }
}
