import React from 'react';

import { Text, Divider } from '../../atoms';
import './listWithTitle.scss';

export const ListWithTitle = ({ title, subList, foldable, ...props }) => {
  //TODO: 여기서 list는 낱개로 해야할 듯?
  const renderSubList = subList.map((item, index) => (
    <Text size="xs" label={item} key={index} />
  ));

  if (foldable) {
    return (
      <div className="list">
        <details>
          <summary className="list__title">
            <Text size="sm" label={title} />
          </summary>
          <div className="list__sublist">{renderSubList}</div>
        </details>
        <Divider direction="horizontal" />
      </div>
    );
  }

  return (
    <div className="list">
      <div className="list__title">
        <Text size="sm" label={title} />
      </div>
      <div className="list__sublist">{renderSubList}</div>
      <Divider direction="horizontal" />
    </div>
  );
};

ListWithTitle.defaultProps = {
  subList: [],
  foldable: false,
};
