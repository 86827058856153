import { SVGProps } from 'react';

export const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="3.37921"
        width="4.73466"
        height="36.2775"
        rx="2.36733"
        transform="rotate(-45.538 0 3.37921)"
        fill="#0C4254"
      />
      <rect
        x="26.3286"
        width="4.73466"
        height="36.2775"
        rx="2.36733"
        transform="rotate(45 26.3286 0)"
        fill="#0C4254"
      />
    </svg>
  );
};
