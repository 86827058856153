import { Typhography } from '@online/aui/Typhography';
import PropTypes from 'prop-types';
import './progress.scss';

// TODO: 0921 - tax : 해당 부분 due 모양이 계속 안보여서 일단 이대로 진행. 추후 ts로 리팩토링!

export const Progress = ({ size, progress, due, color }) => {
  console.log('0921 due', due);

  const day = due ? due.D : null;
  const isLate = day < 0 ? true : false;
  const prgrsColor = isLate ? '#FF0000' : color;

  return (
    <div className="progress__wrapper">
      <div className={`progress progress--${size}`}>
        <div className={`progress__percentage`}>
          <Typhography.Body2>{progress}%</Typhography.Body2>
        </div>
        <div className={`progress__graph progress__graph--${size}`}>
          <div
            className="progress__graphinner"
            style={{ background: prgrsColor, width: progress + '%' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

Progress.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  progress: PropTypes.number.isRequired,
  color: PropTypes.string,
};
Progress.defaultProps = {
  size: 'sm',
  progress: 50,
};
