import jwt_decode from 'jwt-decode';

import { ACCESS_TOKEN, BUILD, BUILD_USAGE, Roles } from '@constants';
import { Http, User } from '@types';

type TokenUserType = {
  is_student: boolean;
  is_coach: boolean;
  is_editor: boolean;
  is_cloud: boolean;
  is_staff: boolean;
  k_digital: boolean;
};

export const updateObject = <T extends object>(
  oldObject: T,
  updatedProperties: Partial<T>,
) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

// NOTE token으로는 store에 어떤 assign도 하지 않기로 했기에 삭제함

/**
 * @author brandonwie
 * @date 06-20-2022
 * @editedAt 10-14-2022
 * @param {IResponseAccount} data
 * @returns {TUserState}
 * @description 아이펠 온라인 /account api를 통해 가져온 정보를 현재 앱에 맞는 TUserState 형태로 변환하는 함수
 * ! 출석체크는 KDC의 경우 "나의 강의실" 안에서 이루어지기 때문에, hasAttendanceChecked는 제외됨
 */
export const fetchedUserDataToUserState = (
  data: Http.Response.TDispatchReturnTypeForAccount,
): User.TStoreUserState | null => {
  const {
    id,
    email,
    username,
    role,
    photo_url,
    first_name, // @wie first_name은 이름 전체값을 가지고 있으며 name에 저장된다.
    phone_number,
    local_school,
    cloud,
    hasAttendanceChecked,
    view_attendance,
    view_forum,
    session_id,
  } = data;

  let hideForum = true; // 일단 static하게 설정
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) return null; // 토큰 localStorage에서 강제로 지우는 경우
  const decodedToken = jwt_decode<User.TDecodedToken>(token);
  const result: User.TStoreUserState = {
    userId: id,
    username,
    name: first_name,
    phoneNumber: phone_number,
    email,
    hideForum,
    role: {
      preset: role,
      scope: [],
      localKernelOnly: !cloud.is_available, // will be deprecated
    },
    expirationTime: decodedToken.exp * 1000,
    photoUrl: photo_url,
    testType: {
      is_target: false,
      mbti: null,
      persona: null,
    },
    sessionId: session_id,
    viewAttendance: view_attendance,
    viewForum: view_forum,
    localSchool: local_school,
    hasAttendanceChecked: BUILD_USAGE !== 'kdc' ? true : hasAttendanceChecked,
  };

  localStorage.setItem('viewForum', view_forum ? 'true' : 'false');
  return result;
};

export const parseRolesHelper = (userType: TokenUserType) => {
  const { is_cloud, is_staff, is_student, is_editor, is_coach, k_digital } =
    userType;
  let roles: Roles[] = [
    is_staff ? Roles.STAFF : null,
    is_student ? Roles.STUDENT : null,
    is_editor ? Roles.EDITOR : null,
    is_coach ? Roles.COACH : null,
    k_digital ? Roles.K_DIGITAL : null,
  ].filter((value) => value !== null) as Roles[];

  const result: User.TStoreUserRoles = {
    preset: roles.filter((value) => value !== null),
    scope: [],
    localKernelOnly: !is_cloud,
  };
  return result;
};
