import * as React from 'react';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';
import { KDT_MARKETING_URL } from '@constants';

const variants = {
  enter: (direction: number) => {
    return {
      y: direction > 0 ? 100 : -100,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      y: direction < 0 ? 100 : -100,
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

type TCarouselProps = {
  data: any[];
};

export const Carousel = ({ data }: TCarouselProps) => {
  const [[page, direction], setPage] = useState([0, 0]);

  const dataIndex = wrap(0, data.length, page);

  React.useEffect(() => {
    const interval = setInterval(() => {
      paginate(1);
    }, 3000);
    return () => clearInterval(interval);
  }, [page]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <div className={'relative flex items-center justify-center '}>
      <AnimatePresence initial={false} custom={direction}>
        <a
          className="inset-x-0 bottom-0 sm:flex sm:justify-center"
          href={data[dataIndex].link}
          target="_blank"
          rel="noreferrer"
          id={`banner-${data[dataIndex].id}}`}
        >
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              y: { type: 'spring', stiffness: 100, damping: 30 },
              opacity: { duration: 0.2 },
              ease: 'easeInOut',
            }}
            style={{
              backgroundColor: data[dataIndex].backgroundColor,
              color: data[dataIndex].color,
              minWidth: '340px',
              padding: '10px 30px',
              borderRadius: '12px',
              fontFamily: 'Gmarket Sans',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
            // drag="x"
            // dragConstraints={{ left: 0, right: 0 }}
            // dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.y, velocity.y);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {data[dataIndex].text}
          </motion.div>
        </a>
      </AnimatePresence>
    </div>
  );
};
