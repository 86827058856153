export const STAGE = '/stages';
export const TODAY = '/today';

export const EDITOR_TODAY = '/today-ed';
export const EDITOR_STAGE = '/stages-ed';

export const ISSUES = '/issues';
export const ISSUES_ALL = '/issues-all';
export const RUBRIC = '/rubric';

export const ACHIEVE = '/achieve';
export const PROFILE = '/profile';
export const ACCOUNT = '/account';

export const PASSWORD_FIND = '/passwordFind';

export const DASHBOARD = '/dashboard';
export const DASHBOARD_USERS = '/dashboard/users';
export const DASHBOARD_USERS_DETAIL = '/dashboard/users/:id';

export const FORUM = '/forum';
export const FORUM_DETAIL = '/forum/:id/';
export const FORUM_SEARCH = '/search/forum/';
export const FORUM_ASK = '/ask';

export const NOTICE = '/notice';

export const PERSONA_SURVEY = '/persona/survey';
export const PERSONA_RESULTS = '/persona/results';
