import { atom } from 'recoil';
import { MessageBannerProps } from '@components/MessageBanner';

export const globalMessageBannerProps = atom<MessageBannerProps | null>({
  key: 'globalMessageBannerProps',
  default: null,
});
/**
 * fetchUseCloud 통신값을 state로 관리
 */
export const CloudConnectType = atom<'local' | 'cloud' | null>({
  key: 'CloudConnectType',
  default: null,
});
