import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '../../atoms';
import './textfieldwithbutton.scss';

// REVIEW 꼭 구별을 해줘야 하는 특징적인 것들만 놔두고 나머지는 전부 spread operator로 넘김
export const TextFieldWithButton = ({
  buttonLabel,
  isTextFieldDisabled,
  isButtonDisabled,
  onClick,
  ...props
}) => {
  return (
    <div className="sb-textfield-with-button">
      <TextField disabled={isTextFieldDisabled} {...props}>
        <div className="button__wrapper">
          <Button
            type="button"
            variant="secondary"
            size="xs"
            onClick={onClick}
            name={props.name}
            disabled={isButtonDisabled}
          >
            {buttonLabel}
          </Button>
        </div>
      </TextField>
    </div>
  );
};
// NOTE Button size는 현재 버전은 xs로 고정입니다.
TextFieldWithButton.propTypes = {
  buttonLabel: PropTypes.node,
  onButtonClick: PropTypes.func,
  isTextFieldDisabled: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
};

TextFieldWithButton.defaultProps = {
  size: 'md',
  buttonLabel: 'verify',
};
