import React from 'react';
import { Text, Divider } from '../../../atoms';

import { Classroom } from '@types';

import './detailTitleInfo.scss';

interface DetailTitleInfoProps {
  title?: string;
  roundSchedule?: Classroom.TRoundSchedule;
  remainDay?: number;
}

const DetailTitleInfoV2: React.FC<DetailTitleInfoProps> = ({
  title,
  roundSchedule,
  remainDay,
}) => {
  console.log('0415 DetailTitleInfo ', title, roundSchedule);

  return (
    <div className="detail__title-wrap">
      <div className="detail__title">
        <Text
          size="xl"
          label={`[${roundSchedule?.round_number}회차] ${title}`}
        />
      </div>
      <div className="detail__sub">
        <div className="detail__sub-date">
          <Text
            label={`${roundSchedule?.start_date} ~ ${roundSchedule?.end_date}`}
            size="xxs"
          />
        </div>
        <div className="detail__sub-remain">
          <Text label={`${remainDay}일남음`} size="xxs" />
        </div>
      </div>
      <Divider direction="horizontal" />
    </div>
  );
};

export default DetailTitleInfoV2;
