import { K_DIGITAL_CAMPUS_ID, Roles } from '@constants';
import { useMemo } from 'react';
import CloseIcon from '../../assets/cross.svg';
import { Button, Overlay } from '../../atoms';
import AuiButton from '@online/aui/Button';
import { useAppSelector } from '@store/hooks';
import './modal.scss';
import { selectUser, selectCurrentCampusId } from '@store/slices';

export const Modal = ({
  title,
  buttonTitle,
  onClickClose,
  onClickConfirm,
  children,
}) => {
  const currentCampusId = useAppSelector(selectCurrentCampusId);

  return (
    <Overlay>
      <div className="modalWrap">
        <div className="modalHeader">
          <div className="modalTitle">{title}</div>
          <div className="modalClose">
            <img src={CloseIcon} alt="close icon" onClick={onClickClose} />
          </div>
        </div>
        <div className="modalContent">{children}</div>
        <div className="modalFooter">
          <AuiButton onClick={onClickConfirm}>{buttonTitle}</AuiButton>
        </div>
      </div>
    </Overlay>
  );
};

Modal.defaultProps = {
  title: 'modal title',
};
