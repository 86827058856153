import React, { ButtonHTMLAttributes } from 'react';
// import { PREFIX } from '@aui/core';
import classNames from 'classnames';

import './_Button.scss';
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}
const PREFIX = 'aui';
const ButtonClassName = `${PREFIX}-button`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      type = 'button',
      children,
      className,
      disabled,
      isLoading,
      ...rest
    } = props;
    return (
      <button
        type={type}
        className={classNames(ButtonClassName, className, { isLoading })}
        aria-busy={isLoading}
        aria-disabled={disabled || isLoading}
        disabled={disabled || isLoading}
        {...rest}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
