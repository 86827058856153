import jwtDecode from 'jwt-decode';
import { Sentry } from '@utils';
import { User } from '../types';
import { useMemo } from 'react';
import { ACCESS_TOKEN } from '../constants';

/**
 * jwt token decode
 * @param {string} token
 */
export const decodeToken = (token: string | null) => {
  console.log('%c 유저님의 정보를 분석하는 중이에요 :)', 'color: #00ff00');
  if (!token) {
    return null;
  }

  try {
    return jwtDecode<User.TDecodedToken>(token);
  } catch (error) {
    Sentry.captureException({ type: 'JWT_DECODE', error });
    console.error('[validateToken][decodeToken()] decode error:', { error });
    return null;
  }
};

/**
 * token 유효성검사
 * @param {string} token
 * @description exp is in seconds
 */
export const validateToken = (token: string | null) => {
  if (!token) return false;
  const payload = decodeToken(token);
  if (!payload) {
    return false;
  }
  if (payload.exp && payload.exp * 1000 < Date.now()) {
    return false;
  }
  return true;
};

/**
 * @description 1개의 캠퍼스 아이디를 가지고 있는가
 * @return {boolean} true: 1개의 캠퍼스 아이디를 가지고 있음
 * */
export const useValidCampusId = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const result = useMemo(() => {
    const payload = decodeToken(token);
    if (!payload) return false;
    if (payload.local_school_id !== (-1 || null)) return true;
    return false;
  }, [token]);

  return result;
};

/**
 * @description 토큰 속 아이디 값 반환
 * @return {number | null} local_school_id
 */
export const useCurrentCampusId = () => {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const result = useMemo(() => {
    const payload = decodeToken(token);
    if (!payload) return null;
    return payload.local_school_id;
  }, [token]);

  return result;
};
