import React from 'react';
import { Text, CheckBox } from '../../atoms';
import './confirmblockwithradio.scss';

/**
 *
 * @param {React.PropsWithChildren} props
 * @param {string} props.label
 * @param {React.ReactNode} props.warningMessage
 * @param {React.ReactNode} props.boxMessage
 * @returns {JSX.Element} notification block for purchase page
 */
export const ConfirmBlockWithRadio = ({
  confirmId,
  negativeId,
  name,
  label,
  warningMessage,
  boxMessage,
  affirmativeLabel,
  negativeLabel,
  isConfirmOptionChecked,
  isNegativeOptionChecked,
  handleCheckBoxClick,
}) => {
  return (
    <div className="confirmBlockWithRadio">
      <Text size="sm" label={label} />
      <div className="warningMessage">{warningMessage}</div>
      <div className="messageBox">{boxMessage}</div>
      <div className="radio__wrapper">
        <CheckBox
          variant="secondary"
          id={confirmId}
          name={name} // share names
          label={affirmativeLabel}
          onCheckBoxClick={handleCheckBoxClick}
          checked={isConfirmOptionChecked}
        />
        <CheckBox
          variant="secondary"
          id={negativeId}
          name={name} // share names
          label={negativeLabel}
          onCheckBoxClick={handleCheckBoxClick}
          checked={isNegativeOptionChecked}
        />
      </div>
    </div>
  );
};
