import { instance, instanceWOT } from '../provider';

/**
 *
 * @param {String} stepSlug
 * @returns
 */
export const getLMSContents = (stepSlug) => {
  return instance.get(`/content/${stepSlug}/`);
};

/**
 * @param {String} slug
 **/
export const getSampleContents = (slug) => {
  return instanceWOT.get(`/content/sample/${slug}`);
};

/**
 *
 * @param {Number} apiVersion
 * @param {Number} nodeVersionId
 * @returns
 */
export const getRubricContents = (apiVersion, nodeVersionId) => {
  return instance.get(`/api-nodes/${apiVersion}/${nodeVersionId}/rubrics/`);
};

/**
 *
 * @param {Number} nodeVersionId
 * @param {Number} stepId
 * @param {Number} order
 * @returns
 */

export const updateUserStepProgress = (nodeVersionId, stepId, order) => {
  let formData = new FormData();
  formData.append('node_version_id', nodeVersionId);
  formData.append('step_id', stepId);
  formData.append('order', order);

  return instance.post('/api-steps/updateUserStepCompt/', formData);
};

export const updateUserNodeProgress = (nodeProgressId) => {
  let formData = new FormData();
  formData.append('node_progrs_id', nodeProgressId);
  return instance.post(`api-node-progrs/updateUserNodeProgrsKdc/`, formData);
};
