import { AUI_PREFIX } from '@constants';
import classNames from 'classnames';
import React, { HTMLAttributes, ReactNode } from 'react';

export interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  id?: string;
  variant?: 'primary' | 'secondary';
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

const TitleClassName = `${AUI_PREFIX}-title`;

const Title = React.forwardRef<HTMLDivElement, TitleProps>((props, ref) => {
  const {
    className,
    size,
    variant = 'primary',
    children = 'This is title',
    ...restProps
  } = props;

  return (
    <div
      className={classNames(
        TitleClassName,
        className,
        `${TitleClassName}--${size}`,
        {
          [`${TitleClassName}--`.concat(variant)]: variant,
        },
      )}
      {...restProps}
      ref={ref}
    >
      {children}
    </div>
  );
});

Title.displayName = 'Title';

export default Title;
