import { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Title,
  Text,
  TextBox,
  CheckBox,
  Button,
  RoundSelect,
} from '../../atoms';
import { CheckBoxWithTextBox, PriceField } from '../../molecules';
import { ConfirmBlockWithRadio } from './ConfirmBlockWithRadio';
import WarningIcon from '../../assets/warning.svg';
import './purchasetemplate.scss';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { isMobileState } from '../../../mobile-detector';

export const PurchaseTemplate = ({
  imageUrl,
  originalPrice,
  discountRate,
  discountedAmount,
  finalPrice,
  courseTitle,
  courseDesc,
  roundList,
  isAcsTermChecked,
  isAiffelTermChecked,
  isAllTermsChecked,
  handleOnClickTerms,
  handleOnClickSelected,
  handleOnClickOptions,
  displayedValue,
  roundId,
  isContainerActive,
  handleOnClickPurchase,
  termsOfDrop,
  termsOfKdc,
  termsOfRefund,
  handleEligibilityOptionCheck,
  isUserNBCHolder,
  isUserNotNBCHolder,
  isUserEligibleForKDC,
  isUserNotEligibleForKDC,
  isReadyToSubmit,
}) => {
  const isMobile = useRecoilValue(isMobileState);

  const PurchaseItemInfo = useCallback(
    () => (
      <>
        <div className="image__wrapper">
          <img src={imageUrl} alt="course" />
        </div>
        <Title size="md" label={courseTitle} />
        <div className="courseAbstract">{courseDesc}</div>
        <PriceField
          originalPrice={originalPrice}
          discountRate={discountRate}
          discountedAmount={discountedAmount}
          finalPrice={finalPrice}
          duration={null}
          difficulty={null}
        />
      </>
    ),
    [
      imageUrl,
      courseTitle,
      courseDesc,
      originalPrice,
      discountRate,
      discountedAmount,
      finalPrice,
    ],
  );

  const PurchaseForm = useCallback(() => {
    return (
      <>
        <TextBox
          fitContent
          className="kdc-student-warning"
          variant="secondary"
          size="lg"
        >
          <img src={WarningIcon} alt="warning icon" /> 해당 AIFFEL 강의는
          내일배움카드가 있는 회원에게만 수강료의 90%를 지원받아 신청할 수
          있습니다. (수료시 나머지 부담 자부담 10% 전액 환급)
        </TextBox>
        <RoundSelect
          roundList={roundList}
          handleOnClickOptions={handleOnClickOptions}
          handleOnClickSelected={handleOnClickSelected}
          displayedValue={displayedValue}
          roundId={roundId}
          isContainerActive={isContainerActive}
          isError={roundId === null && true}
        />
        <ExtraConfirmationDiv className="extraConfirmationBlock">
          <ConfirmBlockWithRadio
            confirmId="confirmNBC"
            negativeId="negativeNBC"
            name="checkNBC"
            label="[필수] 내일배움카드 발급확인"
            warningMessage={
              '본 과정은 내일배움카드가 필수입니다. 내일배움카드로 결제해야 정상적으로 수강신청할 수 있습니다.'
            }
            boxMessage={'내일배움카드를 받급 받으셨나요?'}
            affirmativeLabel={'네, 발급 받았습니다.'}
            negativeLabel={'아니오, 받지 않았습니다.'}
            handleCheckBoxClick={handleEligibilityOptionCheck}
            isConfirmOptionChecked={isUserNBCHolder}
            isNegativeOptionChecked={isUserNotNBCHolder}
          />
          <ConfirmBlockWithRadio
            confirmId="confirmEligibility"
            negativeId="negativeEligibility"
            name="checkEligibility"
            label="[필수] 대상확인"
            warningMessage={
              '본 과정은 K-디지털 기초역량훈련으로 아래 항목 중 1개 이상에 해당하는 분들만 신청가능합니다.'
            }
            boxMessage={applicantRestrictionInfo}
            affirmativeLabel={'네, 해당합니다.'}
            negativeLabel={'아니오, 해당하지 않습니다.'}
            handleCheckBoxClick={handleEligibilityOptionCheck}
            isConfirmOptionChecked={isUserEligibleForKDC}
            isNegativeOptionChecked={isUserNotEligibleForKDC}
          />
        </ExtraConfirmationDiv>
        <Text size="sm" label="[필수] 약관동의" />
        <CheckBoxWithTextBox
          id="acsTerm"
          name="acsTerm"
          label="K-디지털 기초역량훈련 신청동의"
          checked={isAcsTermChecked}
          onCheckBoxClick={handleOnClickTerms}
          isError={!isAcsTermChecked && true}
        >
          {termsOfKdc}
        </CheckBoxWithTextBox>
        <CheckBoxWithTextBox
          id="aiffelTerm"
          name="aiffelTerm"
          label="미수료 또는 수강포기시 패널티 확인동의"
          checked={isAiffelTermChecked}
          onCheckBoxClick={handleOnClickTerms}
          isError={!isAiffelTermChecked && true}
        >
          {termsOfDrop}
        </CheckBoxWithTextBox>

        {/* NOTE Check All button */}
        <CheckBox
          id="allChecked"
          className="allChecked"
          name="allChecked"
          variant="secondary"
          label="모두 확인함"
          // manipulate check button
          checked={isAllTermsChecked}
          // 전체동의용 handler
          onCheckBoxClick={handleOnClickTerms}
        />
        <TextBox className="terms-of-refund" variant="secondary" size="lg">
          {termsOfRefund}
        </TextBox>
        <TextBox className="kdc-card-warning" variant="secondary" size="lg">
          <img src={WarningIcon} alt="warning icon" /> 결제 시에는{' '}
          <span>반드시 내일배움카드로 결제</span>해주세요. 다른 카드로 결제할 시
          구매가 취소될 수 있습니다.
        </TextBox>
        <Button variant="primary" size="lg" onClick={handleOnClickPurchase}>
          구매하기
        </Button>
      </>
    );
  }, [
    displayedValue,
    handleEligibilityOptionCheck,
    handleOnClickOptions,
    handleOnClickPurchase,
    handleOnClickSelected,
    handleOnClickTerms,
    isAcsTermChecked,
    isAiffelTermChecked,
    isAllTermsChecked,
    isContainerActive,
    isUserEligibleForKDC,
    isUserNBCHolder,
    isUserNotEligibleForKDC,
    isUserNotNBCHolder,
    roundId,
    roundList,
    termsOfDrop,
    termsOfKdc,
    termsOfRefund,
  ]);

  return isMobile ? (
    <div className="sb-purchase-template">
      <Title variant="primary" size="lg" label="Purchase" />
      <div className="purchase-item-info">
        <PurchaseItemInfo />
      </div>
      <PurchaseForm />
    </div>
  ) : (
    <div className="sb-purchase-template">
      <div className="sb-purchase--left">
        <Title variant="primary" size="lg" label="Purchase" />
        <PurchaseForm />
      </div>
      <div className="sb-purchase--middle"></div>
      <div className="sb-purchase--right">
        <PurchaseItemInfo />
      </div>
    </div>
  );
};

PurchaseTemplate.propTypes = {
  acs: PropTypes.bool,
  kDigitalPenalty: PropTypes.bool,
  isAllTermsChecked: PropTypes.bool,
  onCheckBoxWithTextBoxClick: PropTypes.func,
  onMainCheckBoxClick: PropTypes.func,
};
PurchaseTemplate.defaultProps = {
  roundList: [],
};

const applicantRestrictionInfo = (
  <div>
    <div>1. 만 34세 미만 교육희망자 전원</div>
    <span style={{ fontSize: '0.75rem', paddingLeft: '0.5rem' }}>
      *군필의 경우, 군 복무기간만큼 나이를 적용 후 만 39세까지 가능
    </span>
    <div>2. 만 35세 ~ 54세 중 현재 구직자(미취업) 상태인 자</div>
  </div>
);

const ExtraConfirmationDiv = styled.div`
  & {
    .confirmBlockWithRadio:nth-child(2) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
`;
