import { media } from '@online/aui/Core';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import * as AUITextStyles from './AuiTextStyles';

export type Typo = keyof typeof typographyList;
export type TypoElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';

interface OwnProps {
  md: Typo;
}

export interface TypoProps {
  lg?: Typo;
  sm?: Typo;
  color?: string;
  textAlign?: string;
  fontWeight?: string | number;
  className?: string;
  children?: React.ReactNode;
  element?: TypoElement;
  'data-element-name'?: string;
}

interface HeadlinProps {
  display?: 2 | 3 | '2' | '3';
}

type Props = OwnProps & TypoProps;
export type HeadlineTypoProps = TypoProps & HeadlinProps;

const customStyle = css<{ textAlign?: string }>`
  margin: 0;
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
`;

const displayStyle = css<{ display?: string | number }>`
  ${(props) =>
    props.display &&
    [2, '2'].includes(props.display) &&
    css`
      ${AUITextStyles.display2};
    `};
  ${(props) =>
    props.display &&
    [3, '3'].includes(props.display) &&
    css`
      ${AUITextStyles.display3};
    `};
`;

const defaultElement = {
  Headline1: 'h1',
  Headline2: 'h2',
  Headline3: 'h3',
  Subtitle1: 'h4',
  Subtitle2: 'h4',
  Body1: 'div',
  Body2: 'div',
  Caption1: 'div',
  Caption2: 'div',
};

const typographyList = {
  Headline1: AUITextStyles.headline1,
  Headline2: AUITextStyles.headline2,
  Headline3: AUITextStyles.headline3,
  Subtitle1: AUITextStyles.subtitle1,
  Subtitle2: AUITextStyles.subtitle2,
  Body1: AUITextStyles.body1,
  Body2: AUITextStyles.body2,
  Caption1: AUITextStyles.caption1,
  Caption2: AUITextStyles.caption2,
};

const TextElement = styled.div<Props>`
  ${(props) => typographyList[props.md]}
  ${displayStyle}
  ${customStyle}
  ${(props) =>
    props.sm &&
    css`
      ${media.sm`
        ${typographyList[props.sm]}
        ${displayStyle}
        ${customStyle}
      `}
    `}
  ${(props) =>
    props.lg &&
    css`
      ${media.lg`
        ${typographyList[props.lg]}
        ${displayStyle}
        ${customStyle}
      `}
    `}
`;

export class BaseTypography extends PureComponent<Props> {
  public render() {
    const {
      lg,
      sm,
      md,
      element = defaultElement[this.props.md] as TypoElement,
      children,
      ...restProps
    } = this.props;
    return (
      <TextElement as={element} lg={lg} sm={sm} md={md} {...restProps}>
        {children}
      </TextElement>
    );
  }
}
