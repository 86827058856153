import { useState, useEffect } from 'react';

export const useWindowSizeTracker = () => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    window.removeEventListener('resize', handleResize);

    setScreenWidth(window.innerWidth);
    setTimeout(() => {
      window.addEventListener('resize', handleResize);
    }, 100);
  };

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return screenWidth;
  //TODO: 후에 모바일인지 아닌지도 판단하는 함수 추가 등 확장 가능
};
