import { Outlet } from 'react-router-dom';

import { TRoute } from '../../routes/TRoute';
import { useAppSelector } from '@store/hooks';
import { selectRole, selectUser } from '@store/slices';
import Sidebar, { TSidebarRouteGroup } from '../Sidebar';
import styles from './index.module.scss';

export type TDashboardRouteGroup = {
  header: string;
  routes: {
    title: string;
    route: TRoute;
    display?: boolean;
    attendanceOnly?: boolean;
  }[];
};

interface DashboardPageLayoutProps {
  routeGroups: TDashboardRouteGroup[];
}

const DashboardPageLayout: React.FC<DashboardPageLayoutProps> = ({
  routeGroups,
}) => {
  const roles = useAppSelector(selectRole);
  const hasLoggedIn = Boolean(useAppSelector(selectUser));

  const viewAttendance = useAppSelector(selectUser)?.viewAttendance ?? false;

  return (
    <div className={styles.managePageLayout}>
      <div className={styles.pageContents}>
        <Sidebar
          className={styles.left}
          routeGroups={routeGroups.map<TSidebarRouteGroup>(
            ({ header, routes }) => ({
              header,
              routes: routes
                .filter(({ route, display, attendanceOnly }) => {
                  if (
                    display === false ||
                    (attendanceOnly === true && viewAttendance === false)
                  ) {
                    return false;
                  }
                  let isBlocked = true;
                  if (route.private || route.allowedPermissions) {
                    if (route.allowedPermissions === undefined) {
                      isBlocked = !hasLoggedIn;
                    } else {
                      isBlocked =
                        !roles ||
                        roles.every(
                          (role) => !route.allowedPermissions!.includes(role),
                        );
                    }
                  } else {
                    isBlocked = false;
                  }
                  return !isBlocked;
                })
                .map(({ title, route }) => ({ path: route.path, title })),
            }),
          )}
        />
        <div className={styles.right}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardPageLayout;
