import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Text } from '../../atoms';
import BannerButton from '../../assets/rightArrowButton.svg';
import QuestionMark from '../../assets/questionMark.svg';
import FAQ from '../../assets/faq.svg';
import './secondbanner.scss';

export const SecondBanner = ({ message, url, icon }) => {
  const imageSource = () => {
    switch (icon) {
      case 'questionMark':
        return QuestionMark;
      case 'faq':
        return FAQ;
      default:
        return;
    }
  };

  return (
    <div className="sb-banner">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Text size="lg" label={message} />
        <div className="sb-banner__arrow">
          <img src={BannerButton} alt="right arrow button" />
        </div>
        <div
          className={classNames(
            'sb-banner__icon',
            { faq: icon === 'faq' },
            { questionMark: icon === 'questionMark' },
          )}
        >
          <img
            src={imageSource()}
            alt={
              icon === 'faq' ? 'frequently asked questions' : 'question mark'
            }
          />
        </div>
      </a>
    </div>
  );
};

SecondBanner.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SecondBanner.defaultProps = {
  icon: 'questionMark',
  message: 'AIFFEL 이용이 처음이신가요?',
  link: 'https://aiffel.gitbook.io/aiffelguide',
};
