import { useMemo } from 'react';
import { User } from '@types';

export const useHasRole = (
  allowedRoles: User.TRoles[] | undefined,
  userRoles: User.TRoles[] | undefined,
) => {
  return useMemo(() => {
    // Route에 배정된 Role이 특정되지 않은 경우 모든 유저에게 허용
    if (!allowedRoles) return true;
    // 유저의 Role이 undefined인 경우 === type B 토큰 없는 경우
    if (!userRoles) return false;
    return userRoles.some((role) => allowedRoles.includes(role));
  }, [allowedRoles, userRoles]);
};
