import React from 'react';
import classNames from 'classnames';

import { goBack } from '../../../legacy/routing/goTo';

import './button.scss';

type ButtonProps = {
  /**
   * Is this the principal call to action on the page?
   */
  variant?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'navigate'
    | 'success'
    | 'danger'
    | 'warning';
  /**
   * Customized text color?
   */
  color?: string;
  /**
   * Customized background color?
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /**
   * Is the button disabled?
   */
  disabled?: boolean;
  /**
   * Is the button loading?
   */
  loading?: boolean;
  /**
   * Optional click handler
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  customClassName?: string;
  history?: any;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Variant UI component for user interaction
 */

export const Button = ({
  variant = 'default',
  type,
  color,
  backgroundColor,
  size = 'sm',
  children,
  name,
  disabled = false,
  loading = false,
  history,
  onClick,
  customClassName,
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={classNames(
        'sb-button',
        `sb-button--${variant}`,
        `sb-button--${size}`,
        { loading },
        { customClassName },
      )}
      style={{ color, backgroundColor }}
      disabled={disabled}
      name={name}
      aria-label={`${children} button`}
      // if onClick function is given, then it ignores the goBack function when it's navigate
      onClick={
        !onClick && variant === 'navigate' ? (e) => goBack(history) : onClick
      }
      {...props}
    >
      {children}
    </button>
  );
};
