import ReactDOM from 'react-dom';

import { useRecoilValue, useRecoilCallback } from 'recoil';
import modalNexus from './modalNexus';
import modalsState from './recoil/atom/modalsState';

type ModalContainerProps = {};
export default function ModalContainer(containerProps: ModalContainerProps) {
  modalNexus.get = useRecoilCallback(
    ({ snapshot }) =>
      function (atom: any) {
        return snapshot.getLoadable(atom).contents;
      },
    [],
  );
  modalNexus.getPromise = useRecoilCallback(
    ({ snapshot }) =>
      function (atom: any) {
        return snapshot.getPromise(atom);
      },
    [],
  );
  modalNexus.set = useRecoilCallback(({ set }) => set, []);
  modalNexus.reset = useRecoilCallback(({ reset }) => reset, []);

  const modals = useRecoilValue(modalsState);

  if (modals.length > 0) {
    const body = window.document.body;
    return ReactDOM.createPortal(
      <div {...containerProps}>
        {modals.map(({ key, component: ModalComponent, ...modalProps }) => {
          return <ModalComponent key={key} {...modalProps} />;
        })}
      </div>,
      body,
    );
  }

  return null;
}

ModalContainer.defaultProps = {
  className: 'modal-container',
};
