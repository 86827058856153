import { SVGProps } from 'react';

export const CourseApplyConfirmIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="70"
      height="58"
      viewBox="0 0 70 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 57.8733L0.22998 35.1033L10.6066 24.7266L23 37.1566L59.2266 0.893311L69.6033 11.27L23 57.8733Z"
        fill="#FFCD05"
      />
    </svg>
  );
};
