import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Api as instance } from '@api';
import { ACCESS_TOKEN, MESSAGE } from '@constants';

import { Http, User } from '@types';
import { HotToast } from '@utils';
import { decodeToken, validateToken } from '@utils/validateToken';

import store, { RootState } from '..';
import { clearStorageAndTimer } from './helper';
import {
  login,
  fetchAccountInfo,
  refreshTokenWithCampusId,
} from './asyncThunks';
import {
  fetchAccountAndAttendance,
  fetchLogin,
} from './combinedAsyncThunks';

import { Sentry } from '@utils';
import { fetchedUserDataToUserState } from '../utility';

export type TInitialState = {
  token: string | null;
  decodedToken: User.TDecodedToken | null;
  error: any;
  user: User.TStoreUserState | null;
  isHijackMode: boolean;
  loading: boolean;
  initialLoading: boolean;
};

const decodedToken = decodeToken(localStorage.getItem(ACCESS_TOKEN));
const initialState: TInitialState = {
  token: null,
  decodedToken: decodedToken,
  error: null,
  user: null,
  isHijackMode: false,
  loading: false,
  // initialLoading triggers /account request; therefore, it checks if the user has valid campus id and agreed to terms and conditions (agreed to terms and conditions means that the user got certified with the phone number)
  // initialLoading은 /accout 요청을 트리거하기 때문에, 유저가 유효한 캠퍼스 아이디를 가지고 있고, 이용약관에 동의했는지를 확인합니다. (이용약관에 동의했다는 것은, 유저가 휴대폰 번호로 인증을 받았다는 것을 의미합니다.)
  initialLoading: Boolean(
    typeof decodedToken?.local_school_id === 'number' &&
      decodedToken?.local_school_id >= 1 &&
      decodedToken?.term_agreement_yn === true,
  ),
};

interface AsyncThunkConfig {
  rejectValue: string;
  rejectMeta: string;
}

// #region nbcard 유무 조회
// TODO: 택수님 해당 message const에 넣어주시고 axios error 분기는 메시지가 같으면 없애는게 어떨까요? 그리고 콘솔찍을 때는 파일 이름 무조건 맨 앞에 [auth.ts] 이런 식으로 넣으면 어떨까 싶네요.
const fetchHasUserCheckedNBCardYn = createAsyncThunk<
  Http.Response.THasUserCheckedNbCardYn,
  void,
  AsyncThunkConfig
>('auth/privacy/fetchHasUserCheckedNBCardYn', async (_, thunkApi) => {
  try {
    const { data } = await instance.user.privacy.fetchHasUserCheckedNBCardYn();
    localStorage.setItem('hasNbcCard', String(data.nb_card_yn));
    return data;
  } catch (err: any) {
    console.error('0824 ', err);
    let message: string;
    Sentry.captureMessageWithAxiosError(
      '[slices/auth.ts][fetchFingerprint] getFingerprint()',
      err,
    );
    if (axios.isAxiosError(err)) {
      message = '내일배움카드 유/무 조회 에러';
    } else {
      message = '내일배움카드 유/무 조회 에러';
    }
    return thunkApi.rejectWithValue(message);
  }
});

// #endregion

/**************************************
 *! REDUX TOOLKIT: CREATE SLICE
 *************************************/
// assignNewTokenToStorageAndStore
// assignStorageTokenToStore
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: () => {
      clearStorageAndTimer(timeoutTimer);
      return { ...initialState, decodedToken: null, initialLoading: false };
    },
    assignNewTokenToStorageAndStore: (
      state: TInitialState,
      action: PayloadAction<{ token: string; shallow?: boolean }>,
    ) => {
      console.log('%c [result] here start', 'color: orange;');
      // 토큰 유효성 검사 - false일 경우 로그아웃과 동일 처리
      if (!validateToken(action.payload.token)) {
        console.log('%c [result] here1', 'color: orange;');
        clearStorageAndTimer(timeoutTimer);
        return { ...initialState, initialLoading: false };
      }
      localStorage.setItem(ACCESS_TOKEN, action.payload.token);
      console.log('%c [result] here2', 'color: orange;');
      // shallow를 사용하는 이유는 user를 변경하지 않기 위함이므로 user!로 사용하기로 (type error 때문)
      if (action.payload.shallow) {
        console.log('%c [result] here3', 'color: orange;');
        return {
          ...state,
          user: {
            ...state.user!,
            expirationTime:
              jwtDecode<User.TDecodedToken>(action.payload.token).exp * 1000,
          },
          token: action.payload.token,
          decodedToken: decodeToken(action.payload.token),
        };
      }
      console.log('%c [result] here4', 'color: orange;');
      return {
        ...state,
        token: action.payload.token,
        decodedToken: decodeToken(action.payload.token),
        initialLoading: true,
      };
    },
    assignStorageTokenToStore: (
      state: TInitialState,
      action: PayloadAction<{ shallow?: boolean } | undefined>,
    ) => {
      const token = localStorage.getItem(ACCESS_TOKEN);

      // shallow를 사용하는 이유는 user를 변경하지 않기 위함이므로 user!로 사용하기로 (type error 때문)
      if (action && action.payload?.shallow) {
        return {
          ...state,
          user: {
            ...state.user!,
            // 토큰 위에서 validate 되었으므로 ! 붙인다
            expirationTime: jwtDecode<User.TDecodedToken>(token!).exp * 1000,
          },
          token: token,
          decodedToken: decodeToken(token),
        };
      }
      return { ...state, token: token, decodedToken: decodeToken(token) };
    },
    setAttendanceToTrue: (state: TInitialState) => {
      return { ...state, hasAttendanceChecked: true };
    },
    setAttendanceToFalse: (state: TInitialState) => {
      return { ...state, hasAttendanceChecked: false };
    },
    startInitialLoading: (state: TInitialState) => {
      return { ...state, initialLoading: true };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<TInitialState>) => {
    builder
      //! login
      .addCase(login.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        localStorage.setItem(ACCESS_TOKEN, action.payload.token);
        return {
          ...state,
          loading: false,
          error: null,
          token: action.payload.token,
          decodedToken: decodeToken(action.payload.token),
        };
      })
      .addCase(login.rejected, (state, action) => {
        console.log('login rejected:', action.payload);
        if (action.payload) HotToast.error(action.payload);
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      })
      //! Fetch  login
      // 최초 로그인을 담당하는 액션이기 때문에 initialLoading을 변경하지 않는다.
      .addCase(fetchLogin.pending, (state) => {
        return { ...state, loading: true, error: null };
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        localStorage.setItem(ACCESS_TOKEN, action.payload.token);
        return {
          ...state,
          token: action.payload.token,
          decodedToken: decodeToken(action.payload.token),
          loading: false,
          error: null,
        };
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        console.log('login rejected:', action.payload);
        if (action.payload) HotToast.error(action.payload);
        localStorage.removeItem(ACCESS_TOKEN);
        return { ...state, loading: false, error: action.payload };
      })

      //! Refresh token with local_school_id
      .addCase(refreshTokenWithCampusId.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      // won't fetch /account if token has no campus ID and not agreed to terms and conditions
      // 토큰은 바뀌지만, local_school_id와 term_agreement_yn은 체크하여 initialLoading 여부를 결정한다.
      .addCase(refreshTokenWithCampusId.fulfilled, (state, action) => {
        localStorage.setItem(ACCESS_TOKEN, action.payload.token);
        const decodedToken = decodeToken(action.payload.token);
        return {
          ...state,
          loading: false,
          token: action.payload.token,
          decodedToken,
          initialLoading: Boolean(
            typeof decodedToken?.local_school_id === 'number' &&
              decodedToken?.local_school_id >= 1 &&
              decodedToken?.term_agreement_yn === true,
          ),
        };
      })
      .addCase(refreshTokenWithCampusId.rejected, (state, action) => {
        return { ...state, loading: false, error: action.payload };
      })

      //! Fetch account and attendance
      // user will mount on Redux store state and ready to use LMS
      // 사용자가 Redux의 user state에 장착되고 앱을 사용할 준비가 된다.
      .addCase(fetchAccountAndAttendance.pending, (state, action) => {
        return { ...state, loading: true };
      })
      .addCase(fetchAccountAndAttendance.fulfilled, (state, action) => {
        const user = fetchedUserDataToUserState(action.payload);
        return {
          ...state,
          user,
          loading: false,
          error: null,
          initialLoading: false,
        };
      })
      .addCase(fetchAccountAndAttendance.rejected, (state, action) => {
        return { ...state, loading: false, error: action.payload };
      })

      //! Fetch account info
      // attendance 정보는 가져오지 않고 순수하게 account 정보만 들고 오는 action
      .addCase(fetchAccountInfo.pending, (state, action) => {
        return { ...state, loading: true };
      })
      .addCase(fetchAccountInfo.fulfilled, (state, action) => {
        const user = fetchedUserDataToUserState(action.payload);
        return {
          ...state,
          user,
          loading: false,
          error: null,
          initialLoading: false,
        };
      })
      .addCase(fetchAccountInfo.rejected, (state, action) => {
        return { ...state, error: action.payload, loading: false };
      })
      //! Fetch has user checked true on NBCard page
      .addCase(fetchHasUserCheckedNBCardYn.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(fetchHasUserCheckedNBCardYn.fulfilled, (state, action) => {
        return { ...state, loading: false, hasNbcCard: action.payload };
      })
      .addCase(fetchHasUserCheckedNBCardYn.rejected, (state, action) => {
        if (action.payload) HotToast.error(action.payload);
        return { ...state, loading: false, error: action.payload };
      });
  },
});

/**************************************
 *! EXPORTS
 *************************************/

//* ASYNC THUNK ACTIONS
export { fetchHasUserCheckedNBCardYn };
//* ACTIONS
export const {
  logout,
  assignNewTokenToStorageAndStore,
  assignStorageTokenToStore,
  setAttendanceToTrue,
  setAttendanceToFalse,
  startInitialLoading,
} = authSlice.actions;
//* SELECTORS
export const selectAuth = (state: RootState) => state.auth;
export const selectToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectRole = (state: RootState) => state.auth.user?.role.preset; 
export const selectIsHijackMode = (state: RootState) => state.auth.isHijackMode;
export const selectErrorMessage = (state: RootState) => state.auth.error;
export const selectInitialLoading = (state: RootState) =>
  state.auth.initialLoading;
export const selectHasAttendanceChecked = (state: RootState) =>
  state.auth.user?.hasAttendanceChecked;
export const selectPhoneNumber = (state: RootState) =>
  state.auth.user?.phoneNumber;
export const selectExpirationTime = (state: RootState) =>
  state.auth.user?.expirationTime;
export const selectCurrentCampusId = (state: RootState) =>
  state.auth.decodedToken?.local_school_id;
export const selectUserId = (state: RootState) => state.auth.user?.userId;
export const selectDecodedToken = (state: RootState) => state.auth.decodedToken;

export default authSlice.reducer;

/**************************************
 *! NORMAL FUNCTIONS
 *************************************/

/**************************************
 ** TIMER
 *************************************/

let timeoutTimer: ReturnType<typeof setTimeout>;
/**
 * @param {number} exp in seconds
 */
export const initTimerWithExpInToken = () => {
  clearTimeout(timeoutTimer);
  const token = localStorage.getItem(ACCESS_TOKEN);
  if (!token) {
    HotToast.error(MESSAGE.AUTH.NO_TOKEN_EXIST);
    store.dispatch(logout());
    return;
  }
  const { exp } = jwtDecode<User.TDecodedToken>(token);
  const timeDiffInMilliSeconds = exp * 1000 - Date.now();
  timeoutTimer = setTimeout(() => {
    HotToast.error(MESSAGE.AUTH.TOKEN_EXPIRED);
    store.dispatch(logout());
  }, timeDiffInMilliSeconds);
};
