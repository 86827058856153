import React, { useEffect } from 'react';
import mobileTriggerWidthState from './recoil/atom/mobileTriggerWidthState';
import windowSizeState from './recoil/atom/windowSizeState';
import { useRecoilState } from 'recoil';

export default function MobileDetector({ mobileTriggerWidth }) {
  const [, setMobileTriggerWidth] = useRecoilState(mobileTriggerWidthState);
  const [, setWindowSize] = useRecoilState(windowSizeState);

  // NOTE: set mobile trigger width when mobileTriggerWidth prop change
  useEffect(() => {
    setMobileTriggerWidth(parseInt(mobileTriggerWidth, 10));
    return () => {};
  }, [mobileTriggerWidth, setMobileTriggerWidth]);

  // NOTE: set windowSizeState when window resize
  useEffect(() => {
    const onResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setWindowSize]);

  return null;
}
