import { useMemo } from 'react';

const notAllowedPathNameList = ['/signin', '/signup', '/checknbc'];

export default function usePathNameAllowedGNB(pathname: string) {
  const isPathNameAllowedGNB = useMemo(() => {
    return !notAllowedPathNameList.includes(pathname);
  }, [pathname]);

  return isPathNameAllowedGNB;
}
