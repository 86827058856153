import { instance } from '../provider';

/**
 * fetch rubric
 * @param {number} nodeId
 * @returns rubric of the target node
 */
export const getRubric = async (nodeId) => {
  try {
    const res = await instance.get(
      `/api-nodes/v1/${nodeId.toString()}/rubrics/`,
    );
    if (res.status === 200) {
      console.log('[API][KDC][getRubric] response:', res);
      return res;
    } else {
      return { error: { message: '평가내용을 불러오는데 실패했습니다.' } };
    }
  } catch (err) {
    console.error('[API][KDC][getRubric] err.stack', err.stack);
    console.error('[API][KDC][getRubric] err.response', err.response);
    return { error: { message: '평가내용을 불러오는데 실패했습니다.' } };
  }
};

/**
 * fetch submitted project list of users
 * @param {number} nodeId
 * @returns project list of the node
 */
export const getSubmittedList = async (nodeId) => {
  try {
    const res = await instance.get(`/api-evaluation/kdc/${nodeId.toString()}`);
    if (res.status === 200) {
      console.log('[API][LEGACY][getSubmittedList] response:', res);
      return res;
    } else {
      return { error: { message: '평가목록을 불러오는데 실패했습니다.' } };
    }
  } catch (err) {
    console.error('[API][KDC][getSubmittedList] err.stack', err.stack);
    console.error('[API][KDC][getSubmittedList] err.response', err.response);
    return { error: { message: '평가목록을 불러오는데 실패했습니다.' } };
  }
};
