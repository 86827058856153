export const HOME = '/';
export const CLASSROOM = '/classroom'; // done
export const CLASSROOM_WITH_ID = `${CLASSROOM}/:id`;
export const MAIN = CLASSROOM;

export const LOGIN = '/kdc/login';
export const ATTENDANCE = '/attendance'; // done
export const ACCOUNT = '/account'; // done
export const FIND_PASSWORD = '/find-password'; // done
export const RESET_PASSWORD = '/reset-password'; // done

export const SIGN_UP = '/signup'; // done

export const FIND_CLASS = '/findclass'; // done

export const COURSE_DETAIL = '/detail'; // done
export const COURSE_DETAIL_WITH_ID = `${COURSE_DETAIL}/:courseId/:slug`; // done
export const ACHIEVE_PRINT = '/achieve/print'; // done

export const SAMPLE_PAGE = '/sample'; // done
export const SAMPLE_PAGE_WITH_SLUG = `${SAMPLE_PAGE}/:slug`; // done

export const PURCHASE = '/purchase'; // done
export const PURCHASE_WITH_SLUG = `${PURCHASE}/:courseId/:slug`; // done
export const PURCHASE_CONFIRM = `${PURCHASE}/success/:courseId/:slug/:roundId`; // done

export const HIJACK = '/kdc/hijack'; // done
export const COACH_RUBRIC = '/rubric'; // done
export const COACH_ASSESSMENT = '/assessment'; // done

export const ADMIN_PAYMENT = '/admin/payment'; // done
export const ADMIN_PAYMENT_ITEM = `${ADMIN_PAYMENT}/:orderId`;

export const ADMIN_COURSE = '/admin/course'; // done
export const ADMIN_COURSE_ITEM = `${ADMIN_COURSE}/:courseId`;
export const ADMIN_COURSE_ROUND = `${ADMIN_COURSE}/:courseId/:roundId`;
export const ADMIN_COURSE_ROUND_ADD = `${ADMIN_COURSE}/add-round/:courseId`;
