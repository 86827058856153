import { instance } from '../provider';
import '@api/types/typedef';

/**
 * @returns
 */
export const getTodayNode = () => {
  return instance.get('/api-nodes/v2/today/');
};

/**
 * @param {number} pageNum
 * */
export const getTodayWaitNP = (pageNum) => {
  let params = new URLSearchParams();
  params.append('page', pageNum);
  return instance.get(`/api-nodes/waitnp/`, {
    params,
  });
};

/**
 *
 * @param {number} nodeId
 * @returns {Object} status: 0
 * @returns {Object.<String>} message
 */

export const activateNode = (nodeId) => {
  let formData = new FormData();
  formData.append('node_progrs_id', nodeId);
  return instance.post(`/api-node-progrs/activeUserNodeProgrsV2/`, formData);
};

/**
 * @typedef {Object} FootPrint
 * @property {Stage[]} stage_list
 * @property {statusCard} stat_card
 * @returns {FootPrint}
 */
export const getStageCategoryAPI = () => {
  return instance.get(`/api-stages/v2/`);
};

/**
 * @param {number} stageId ?<optional>
 * @param {number} pageNum
 **/
export const getNodeByStageIdAPI = (stageId, pageNum) => {
  let params = new URLSearchParams();
  if (stageId !== 0) {
    params.append('stage_id', stageId);
  }
  params.append('page', pageNum);
  return instance.get(`/api-stages/compnp/`, {
    params,
  });
};
