import { datadogRum } from '@datadog/browser-rum';

import { BUILD_MODE, BUILD_USAGE } from '@constants';

export function initDataDog() {
  const sessionReplaySampleRate = () => {
    if (BUILD_MODE === 'prod') {
      return 100;
    } else {
      return 100;
    }
  };

  const sessionSampleRate = () => {
    if (BUILD_MODE === 'prod') {
      return 100;
    } else {
      // TODO: 개발 확인 후 다시 0으로 수정
      return 100;
    }
  };

  //TODO: app 어떻게 만들지 정하기.
  datadogRum.init({
    applicationId: 'cd3b1130-adc3-4f0c-8ddd-33b963bf210d',
    clientToken: 'pube25a7a41dd2ba0cfe31bcf64a1c9cdcb',
    site: 'datadoghq.com',
    service: 'aiffel-lms',
    env: BUILD_MODE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: sessionSampleRate(),
    sessionReplaySampleRate: sessionReplaySampleRate(),
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap'],
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackSessionAcrossSubdomains: true,
  });

  datadogRum.startSessionReplayRecording();
}

type TDatadogSetUserInfo = {
  userId?: string;
  userName?: string;
  email?: string;
  [key: string]: unknown;
};
export function setUserInfo({
  userId,
  userName,
  email,
  ...props
}: TDatadogSetUserInfo) {
  datadogRum.setUser({
    id: userId,
    name: userName,
    email: email,
    ...props,
  });
}
