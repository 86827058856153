import { SVGProps } from 'react';

export const ModalCloseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7764 6.25818L16.4722 4.95398L11.3016 10.1245L6.1311 4.95398L4.8269 6.25818L9.99744 11.4287L4.8269 16.5992L6.1311 17.9034L11.3016 12.7329L16.4722 17.9034L17.7764 16.5992L12.6058 11.4287L17.7764 6.25818Z"
        fill="#1A1500"
      />
    </svg>
  );
};
