import axios, { AxiosRequestConfig } from 'axios';

import { API_HOST, ACCESS_TOKEN, TOKEN_EXPIRED } from '../constants';
import { getItem } from '@utils/Storage';

import store from '@store';
import { logout } from '@store/slices';

export const instance = axios.create({
  baseURL: `${API_HOST}`,
});
instance.interceptors.request.use((config: AxiosRequestConfig<any>) => {
  const token = `Bearer ${getItem(ACCESS_TOKEN)}`;
  if (config.headers === undefined) {
    config.headers = {};
  }
  config.headers!.Authorization = token;
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.detail === TOKEN_EXPIRED) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export const instanceWOT = axios.create({
  baseURL: `${API_HOST}`,
});
