import React from 'react';
import PropTypes from 'prop-types';
import { paginate } from './paginate';
import './pagination.scss';

export class Pagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {}

  componentWillMount() {
    const { items, itemCount, initialPage } = this.props;
    if (itemCount) {
      this.setPage(initialPage);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { items, initialPage, itemCount } = this.props;
    if (items !== prevProps.items) {
      this.setPage(initialPage);
    }

    if (itemCount !== prevProps.itemCount) {
      console.log('0804 componentWillMount2');
      this.setPage(initialPage);
    }
  }

  setPage(page) {
    const { items, itemCount, pageSize, maxPages } = this.props;
    let pager = this.state.pager;

    pager = paginate(itemCount, page, pageSize, maxPages);
    const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

    this.setState({ pager });
    this.props.onChangePage(pager.currentPage);
  }

  render() {
    const { labels, firstLastShown } = this.props;
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length <= 1) {
      console.log('0819 not showing ', pager);

      return null;
    }

    return (
      <div className="pagination-wrap">
        <ul className="pagination">
          {firstLastShown && (
            <li
              className={`page-item first ${
                pager.currentPage === 1 ? 'disabled' : undefined
              }`}
            >
              <a
                href={() => false}
                className="page-link"
                onClick={() => this.setPage(1)}
              >
                {labels.first}
              </a>
            </li>
          )}

          <li
            className={`page-item previous ${
              pager.currentPage === 1 ? 'disabled' : undefined
            }`}
          >
            <a
              href={() => false}
              className="page-link"
              onClick={() => this.setPage(pager.currentPage - 1)}
            >
              {labels.previous}
            </a>
          </li>
          {pager.pages.map((page, index) => (
            <li
              key={index}
              className={`page-item page-number ${
                pager.currentPage === page ? 'active' : undefined
              }`}
            >
              <a
                href={() => false}
                className="page-link"
                onClick={() => this.setPage(page)}
              >
                {page}
              </a>
            </li>
          ))}
          <li
            className={`page-item next ${
              pager.currentPage === pager.totalPages ? 'disabled' : undefined
            }`}
          >
            <a
              href={() => false}
              className="page-link"
              onClick={() => this.setPage(pager.currentPage + 1)}
            >
              {labels.next}
            </a>
          </li>
          {firstLastShown && (
            <li
              className={`page-item last ${
                pager.currentPage === pager.totalPages ? 'disabled' : undefined
              }`}
            >
              <a
                href={() => false}
                className="page-link"
                onClick={() => this.setPage(pager.totalPages)}
              >
                {labels.last}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  maxPages: PropTypes.number,
  labels: PropTypes.object,
  styles: PropTypes.object,
  disableDefaultStyles: PropTypes.bool,
  firstLastShown: PropTypes.bool,
};
Pagination.defaultProps = {
  items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  itemCount: 9,
  onChangePage: () => {},
  initialPage: 1,
  pageSize: 5,
  maxPages: 9,
  labels: {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
  },
  firstLastShown: false,
};
