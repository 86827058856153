import classNames from 'classnames';
import React from 'react';
import styles from './StepBadge.module.scss';

type TStepBadgeProps = {
  // TODO: 실제로 들어오는 강의 타입 값 확정되면 수정할 것.
  // label: 'project' | 'lecture';
  label: string;
  className?: string;
};

export const StepBadge = ({
  label = 'project',
  className,
}: TStepBadgeProps) => {
  return (
    <div className={classNames(styles.stepBadge, className)}>
      {label.toUpperCase()}
    </div>
  );
};
