/**
 * @author brandonwie
 * @created Oct 4, 2022
 *
 */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { request } from './client';

const { GET, POST, PUT, DELETE, PATCH } = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
} as const;

export const _http = {
  get: <T, D = void>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, D>> => request({ method: GET, url, ...options }),
  post: <T, D = void>(
    url: string,
    data: any,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, D>> =>
    request({ method: POST, url, data, ...options }),
  put: <T, D>(
    url: string,
    data: any,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, D>> =>
    request({ method: PUT, url, data, ...options }),
  patch: <T, D>(
    url: string,
    data: any,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, D>> =>
    request({ method: PATCH, url, data, ...options }),
  delete: <T, D>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T, D>> =>
    request({ method: DELETE, url, ...options }),
};

/** Object의 key, value pair를 이용해 FromData를 생성하여 반환합니다.
 *! Key 값은 무조건 Back-End의 API에서 사용하는 Key 값으로 설정해야 합니다.
 * @param payload key value pairs
 * @returns
 */

export const genFormData = (payload: {
  [key: string]: string | number | File | Blob | Boolean;
}) => {
  let f = new FormData();
  try {
    // eslint-disable-next-line array-callback-return
    Object.entries(payload).map(([k, v]) => {
      switch (typeof v) {
        case 'number':
          f.append(k, v.toString());
          break;
        case 'boolean':
          f.append(k, v.toString());
          break;
        case 'object':
          f.append(k, JSON.stringify(v));
          break;
        default:
          f.append(k, v);
          break;
      }
    });
  } catch (err) {
    // 'cause: unknown' only can have `unknown` or `any` type
    throw new Error('Generating form data failed', {
      cause: { ...(payload as any), err },
    });
  }
  return f;
};
