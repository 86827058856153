import { instance } from '../provider';

export const getUser = () => {
  return instance.get('/api-users/profileKdc/');
};

export const updateUserPassword = (oldPassword, newPassword1, newPassword2) => {
  const formData = new FormData();
  formData.append('old_password', oldPassword);
  formData.append('new_password1', newPassword1);
  formData.append('new_password2', newPassword2);
  return instance.post('/rest-auth/password/change/', formData);
};

export const updateUserPhoneNumber = (userId, newPhoneNumber, uniqueKey) => {
  const formData = new FormData();
  formData.append('phone_number', newPhoneNumber);
  formData.append('auth_key', uniqueKey);
  return instance.patch(`/api-users/privacy/${userId}/`, formData);
};

/**
 * Update Marketing Setting
 * @param {number} userId
 * @param {boolean} isMktAgreed
 * @returns {Promise<AxiosResponse<any>>} response
 */
export const updateMarketingAgreementStatus = (userId, isMktAgreed) => {
  const formData = new FormData();
  formData.append('mkt_agreement_yn', isMktAgreed);
  return instance.patch(`/api-users/privacy/${userId}/`, formData);
};

/**
 * Get All User Purchase History
 * @returns {Promise<AxiosResponse<any>>} response
 */
export const getAllPurchaseHistories = () => {
  return instance.get('/api-users/order/');
};
