import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SubHeaderProps } from '../SubHeader';
import { globalSubHeaderProps } from './recoil/atom';

export default function GlobalSubHeader(props: SubHeaderProps) {
  const setSubHeaderProps = useSetRecoilState(globalSubHeaderProps);
  const resetSubHeaderProps = useResetRecoilState(globalSubHeaderProps);

  useEffect(() => {
    setSubHeaderProps(props);
    return () => {
      resetSubHeaderProps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
}

export function useGlobalSubHeaderProps() {
  return useRecoilValue(globalSubHeaderProps);
}
