import classNames from 'classnames';
import React from 'react';
import './labelValueField.scss';

type TLabelValueFieldProps = {
  labelSize: string | any;
  labelTxt: string;
  valueSize: string | any;
  valueTxt: number | string;
  valueVariant?: string | any;
  discount?: number;
  price?: number;
  isFinalPrice?: boolean;
};

export const LabelValueField = ({
  labelSize,
  labelTxt,
  valueSize,
  valueTxt,
  valueVariant,
  discount,
  isFinalPrice,
  ...rest
}: TLabelValueFieldProps) => {
  const isPrice = valueVariant === 'discount' ? true : false;
  const kcreditDiscountTxt = `-${discount}% (고용노동부 지원)`;
  const formattedDiscountPrice = valueTxt.toLocaleString('ko-KR');

  const fullLabelTxt = isPrice
    ? kcreditDiscountTxt + ' ' + formattedDiscountPrice
    : valueTxt;

  return (
    <div className="field">
      <span className="field__label text-[12px] text-k-800 md:text-[14px]">
        {labelTxt}
      </span>
      <span
        className={classNames(
          'field__value text-[12px] text-y-800 md:text-[14px]',
          isFinalPrice === true && 'text-[14px] font-bold md:text-[18px]',
        )}
      >
        {valueVariant === 'price' && `${formattedDiscountPrice}원`}
        {valueVariant === 'discount' && `${fullLabelTxt}원`}
      </span>
    </div>
  );
};
