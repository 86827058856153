import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { BUILD_USAGE } from '@constants';
import routes from '@routes';
import { useAppSelector } from '@store/hooks';
import { selectUser } from '@store/slices';
import { User } from '@types';

import styles from './index.module.scss';

type ProfileButtonType = {
  onLogout?: () => void;
};

export default function ProfileButton({ onLogout }: ProfileButtonType) {
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const profileDropDownRef = useRef<HTMLDivElement>(null);
  const [isAccountMenuOpened, setIsAccountMenuOpened] = useState(false);
  const handleProfileClick = useCallback(() => {
    setIsAccountMenuOpened(!isAccountMenuOpened);
  }, [isAccountMenuOpened]);

  //#region Dropdown 바깥영역 클릭시 dropdown menu 닫도록 event binding
  useEffect(() => {
    const handleGlobalClick = (e: MouseEvent) => {
      if (
        profileDropDownRef.current &&
        !profileDropDownRef.current.contains(e.target as any)
      ) {
        setIsAccountMenuOpened(false);
        e.stopPropagation();
      }
    };
    window.addEventListener('click', handleGlobalClick, { capture: true });
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);
  //#endregion

  //#region 페이지 이동시 dropdown menu 닫도록
  useEffect(() => {
    setIsAccountMenuOpened(false);
    return () => {};
  }, [location]);
  //#endregion
  return (
    <>
      <button
        className={classNames(styles.profileButton, styles.accountIcon)}
        onClick={handleProfileClick}
      >
        <AccountIcon />
      </button>
      {isAccountMenuOpened && user && (
        <ProfileDropdownMenu
          ref={profileDropDownRef}
          user={user}
          onLogout={onLogout}
        />
      )}
    </>
  );
}

const ProfileDropdownMenu = React.forwardRef<
  HTMLDivElement,
  { user: User.TStoreUserState; onLogout?: () => void }
>(({ user, onLogout }, ref) => {
  return (
    <div className={styles.profileMenu} ref={ref}>
      <p className={styles.email}>{user.username}</p>
      <ul>
        {BUILD_USAGE === 'legacy' && (
          <Link to={routes.lms.ProfilePageRoute.path}>프로필</Link>
        )}
        {BUILD_USAGE === 'legacy' && user.viewForum && (
          <Link to={routes.common.TopicContainerPageRoute.path}>포럼</Link>
        )}
        <Link to={routes.common.AccountPageRoute.path}>계정설정</Link>
        <li onClick={onLogout}>로그아웃</li>
      </ul>
    </div>
  );
});

const AccountIcon = () => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.45724 12.0524C3.31436 13.1929 2.44029 14.7449 1.97491 16.5316C1.55729 18.1349 2.94324 19.5 4.6001 19.5L13.6001 19.5C15.257 19.5 16.6429 18.1348 16.2252 16.5314C15.4853 13.6908 13.7124 11.4435 11.4685 10.5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="9.1001" cy="6" r="4.5" strokeWidth="2" />
  </svg>
);
