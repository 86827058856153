/**
 * @author brandonwie
 * @created Sep 20, 2022
 */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { API_HOST, ACCESS_TOKEN, TOKEN_EXPIRED } from '@constants';
import store from '@store';
import { logout } from '@store/slices';

//! Create Axios Instance with interceptors
// 인터셉터들과 함께 악시오스 인스턴스 생성
const client = axios.create({ baseURL: API_HOST });
// REQUEST INTERCEPTOR
client.interceptors.request.use(
  (config) => {
    const token = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// RESPONSE INTERCEPTOR
client.interceptors.response.use(
  (response) => {
    // returning response.data will lose status codes; therefore, data property access will take place within the http functions
    return response;
  },
  (error) => {
    if (error.response.data.detail === TOKEN_EXPIRED) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

//! Define onSuccess and OnError behaviors
// 성공 및 오류 시 동작 정의
export const request = ({
  ...options
}: AxiosRequestConfig): Promise<AxiosResponse<any, any>> => {
  const onSuccess = (response: AxiosResponse) => {
    return { ...response };
  };
  const onError = (error: any) => {
    return Promise.reject(error);
  };
  return client(options).then(onSuccess).catch(onError);
};
