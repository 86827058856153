// NOTE: 아래는 기존 role 관련 key에 대한 상수
// export const KERNEL_AUTH_FIELD = 'localKernelOnly';
// export const PRESET_FIELD = 'preset';
// export const SCOPE_FIELD = 'scope';
// export const ROLE_FIELD = 'role';
// STUDENT: 'student',
// COACH: 'coach',
// EDITOR: 'editor',
// STAFF: 'staff',
// K_DIGITAL: 'k_digital',

//! enum을 Roles[] 처럼 type으로 지정하여 사용하는 경우에 value는 무조건 Roles.STUDENT처럼 enum의 member를 사용하여야 합니다. reference가 다른 경우 에러를 발생시킵니다.
export enum Roles {
  STUDENT = 'student',
  COACH = 'coach',
  EDITOR = 'editor',
  STAFF = 'staff',
  K_DIGITAL = 'k_digital',
}

//* CONSTANT로 쓰이는 ROLE과 Type으로 쓰이는 TRole이 구별되야 될 것으로 생각합니다.
export const ROLES = {
  STUDENT: 'student',
  COACH: 'coach',
  EDITOR: 'editor',
  STAFF: 'staff',
  K_DIGITAL: 'k_digital',
} as const;

export type TRoles = typeof ROLES[keyof typeof ROLES];
