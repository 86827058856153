import React from 'react';
import { Text, Divider, StarRating } from '../../../atoms';

import './projectrubric.scss';

export const ProjectRubric = ({ node, ratingScore, comment, ...props }) => {
  const rating = node.sum_score ? node.sum_score : 0;
  const isSubmitted = node.sum_score ? true : false;

  return (
    <div className="project-rubric">
      <div className="project-rubric-wrap">
        <div className="project-rubric-title">
          <Text label="프로젝트 피드백" opacity={0.5} />
          <div className="project-rubric-rating">
            {!node.sum_score && node.status === 'C' && '미제출'}
            {!node.sum_score && node.status === 'E' && '평가 대기중'}
            {node.sum_score && <StarRating rating={rating} />}
          </div>
        </div>
        <Divider direction="vertical" />
        <div>{isSubmitted && comment}</div>
      </div>
    </div>
  );
};

ProjectRubric.defaultProps = {
  comment: '코치님 평가',
  ratingScore: [
    { rubric_metric: '', score: 0 },
    { rubric_metric: '', score: 0 },
    { rubric_metric: '', score: 0 },
  ],
};
