import {
  KernelConnector,
  useKernelConnector as _useKernelConnector,
} from 'aiffel-jupyter-kernel';

export const kernelConnector = new KernelConnector();

export default function useKernelConnector() {
  return _useKernelConnector(kernelConnector);
}
