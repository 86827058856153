import { NodesPageRoute } from '../../routes/common';
import { withEvent } from '../../withEvent';

//TODO: 나중에 안쓰도록 바꿔야함
export const goBack = (navigate) => {
  if (typeof navigate.goBack === 'function') {
    navigate.goBack();
  } else if (typeof navigate === 'function') {
    navigate(-1);
  }
};

export const goToNodeDetail = (node, navigate, from) => {
  const nodeVersionId = node.node_schedule.node_version.id;
  withEvent({
    category: 'Otam',
    action: 'Enter node',
    label: from,
  });
  const target = NodesPageRoute.path
    .replace(':courseId', node.user_enrolments.enrol.course.id)
    .replace(':nodeId', nodeVersionId);
  if (typeof navigate.push === 'function') {
    navigate.push({
      pathname: target,
    });
  } else if (typeof navigate === 'function') {
    navigate(target);
  }
};
