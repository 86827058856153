import { Suspense } from 'react';
import { BUILD_USAGE } from '../../constants';
import LMSRoutes from './LMSRoutes';
import KDCRoutes from './KDCRoutes';
import OnlineRoutes from './OnlineRoutes';
import { KDCMetaTagWrapper } from './KDCMetaTagWrapper';
import LoadingPage from '@pages/Loading';

export default function AppRouter() {
  return (
    <Suspense
      fallback={<LoadingPage message="페이지를 불러오는 중입니다... 👾" />}
    >
      {BUILD_USAGE === 'legacy' && <LMSRoutes />}
      {BUILD_USAGE === 'kdc' && (
        <KDCMetaTagWrapper>
          <KDCRoutes />
        </KDCMetaTagWrapper>
      )}
      {BUILD_USAGE === 'online' && <OnlineRoutes />}
    </Suspense>
  );
}
