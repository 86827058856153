import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export type MessageBannerProps = { message: string } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export default function MessageBanner({
  message,
  className,
  ...props
}: MessageBannerProps) {
  return (
    <div {...props} className={classNames(styles.messageBanner, className)}>
      {message}
    </div>
  );
}
