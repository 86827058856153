import { LoadingLayerProps } from '@components/LoadingLayer';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { globalLoadingLayerProps } from './recoil/atom';

export default function GlobalLoadingLayer(props: LoadingLayerProps) {
  const setGlobalLoadingLayerProps = useSetRecoilState(globalLoadingLayerProps);
  const resetGlobalLoadingLayerProps = useResetRecoilState(
    globalLoadingLayerProps,
  );

  useEffect(() => {
    setGlobalLoadingLayerProps(props);
    return () => {
      resetGlobalLoadingLayerProps();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return null;
}

export function useGlobalLoadingLayerProps() {
  return useRecoilValue(globalLoadingLayerProps);
}
