import { Text } from '@stories/atoms';
import { StepCard } from '@stories/molecules';

import './node.scss';

export const Node = ({ prgrsData, onClick, ...props }) => {
  const dummy = prgrsData[0];
  console.log('0823 progrsData ', prgrsData, props);
  const nodeSchedule = dummy ? dummy.node_schedule : {};
  const stepList = dummy ? dummy.steps : [];

  const renderStepList = prgrsData.map((node, index) => {
    console.log('0823 node ', node);

    return (
      <div key={index}>
        <StepCard node={node} onClick={onClick} />
      </div>
    );
  });

  return (
    <div className="node-wrap">
      <div className="node-content">
        <div className="node-content__title">
          <Text label={`강의 리스트`} opacity={0.65} />
        </div>
        <div className="node-content__nodes">{renderStepList}</div>
      </div>
    </div>
  );
};

Node.defaultProps = {
  prgrsData: [],
};
