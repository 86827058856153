import React from 'react';
import PropTypes from 'prop-types';
import './text.scss';
import classNames from 'classnames';

type TTextProps = {
  label: string | number;
  variant?: 'primary' | 'secondary' | 'discount' | 'price';
  size?: 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

  // NOTE: 스타일 관련.
  className?: string;
  color?: string;
  opacity?: number;
  style?: React.CSSProperties;
};

export const Text = ({
  className,
  color,
  label,
  size,
  variant = 'primary',
  style,
  opacity,
  ...props
}: TTextProps) => {
  const labelValue = variant === 'price' ? label.toLocaleString() : label;
  return (
    <div
      className={classNames(
        'sb-text',
        `sb-text--${variant}`,
        `sb-text--${size}`,
        className,
      )}
      style={{ color, opacity, ...style }}
      {...props}
    >
      {labelValue}
      {variant === 'price' && '원'}
    </div>
  );
};
