/**
 * @author brandonwie
 * @edited Oct 7, 2022
 * @param payload {object} key value pairs
 * @returns {FormData} form data
 * @description 이전에 login에만 적용되던 부분은 더 general하게 변경, key는 반드시 백엔드 API에서 사용하는 key로 설정해야 함
 */
export const getFormData = (payload: { [key: string]: string }) => {
  console.log({ payload });
  let formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return formData;
};
