import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import LoadingPage from '@pages/Loading';

import styles from './PageContainer.module.scss';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  fallback?: boolean;
  fallbackMessage?: string;
  className?: string;
  center?: boolean;
}

const PageContainer: React.FC<PageContainerProps> = ({
  children,
  fallback,
  fallbackMessage,
  className,
  center,
}) => {
  if (fallback) {
    return (
      <LoadingPage
        message={
          fallbackMessage || '페이지에 필요한 정보를 불러오는 중입니다... 🤖'
        }
      />
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.center]: center },
        className,
      )}
    >
      {children}
    </div>
  );
};

export default PageContainer;
