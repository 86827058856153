import React from 'react';
import * as Sentry from '@sentry/browser';
import { BUILD_MODE } from '../../../constants';
import { Button } from '@online/aui';

const chunkErrorTitle = 'Loading chunk';

class PageExpired extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error Boundary error: ', error);
    console.log('Error Boundary error.name: ', error.name);
    console.log('Error Boundary errorInfo: ', errorInfo);

    console.error('Error Boundary Caught:', error, errorInfo);

    this.setState({ hasError: true, error });
    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(error, { extra: errorInfo });
    }

    if (error.toString().includes(chunkErrorTitle)) {
      // window.location.reload();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <div className={'grid h-[100vh] w-[100vw] items-center justify-center'}>
          <div className={'text-center'}>
            <div className={'text-[2rem] font-bold'}>
              Oooooo00ops!!{' '}
              <span role="img" aria-label="ooops icon">
                🤢
              </span>
            </div>
            {error.toString().includes(chunkErrorTitle) ? (
              <div>
                AIFFEL이 업데이트 되었습니다. 최신 버전 사용을 위해 새로고침
                부탁드립니다 :)
              </div>
            ) : (
              <div>오류가 발생했습니다!</div>
            )}
            {BUILD_MODE === 'dev' && (
              <p>{this.state.error?.toString() ?? ''}</p>
            )}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default PageExpired;
