import React from 'react';
import classnames from 'classnames';

import './card.scss';

type TCardProps = {
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;

  //NOTE: 스타일 관련?
  width?: string;
  height?: string;
  className?: string;

  onClick?: () => void;
};

export const Card = ({
  size = 'sm',
  onClick,
  width,
  height,
  className,
  children,
}: TCardProps) => {
  return (
    <div
      className={classnames(`sb-card`, `sb-card--${size}`, className)}
      onClick={onClick}
      style={{ width, height }}
    >
      {children}
    </div>
  );
};
