import { instance, instanceWOT } from '../provider';

// parameter들 breakdown 할 수 있는 것들은 최대한 잘게 나누기
export const loginForKdc = (payload) => {
  return instance.post('/token-kdc/', payload);
};

export const signUp = (payload) => {
  return instanceWOT.post('/api-users/signup/', payload);
};

export const checkIsEmailDuplicate = (email) => {
  return instanceWOT.get(`/api-users/signup/?email=${email}`);
};

export const checkUserDataUsingImpUid = (impUid) => {
  let data = new FormData();
  data.append('imp_uid', impUid);
  return instanceWOT.post(`/api-users/certifications/`, data);
};

/** get 6 digit verification code
 * @param {string} tel
 * */
export const getVerificationCode = (tel) => {
  const formData = new FormData();
  formData.append('phone_number', tel);
  return instance.post('/auth/', formData);
};

/** send verification code and compare with server's code
 * @param {string} tel
 * @param {string} verificationCode
 * */
export const compareVerificationCodes = (tel, verificationCode) => {
  return instance.get(
    `/auth/?phone_number=${tel}&auth_number=${verificationCode}`,
  );
};

export const authAttendance = () => {
  return instance.get(`/auth/attendance/`);
};
