import React from 'react';
import PropTypes from 'prop-types';
import './select.scss';

export const Select = ({
  label,
  selectList,
  isContainerActive,
  selectedRoundNumber,
  selectPlaceholder,
  handleOnOptionClick,
  handleOnLabelBoxClick,
  isError,
}) => {
  return (
    <div className="sb-select">
      {label && <div className="title">{label}</div>}
      <div className="selectBox">
        <div
          className={[
            'optionsContainer',
            `${isContainerActive ? 'active' : ''}`,
          ]
            .join(' ')
            .trim()}
        >
          {selectList.map((item, index) => {
            return (
              <div
                // TODO classname 공통으로 변경 필요.
                className="selectOption"
                key={index}
                onClick={handleOnOptionClick}
                value={item.value}
                data-value={item.label}
              >
                <input
                  className="radio"
                  type="radio"
                  id={item.value}
                  name="round"
                  value={item.value}
                  data-value={item.label}
                />
                <label
                  className="roundLabel"
                  htmlFor={item.value}
                  value={item.value}
                  data-value={item.label}
                >
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
        <div
          className={['roundSelected', `${isError && 'error'}`]
            .join(' ')
            .trim()}
          value={selectedRoundNumber}
          onClick={handleOnLabelBoxClick}
        >
          {selectPlaceholder}
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  isContainerActive: PropTypes.bool,
};

Select.defaultProps = {
  isContainerActive: false,
  selectList: [],
};
