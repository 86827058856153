import React from 'react';
import ReactDOM from 'react-dom';

const overlay = {
  height: '100%',
  width: '100%',
  position: 'fixed',
  StayInPlaceZIndex: '1',
  SitOnTopLeft: '0',
  top: '0',
  backgroundColor: 'rgba(0,0,0, 0.5)',
  BlackFallbackColorBackgroundColor: 'rgba(0,0,0, 0.9)',
  BlackWOpacityOverflowX: 'hidden',
  DisableHorizontalScrollTransition: '0.5s',
};

const overlayContent = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

export class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.overlayContainer = document.createElement('div');
    document.body.appendChild(this.overlayContainer);
  }

  componentWillUnmount() {
    document.body.removeChild(this.overlayContainer);
  }

  render() {
    return ReactDOM.createPortal(
      <div style={overlay}>
        <div style={overlayContent}>{this.props.children}</div>
      </div>,
      this.overlayContainer,
    );
  }
}
