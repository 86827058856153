import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { authReducer, kernelReducer } from './slices';

const store = configureStore({
  reducer: {
    auth: authReducer,
    kernel: kernelReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true }),
  devTools: {
    trace: true,
  },
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown, // extra thunk arg
  Action<string> // basic action
>;
