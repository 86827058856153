import { useAutoAnimate } from '@formkit/auto-animate/react';

const AnimatedBox: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const [box] = useAutoAnimate<HTMLDivElement>();
  return (
    <div ref={box} {...props}>
      {children}
    </div>
  );
};

export default AnimatedBox;
