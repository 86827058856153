import ReactGa from 'react-ga';
import { Roles } from '@constants';
import store from '@store';

/**
 * Implement Google Analytics
 * @param { Object } options.category
 * @param { Object } options.action
 * @param { Object } options.label
 */
export function withEvent(options) {
  // move inside function to prevent side effect
  const { auth } = store.getState();
  if (options) {
    try {
      // NOTE: 무조건 학생이어야 한다.
      const rolesPreset = auth?.user?.roles?.preset ?? [];
      if (
        !rolesPreset.includes(Roles.STAFF) &&
        !rolesPreset.includes(Roles.COACH) &&
        !rolesPreset.includes(Roles.EDITOR)
      ) {
        ReactGa.event({
          ...options,
        });
      }
    } catch (err) {
      console.log('event error', err);
    }
  } else {
    console.log('need options!');
  }
}

export function withEventTo(toUrl, options) {
  // fix: move getState() inside func to prevent side effect
  const { auth } = store.getState();
  if (options) {
    try {
      const rolesPreset = auth?.user?.roles?.preset ?? [];
      if (
        !rolesPreset.includes(Roles.STAFF) &&
        !rolesPreset.includes(Roles.COACH) &&
        !rolesPreset.includes(Roles.EDITOR)
      ) {
        ReactGa.event({
          ...options,
        });
      }
    } catch (err) {
      console.log('event error', err);
    }
  } else {
    console.log('need options!');
  }

  return toUrl;
}
