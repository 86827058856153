import {
  captureException as _captureException,
  captureMessage as _captureMessage,
  captureEvent as _captureEvent,
} from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { CaptureContext, Event } from '@sentry/types';
import axios from 'axios';
import { BUILD_MODE, BUILD_USAGE } from '@constants';
import store from '@store';

export function initSentry() {
  const buildEnv = () => {
    if (BUILD_MODE === 'prod') {
      return 'production';
    } else if (BUILD_MODE === 'dev') {
      return 'development';
    } else {
      return 'staging';
    }
  };

  const sampleRate = () => {
    if (BUILD_MODE === 'prod') {
      return 0.2;
    } else {
      return 1.0;
    }
  };

  const replaySessionSampleRate = () => {
    if (BUILD_MODE === 'prod') {
      return 0.1;
    } else {
      return 0;
    }
  };

  const replaysOnErrorSampleRate = () => {
    if (BUILD_MODE === 'prod') {
      return 1;
    } else {
      return 0;
    }
  };

  Sentry.init({
    dsn: 'https://3173d5b4c6bd4b2ba5e7d6c74aeb94dc@o427078.ingest.sentry.io/5370791',
    replaysSessionSampleRate: replaySessionSampleRate(),
    replaysOnErrorSampleRate: replaysOnErrorSampleRate(),
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    environment: buildEnv(),
    tracesSampleRate: sampleRate(),
    /**
     * NOTE: 현재 aiffel-jupyter-kernel 내 monaco 쪽에서 'ResizeObserver loop limit exceeded' 발생됨.
     * {monacoWrapperElement}.style.height에 값을 set하는 쪽에서 발생하는 데, 해당 코드에서 즉시 발생하는 에러는 아니고 global onerror 에서만 catch됨.
     * 해당 코드 자체에는 문제가 없는 것으로 생각이 되고, UI도 의도한대로 정상작동은 되므로 ignore 설정
     */
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

/**
 * user, axios 정보등을 주입시키는 Sentry.captureException wrapper
 * @param exception An exception-like object.
 * @param captureContext captureContext
 * @returns The generated eventId.
 */
export function captureException(
  exception: any,
  captureContext?: CaptureContext,
) {
  return _captureException(
    exception,
    generateCaptureContext(exception, captureContext),
  );
}

/**
 * 입력받은 message로 sentry report를 하는데, 입력받은 axios error 정보를 extra로 남긴다.
 * @param message
 * @param exception
 * @param captureContext
 * @returns
 */
export function captureMessageWithAxiosError(
  message: string,
  exception: any,
  captureContext?: CaptureContext,
) {
  return _captureMessage(
    message,
    generateCaptureContext(exception, captureContext),
  );
}

/**
 * user, axios 정보등을 주입시키는 Sentry.captureMessage wrapper
 *
 * @param message The message to send to Sentry.
 * @param captureContext captureContext
 * @returns The generated eventId.
 */
export function captureMessage(
  message: string,
  captureContext?: CaptureContext,
) {
  return _captureMessage(message, generateCaptureContext(null, captureContext));
}

/**
 * Captures a manually created event and sends it to Sentry.
 *
 * @param event The event to send to Sentry.
 * @returns The generated eventId.
 */
export function captureEvent(event: Event) {
  const username = store.getState().auth.user?.username;
  const userId = store.getState().auth.user?.userId;

  return _captureEvent({
    user:
      username && userId
        ? {
            username: username,
            id: userId.toString(),
          }
        : undefined,
    ...event,
  });
}

function generateCaptureContext(
  exception: any,
  captureContext?: CaptureContext,
): CaptureContext {
  //NOTE: Sentry maximum content size는 100KB
  //TODO: Sentry maximum content size 다시 체크
  const maxResponseDataLength = 100000;
  const username = store.getState().auth.user?.username;
  const userId = store.getState().auth.user?.userId;
  let extra = (captureContext as any)?.extra ?? {};
  const tags = { site: BUILD_USAGE, ...((captureContext as any)?.tags ?? {}) };

  if (axios.isAxiosError(exception)) {
    extra.request = exception.request;
    if (exception.response?.data) {
      const responseData =
        typeof exception.response.data === 'string'
          ? exception.response.data
          : JSON.stringify(exception.response.data);
      extra.responseData =
        responseData.length >= maxResponseDataLength + 3
          ? `${responseData.slice(0, maxResponseDataLength)}...`
          : responseData;
    }
  }
  return {
    tags,
    user:
      username && userId
        ? {
            username: username,
            id: userId.toString(),
          }
        : undefined,
    ...captureContext,
    extra,
  };
}
