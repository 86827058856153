import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Text, Card } from '../../atoms';
import BannerPrimary from '../../assets/banner-primary.svg';
import BannerSecondary from '../../assets/banner-secondary.svg';
import './banner.scss';

export const Banner = ({ className, title, type, btnTitle, bannerLink }) => {
  const navigate = useNavigate();
  const handleOnClick = (e) => {
    e.preventDefault();
    navigate(bannerLink);
  };

  return (
    <div className={classNames('banner', className)}>
      <div
        className="banner-bg"
        style={type === 'A' ? { cursor: 'pointer' } : { cursor: 'default' }}
      >
        {type === 'A' && (
          <img
            src={BannerPrimary}
            alt="primary banner"
            onClick={handleOnClick}
          />
        )}
        {type === 'B' && (
          <Card size="lg">
            <img src={BannerSecondary} alt="secondary banner" />
          </Card>
        )}
        <div className="banner-content">
          <div className="banner-title">
            <Text size="md" label={title} />
          </div>
        </div>
        {type === 'A' && (
          <div className="banner-btn">
            <a href={`${bannerLink}`}>
              <Text size="xs" label={btnTitle} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

Banner.defaultProps = {
  title: 'AIFFEL에서 더 많은 강의를 듣고 싶다면?',
  type: 'A',
  btnTitle: '강의 더 찾기로 이동!',
};
