import React, { useEffect } from 'react';
import LoadingPage from '@pages/Loading';
import styles from './index.module.scss';
import type { LoadingPageProps } from '@pages/Loading';
import classNames from 'classnames';

export type LoadingLayerProps = LoadingPageProps;

export default function LoadingLayer({
  className,
  message,
}: LoadingLayerProps) {
  /**
   * LoadingLayer가 표시되는 동안은 body의 scroll을 막는다.
   */
  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (bodyElement) {
      /* NOTE:
      여러개의 LoadingLayer가 중첩되어 render 될때를 고려하여,
      현재 컴포넌트가 mount될때 기존에 body element에서 holdScroll className을 가지고 있는 지를 저장하고,
      unmount시 원상복구 시켜준다.
      */
      const hasHoldScrollClassName = bodyElement.classList.contains(
        styles.holdScroll,
      );
      bodyElement.classList.add(styles.holdScroll);
      return () => {
        if (!hasHoldScrollClassName) {
          bodyElement.classList.remove(styles.holdScroll);
        }
      };
    }
    return () => {};
  }, []);

  return (
    <LoadingPage
      className={classNames(styles.loadingLayer, className)}
      message={message}
    />
  );
}
