import React from 'react';
import { Roles } from '@constants';
import { TRoute } from './TRoute';

const MainPage = React.lazy(() => import('../pages/lms/MainPage'));

const TodayPage = React.lazy(() => import('../pages/lms/StudentToday'));
const StagePage = React.lazy(() => import('../pages/lms/StudentFootprint'));
const EditorToday = React.lazy(() => import('../pages/lms/EditorToday'));
const EditorStage = React.lazy(() => import('../pages/lms/EditorFootprint'));

const CoachTodayPage = React.lazy(() => import('../legacy/pages/CoachTodayV2'));
const CoachStagePage = React.lazy(() => import('../legacy/pages/CoachFootprintV2'));
const CoachRubricPage = React.lazy(() => import('../legacy/pages/CoachRubricV2'));
const AssessmentPage = React.lazy(() => import('../legacy/kdc/Assessment/AssessmentPage'));

const AccountPageV2 = React.lazy(() => import('@legacy/kdc/Account/AccountPageV2'));
const AchievePage = React.lazy(() => import('../pages/lms/Achieve'));
const ProfilePage = React.lazy(() => import('../pages/lms/Profile'));
const AttendancePage = React.lazy(() => import('../pages/lms/Attendance'));

const DashboardUserPage = React.lazy(() => import('../legacy/pages/Dashboard/User'));
const DashboardUserDetailPage = React.lazy(() => import('../legacy/pages/Dashboard/UserProfile'));

const DashboardCloudPage = React.lazy(() => import('../legacy/pages/Dashboard/Cloud'));
const DashboardCloudPageV2 = React.lazy(() => import('../legacy/pages/Dashboard/Cloud/CloudV2'));

const DashboardCoursePage = React.lazy(() => import('../legacy/pages/Dashboard/Course'));
const DashboardCourseListPage = React.lazy(() => import('../legacy/pages/Dashboard/SchoolDetail'));
const DashboardCourseDetailPage = React.lazy(() => import('../legacy/pages/Dashboard/CourseDetail'));
const DashboardEnrolDetailPage = React.lazy(() => import('../legacy/pages/Dashboard/EnrolDetail'));

const NotFoundPage = React.lazy(() => import('../pages/NotFoundPage'));

/** MainPage */
// export const MainPageRoute: TRoute = { path: '/', component: MainPage };

//#region LMS Explorer 메뉴
/** LMS 오늘의탐험 */
export const TodayPageRoute: TRoute = { path: '/today', component: TodayPage, private: true, allowedPermissions: [Roles.STUDENT] };
/** LMS 나의발자취 */
export const StagePageRoute: TRoute = { path: '/stages', component: StagePage, private: true, allowedPermissions: [Roles.STUDENT] };
/** LMS 오늘의탐험(에디터용) */
export const TodayForEditorPageRoute: TRoute = { path: '/today-ed', component: EditorToday, private: true, allowedPermissions: [Roles.EDITOR] };
/** LMS 나의발자취(에디터용) */
export const StageForEditorPageRoute: TRoute = { path: '/stages-ed', component: EditorStage, private: true, allowedPermissions: [Roles.EDITOR] };
//#endregion

//#region coach 페이지
export const CoachTodayPageRoute: TRoute = { path: '/issues', component: CoachTodayPage, private: true, allowedPermissions: [Roles.COACH] };
export const CoachStagePageRoute: TRoute = { path: '/issues-all', component: CoachStagePage, private: true, allowedPermissions: [Roles.COACH] };
export const CoachRubricPageRoute: TRoute = { path: '/rubric', component: CoachRubricPage, private: true, allowedPermissions: [Roles.COACH] };
export const AssessmentPageRoute: TRoute = { path: '/assessment', component: AssessmentPage, private: true, allowedPermissions: [Roles.COACH] };
//#endregion

//#region 사용자 페이지
// export const AccountPageRoute: TRoute = { path: '/account', component: AccountPage, private: true };
export const AccountPageRoute: TRoute = { path: '/account', component: AccountPageV2, private: true };
export const AchievePageRoute: TRoute = { path: '/achieve', component: AchievePage, private: true, allowedPermissions: [Roles.STUDENT] };
export const ProfilePageRoute: TRoute = { path: '/profile', component: ProfilePage, private: true };
export const AttendancePageRoute: TRoute = { path: '/attendance', component: AttendancePage, private: true, allowedPermissions: [Roles.STUDENT], attendanceOnly: true }; // TODO: tax: allowedPermissions 확인하기 -1025
//#endregion

//#region 대시보드 페이지
export const DashboardUserRoute: TRoute = { path: '/dashboard/users', component: DashboardUserPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };
export const DashboardUserDetailRoute: TRoute = { path: '/dashboard/users/:id', component: DashboardUserDetailPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };

export const DashboardCloudRoute: TRoute = { path: '/dashboard/cloud', component: DashboardCloudPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };
export const DashboardCloudRouteV2: TRoute = { path: '/dashboard/cloud', component: DashboardCloudPageV2, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };

export const DashboardCourseRoute: TRoute = { path: '/dashboard/course', component: DashboardCoursePage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };
export const DashboardCourseListRoute: TRoute = { path: '/dashboard/schools/:id', component: DashboardCourseListPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };
export const DashboardCourseDetailRoute: TRoute = { path: '/dashboard/course/:id', component: DashboardCourseDetailPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };
export const DashboardEnrolDetailRoute: TRoute = { path: '/dashboard/enrols/:id', component: DashboardEnrolDetailPage, private: true, allowedPermissions: [Roles.COACH, Roles.STAFF] };

//#endregion

export const NotFoundPageRoute: TRoute = { path: '*', component: NotFoundPage };

//TODO: /legacy/router/legacy.js 포함 필요
