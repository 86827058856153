export const aiffelTheme = {
  colors: {
    main: {
      body: '#fff',
      text: '#000',
      progress: '#F4F6FC',
      buton: '#6181F7',
    },

    dark: {
      body: '#1e1f21',
      text: '#d9d9d9',
    },

    contentHeader: '#4f4f4f',
  },

  fonts: {
    GmarketM: 'Gmarket Sans',
    GmarketL: 'Gmarket Sans',
    Spoqa: 'Spoqa Han Sans',
  },

  sizes: {
    width: {
      max: '1300px',
    },

    fonts: {
      stepList: '14px',
      contentHeader: '25px',
    },
  },

  margins: {
    center: {
      auto: '0 auto',
    },
    zero: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      all: 0,
    },
  },

  paddings: {
    zero: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      all: 0,
    },
  },
};
