import React from 'react';
import PropTypes from 'prop-types';
import { Text, Card, Image, Link, Progress, Button } from '../../atoms';
import AuiButton from '@online/aui/Button';
import './nodecard.scss';
import { BUILD_USAGE } from '@constants';

export const NodeCardLg = ({
  category,
  title,
  url,
  startDate,
  endDate,
  progress,
  onClick,
}) => {
  const durationLabel = `${startDate} ~ ${endDate}`;

  return (
    <Card className="node-card--lg" size="lg" onClick={onClick}>
      <div className="sb-card__image">
        <Image size="lg" {...{ url }} />
      </div>
      <div className="sb-card__contents">
        <div className="sb-card__contents-top">
          <div>
            <Text size="xxs" variant="secondary" label={category} />
            <Text size="md" label={title} />
          </div>
          <div className="only-desktop">
            <Progress size="sm" progress={progress} />
          </div>
        </div>
        <div className="sb-card__contents-bottom">
          <div className="only-desktop">
            <Text size="xxs" variant="secondary" label={durationLabel} />
          </div>
          <div className="only-mobile">
            <Progress size="sm" progress={progress} />
          </div>
          <div>
            <Link variant="underline" />
          </div>
        </div>
      </div>
    </Card>
  );
};

NodeCardLg.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
NodeCardLg.defaultProps = {
  category: '쫄지말자 딥러닝',
  title: '쫄지 않는 파이썬 -1',
  url: 'https://via.placeholder.com/300',
  startDate: '',
  endDate: '',
};

export const NodeCardSm = ({
  type,
  count,
  url,
  onClick,
  tagLabel,
  title,
  desc,
  price,
  originPrice,
  discount,
  startDate,
  endDate,
  buyDate,
  achieveState,
  haveRecord,
  onClickPrintAchievement,
  isKCredit,
  ...props
}) => {
  const durationLabel = `${startDate} ~ ${endDate}`;
  const discountLabel = `-${discount}% (K-credit 지원)`;

  const formattedOriginPrice = originPrice.toLocaleString('ko-KR');
  const formattedPrice = price.toLocaleString('ko-KR');
  const discountPrice = price - price * (discount / 100);
  const formattedDiscountPrice = discountPrice.toLocaleString('ko-KR');
  const duration = type === 'A' ? `${startDate} ~ ${endDate}` : '';

  const isOnlineBuild = BUILD_USAGE === 'online';

  return (
    <Card className="node-card--sm" size="sm" onClick={onClick}>
      {achieveState === 'A' && <span className="sb-card__label">개강 전</span>}
      {achieveState === 'Z' && <span className="sb-card__label">개강 전</span>}
      {haveRecord && <span className="sb-card__label">이미 구매한 강의</span>}
      <div className="sb-card__image">
        <Image {...{ url }} />
      </div>

      <div className={`sb-card__contents`}>
        <div className="sb-card__desc-wrap">
          {type === 'A' && (
            <Text size="xxs" variant="secondary" label={`${count}회차`} />
          )}
          <Text size="sm" label={title} />
          {type === 'A' && (
            <Text size="xxs" variant="secondary" label={duration} />
          )}
          {type === 'B' && <Text size="xxs" variant="secondary" label={desc} />}
        </div>

        {type === 'A' && (
          <div className="sb-card__achieve-wrap">
            <div className="sb-card__achieve-wrap-bottom">
              <div className="sb-card-bottom__left">
                <Text size="xxs" label={`${buyDate} 구매함`} />
              </div>

              <div className="sb-card-bottom__right">
                {/* {achieveState === 'A' && <Text size="xxxs" label={'수료중'} />} */}
                {achieveState === 'C' && <Text size="xxxs" label={'수료중'} />}
                {/* NOTE: KDC 인 경우에는 H만 수료증 출력 가능 */}
                {isKCredit && achieveState === 'H' && (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={onClickPrintAchievement}
                  >
                    수료증 발급
                  </Button>
                )}
                {/* NOTE: Online 인 경우에는 G || H 일때  수료증 출력 가능 */}
                {!isKCredit &&
                  (achieveState === 'G' || achieveState === 'H') && (
                    <AuiButton onClick={onClickPrintAchievement}>
                      수료증 발급
                    </AuiButton>
                  )}
                {achieveState === 'I' && (
                  <Text
                    size="xxxs"
                    variant="negative"
                    label={'수료기간 지남'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {type === 'B' && (
          <div className="sb-card__price-wrap">
            <Text
              size="xxxs"
              variant="secondary"
              label={`${formattedOriginPrice}원`}
            />
            <div className="sb-card__price-wrap-bottom">
              <Text size="xs" label={`${formattedPrice}원`} />
              <Text size="xxxs" variant="discount" label={discountLabel} />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};

NodeCardSm.propsTypes = {
  type: PropTypes.oneOf(['A', 'B']),
  title: PropTypes.string,
  desc: PropTypes.string,
  price: PropTypes.number,
  onClick: PropTypes.func,
};

NodeCardSm.defaultProps = {
  type: 'A',
  title: '노드 제목',
  desc: '노드 요약 설명',
  price: 30000,
  originPrice: 300000,
  onClick: undefined,
  count: 0,
};
