import { BaseTypography, TypoProps } from '../Base';

export const Caption1 = (props: TypoProps) => {
  const { children, ...restProps } = props;

  return (
    <BaseTypography md="Caption1" {...restProps}>
      {children}
    </BaseTypography>
  );
};
