import React from 'react';
import NotFoundPage from '../pages/NotFoundPage';
import { TRoute } from './TRoute';

const ExpiredInvitationPage = React.lazy(() => import('@online/pages/ExpiredInvitation'));
const RegisterConfirmation = React.lazy(() => import('@online/pages/RegisterConfirmation'));
const Invitation = React.lazy(() => import('@online/pages/Invitation'));
/** 약관 페이지 */
/** 초대 유저 약관 동의 및 정보 입력 페이지 */
const TermsAndExtraInfoPage = React.lazy(() => import('@pages/TermsAndExtraInfo'));

export const InvitationRoute: TRoute = {
  path: '/invitation',
  component: Invitation,
  exact: true,
};
export const ExpiredInvitationPageRoute: TRoute = {
  path: '/expired-invitation',
  component: ExpiredInvitationPage,
  exact: true,
  publicOnly: true,
};
export const RegisterConfirmationRoute: TRoute = {
  path: '/register-confirmation',
  component: RegisterConfirmation,
  exact: true,
  publicOnly: true,
};
export const NotFoundPageRoute: TRoute = { path: '*', component: NotFoundPage };

// 초대 유저 정보 등록 페이지
export const TermsAndExtraInfoPageRoute: TRoute = {
  path: '/register-user-info',
  component: TermsAndExtraInfoPage,
};
