import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import './nbcbutton.scss';

interface TNBCButtonProps extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  bordered?: boolean;
  extraClassName?: string;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit';
  danger?: boolean;
}
export const NBCButton = ({
  title,
  bordered,
  extraClassName,
  onClick,
  loading,
  disabled,
  danger,
  type,
  ...props
}: TNBCButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        'nbcbutton',
        'w-full rounded border py-3 font-bold',
        extraClassName,
        bordered && 'bordered',
        danger && 'danger',
      )}
      disabled={disabled || loading}
      aria-disabled={disabled || loading}
      {...props}
    >
      {title}
    </button>
  );
};
