import store from '@store';
import {
  USER_TYPE_STUDENT,
  USER_TYPE_COACH,
  USER_TYPE_ETC,
  KERNEL_USAGE_LOCAL,
  KERNEL_USAGE_CLOUD,
  Roles,
} from '@constants';

export const convertStringToBool = (str) => {
  return str === 'true' ? true : false;
};

export const convertListNullToEmpty = (list) => {
  return list ? list : [];
};

export const isEmptyObjectV2 = (param) => {
  for (var prop in param) {
    if (param.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const isEmptyObject = (param) => {
  return (
    Object.keys(param).length === 0 &&
    JSON.stringify(param) === JSON.stringify({})
  );
};

/**
 *
 * @param {Object} dict
 * @param {string} key
 * @returns dict[key] | undefined
 */
export const getObjValue = (dict, key) => {
  if (dict && dict[key]) {
    return dict[key];
  }
  return;
};

export const checkIsAdmin = (userType) => {
  return userType.is_staff;
};

export const checkIsKDigital = (userType) => {
  return userType.k_digital;
};

export const checkUserType = (userType) => {
  if (userType) {
    if (userType.is_coach) {
      return USER_TYPE_COACH;
    } else if (userType.is_student) {
      return USER_TYPE_STUDENT;
    } else if (userType.is_editor) {
      return USER_TYPE_ETC;
    } else {
      return USER_TYPE_STUDENT;
    }
  } else {
    return USER_TYPE_STUDENT;
  }
};

/**
 *
 * @param {number} id
 * @param {string} username
 * @param {Object} user_type
 * @returns
 */
export function setUserType(id, username, user_type) {
  return {
    id,
    username,
    user_type,
  };
}

export const getNodeScheduleIdByUser = (node) => {
  const { auth } = store.getState();
  const rolesPreset = auth?.user?.roles?.preset;

  const isEditor = rolesPreset.includes(Roles.EDITOR);
  const isCoach = rolesPreset.includes(Roles.COACH);
  if (isEditor || isCoach) {
    return Array.isArray(node.node_progrs)
      ? node.node_progrs[0].node_schedule
      : node.node_progrs.node_schedule;
  }

  return node.node_schedule.id;
};

export const checkKernelType = (userType) => {
  return userType.is_cloud ? KERNEL_USAGE_CLOUD : KERNEL_USAGE_LOCAL;
};

export const checkCanUserHasCloud = (type) => {
  return type === KERNEL_USAGE_CLOUD ? true : false;
};

const queryString = require('query-string');
export const convertQueryStringToJson = (query) => {
  return queryString.parse(query);
};
