import { updateObject } from '../utility';
import { KERNEL_USAGE_LOCAL } from '@constants';
import { kernelActionTypes } from '@store/actionTypes';

const initialState = () => {
  return {
    kernelType: KERNEL_USAGE_LOCAL,
  };
};

const setUserKernelType = (state: any, action: any) => {
  return updateObject(state, {
    kernelType: action.kernelType,
    error: action.error,
  });
};

const kernelReducer = (state = initialState(), action: any) => {
  switch (action.type) {
    case kernelActionTypes.SET_USER_KERNEL_TYPE:
      return setUserKernelType(state, action);
    case kernelActionTypes.GET_USER_KERNEL_TYPE:
      return setUserKernelType(state, action);
    default:
      return state;
  }
};

export default kernelReducer;
