import React from 'react';
import PropTypes from 'prop-types';
import './Steps.scss';

export const Steps = ({ steps = [], activeIndex, className }) => {
  return (
    <div className={['sb-steps', className || ''].join(' ').trimEnd()}>
      {steps.map((step, i) => {
        return (
          <div
            key={i}
            className={['sb-steps__step', activeIndex == i ? 'active' : '']
              .join(' ')
              .trimEnd()}
          >
            {step}
          </div>
        );
      })}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
};
