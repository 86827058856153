import React from 'react';
import { LabelValueField } from '../LabelValueField/LabelValueField';
import './pricefield.scss';

type TPriceFieldProps = {
  originalPrice: number;
  discountRate: number;
  discountedAmount: number;
  finalPrice: number;
  difficulty: string | null;
  duration?: any;
};

export const PriceField = ({
  originalPrice,
  discountRate,
  discountedAmount,
  finalPrice,
  difficulty,
  duration,
}: TPriceFieldProps) => {
  console.log(
    originalPrice,
    discountRate,
    discountedAmount,
    finalPrice,
    difficulty,
    duration,
  );
  return (
    <div className="pricefield">
      {duration && difficulty && (
        <>
          <LabelValueField
            labelSize="xs"
            labelTxt={'강의 총 소요시간'}
            valueSize="xs"
            valueTxt={`${duration}분`}
          />
          <LabelValueField
            labelSize="xs"
            labelTxt={'난이도'}
            valueSize="xs"
            valueTxt={difficulty}
          />
        </>
      )}

      <div className="my-4 block border-b border-k-200" />

      <LabelValueField
        labelSize="xs"
        labelTxt="과정 금액"
        valueSize="xs"
        valueVariant="price"
        isFinalPrice={false}
        valueTxt={originalPrice}
      />

      <LabelValueField
        labelSize="xs"
        labelTxt="지원 금액"
        valueSize="xs"
        valueVariant="discount"
        isFinalPrice={false}
        discount={discountRate}
        valueTxt={discountedAmount}
      />

      <LabelValueField
        labelSize="xs"
        labelTxt="최종 금액"
        valueSize="sm"
        valueVariant="price"
        isFinalPrice={true}
        valueTxt={finalPrice}
      />
    </div>
  );
};

// PriceField.defaultProps = {
//   originalPrice: 10000,
//   discountRate: '90',
//   discountedAmount: 9000,
//   finalPrice: 1000,
//   duration: '60시간',
//   difficulty: '중급',
// };
