import classNames from 'classnames';
import React from 'react';
import { TextField } from '../../../atoms';
import { Modal } from '../../../molecules';
import './completionModal.scss';

export const CompletionModal = ({
  courseTitle,
  round,
  startDate,
  endDate,
  nodeCount,
  studyTime,
  fullname,
  onChangeEngName,
  onClickClose,
  printAchievement,
  isKCredit,
  ...props
}) => {
  return (
    <Modal
      title="수료증 발급"
      buttonTitle="수료증 출력"
      onClickClose={onClickClose}
      onClickConfirm={printAchievement}
    >
      <table
        className={classNames('courseCertiTable', isKCredit ? 'kdc' : 'online')}
      >
        <thead>
          <tr>
            <th>강의명</th>
            <th>영문 이름</th>
            <th>국문 이름</th>
            <th>수료기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {courseTitle}
              <br />
              {round}회차
            </td>
            <td className="engNameField">
              <TextField
                size="xs"
                placeholder="영문 이름"
                onChange={onChangeEngName}
              />
            </td>
            <td>{fullname}</td>
            <td>
              {startDate} ~ {endDate}
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

CompletionModal.defaultProps = {
  fullname: '유원선',
  round: 1,
  courseTitle: '쫄지말자 딥러닝',
  startDate: '2021-09-01T00:00:00.000',
  endDate: '2021-09-01T00:00:00.000',
};
