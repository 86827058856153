import { Route, Routes } from 'react-router-dom';
import routes from '../../routes';
import PublicOnly from './PublicOnly';
import RequiredAuth from './RequireAuth';

const renderingRoutes = [
  routes.kdc.LandingPageRoute,
  routes.kdc.TermsPageRoute,
  routes.kdc.FindClassPageRoute,
  routes.kdc.CheckNBCPageRoute,
  routes.kdc.CourseApplyPageRoute,
  routes.kdc.CourseAPplyConfirmPageRoute,

  routes.kdc.PurchasePageRoute,
  routes.kdc.PurchaseConfirmPageRoute,
  routes.kdc.AssessmentPageRoute,

  routes.common.FindPasswordPageRoute,
  routes.common.ResetPasswordPageRoute,
  routes.common.AttendancePageRoute,
  routes.common.NodesPageRoute,
  routes.common.StepsPageRoute,
  routes.common.ReadonlyStepsPageRoute,
  routes.common.ReleaseNotePageRoute,
  routes.common.PrintPageRoute,
  routes.common.ClassroomPageRoute,
  routes.common.ClassroomDetailPageRoute,
  routes.common.AccountPageRoute,
  routes.common.HijackPageRoute,
  routes.common.RedirectPageRoute,

  /** 내일배움클래스 상세페이지 */
  routes.kdc.PythonBascisRoute,
  routes.kdc.MetaverseAiBasicRoute,
  routes.kdc.MlBasicRoute,
  routes.kdc.DataAbcRoute,
  routes.kdc.DlTrainingRoute,
  routes.kdc.DlTheoryRoute,
  routes.kdc.KagglerDaRoute,
  /** 공지사항 */
  routes.common.NoticeBoardPageRoute,
  routes.common.NoticePostPageRoute,

  /** 통합 로그인 페이지 */
  routes.common.IntegratedSignInPageRoute,
  routes.common.IntegratedSignUpPageRoute,
  routes.common.SelectCampusPageRoute,
  routes.common.RegisterInvitedUserPageRoute,

  /** Terms And Conditions */
  routes.common.IntergratedPrivacyPolicyPageRoute,
  routes.common.IntergratedTermsPageRoute,
  routes.common.IntergratedRefundPolicyPageRoute,
  routes.common.IntergratedMarketingPageRoute,
  /** Not Found */
  routes.kdc.NotFoundPageRoute,
];

export default function KDCRoutes() {
  return (
    <Routes>
      {renderingRoutes.map((route, key) => {
        const Component: React.FC = () => {
          // private 부분은 추후에 그 이름이 type B로 변경되거나, A,B가 모두 고려된 적절한 segregation 기준명을 정하여 보강해야 함
          if (route.private || route.allowedPermissions) {
            return (
              <RequiredAuth allowedRoles={route.allowedPermissions}>
                <route.component />
              </RequiredAuth>
            );
          }

          if (route.publicOnly) {
            return (
              <PublicOnly>
                <route.component />
              </PublicOnly>
            );
          }
          return <route.component />;
        };

        return <Route key={key} path={route.path} element={<Component />} />;
      })}
    </Routes>
  );
}
