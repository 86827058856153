/***************************
 *! SIGN UP 회원가입
 **************************/

//* GENERAL
export const SIGN_UP_ERROR = '회원가입에 실패했습니다.';
export const INVALID_VALUE_SIGN_UP_FAIL =
  '중복된 이메일 혹은 잘못된 입력값이 있습니다.';

//* PHONE
export const PHONE_VERIFICATION_NEEDED = '휴대폰 인증이 필요합니다.';
export const PHONE_VERIFICATION_CANCELLED =
  '휴대폰 인증이 완료되지 않았습니다.';
export const PHONE_VERIFICATION_SUCCESS = '휴대폰 인증이 완료되었습니다.';
export const PHONE_VERIFICATION_FAILED = '휴대폰 인증에 실패했습니다.';
export const PHONE_REQUIRED = '휴대폰 번호는 필수입력 항목입니다.';
export const PHONE_INVALID = '휴대폰 번호를 확인해주세요.';

//* EMAIL
export const EMAIL_REQUIRED = '이메일은 필수입력 항목입니다.';
export const EMAIL_INVALID = '이메일 형식을 확인해주세요.';
export const EMAIL_ALREADY_EXISTS = '이미 사용중인 이메일입니다.';
export const EMAIL_DUPLICATE_CHECK_FAILED = '이메일 중복체크를 해주세요.';

//* PASSWORD
export const PREV_PASSWORD_REQUIRED = '이전 비밀번호는 필수입력 항목입니다.';
export const NEW_PASSWORD_REQUIRED = '변경할 비밀번호는 필수입력 항목입니다.';
export const NEW_PASSWORD_CONFIRM_REQUIRED =
  '변경할 비밀번호 확인은 필수입력 항목입니다.';
export const PASSWORD_REQUIRED = '비밀번호는 필수입력 항목입니다.';
export const PASSWORD_RULE = '영문/숫자/특수문자 중 2가지 이상 포함 10자 이상';
export const PASSWORD_SPECIAL_CHARACTER_RULE =
  '특수문자는 !@#$%^&*_만 가능합니다.';
export const PASSWORD_CONFIRM_REQUIRED = '비밀번호 확인은 필수입력 항목입니다.';
export const PASSWORD_CONFIRM_DIFFERENT = '비밀번호가 일치하지 않습니다.';
export const PASSWORD_CHANGE_FAIL = '비밀번호 변경에 실패했습니다.';

//* TERMS AND CONDITIONS
export const TERMS_AND_CONDITIONS_UNCHECKED = '약관동의가 필요합니다.';

/***************************
 *! LOGIN 로그인
 **************************/

//* ID & PASSWORD
export const LOGIN_FAIL = '아이디 또는 비밀번호를 확인해주세요.';
export const SERVER_LOGIN_FAIL = '로그인에 실패했습니다.';
export const UNEXPECTED_LOGIN_FAIL =
  '로그인에 실패했습니다. 관리자에게 문의해주세요.';

//* TOKEN
export const FETCH_ACCOUNT_FAIL =
  '인증정보가 유효하지 않습니다. 다시 로그인 해주세요.';
export const SERVER_FETCH_ACCOUNT_FAIL =
  '계정정보를 서버에서 받아오지 못했습니다.';
export const UNEXPECTED_FETCH_ACCOUNT_FAIL = 'Unexpected fetch account fail';

/**************************
 *! FINGERPRINT 핑거프린트
 **************************/

export const FETCH_FINGERPRINT_FAIL =
  '접속정보를 확인할 수 없습니다. 광고차단 애드온이나 VPN을 사용 중인지 확인해주세요.';
export const SERVER_FETCH_FINGERPRINT_FAIL =
  '접속정보를 확인할 수 없습니다. 잠시 후 다시 시도해주세요.';
export const UNEXPECTED_FETCH_FINGERPRINT_FAIL =
  '접속정보를 확인할 수 없습니다. 관리자에게 문의해주세요.';

/***************************
 *! ATTENDANCE 출석체크
 **************************/

export const FETCH_ATTENDANCE_FAIL = '출석체크 여부를 확인할 수 없습니다.';
export const SERVER_FETCH_ATTENDANCE_FAIL =
  '출석체크 여부를 확인할 수 없습니다. 잠시 후 다시 시도해주세요.';
export const UNEXPECTED_FETCH_ATTENDANCE_FAIL =
  '출석체크 여부를 확인할 수 없습니다. 관리자에게 문의해주세요.';

/***************************
 *! ATTENDANCE 회원가입
 **************************/

export const SERVER_SIGN_UP_FAIL =
  '회원가입을 하지 못했습니다. 잠시 후 다시 시도해주세요.';
export const SIGN_UP_FAIL = '회원가입에 실패했습니다.';
export const UNEXPECTED_SIGN_UP_FAIL =
  '회원가입을 하지 못했습니다. 관리자에게 문의해주세요.';
export const SIGN_UP_SUCCESS = '회원가입에 성공했습니다.';

/***************************
 *! CHANNEL TALK 채널톡
 **************************/

export const CHANNEL_TALK_INIT_FAIL =
  '채널톡 서비스를 초기화하는데 실패했습니다.';

/***************************
 *! AUTHENTICATION 인증정보
 **************************/
export const NO_TOKEN_EXIST = '인증정보를 찾지 못했습니다.';
export const TOKEN_EXPIRED = '인증정보가 만료되었습니다.';
export const NO_TOKEN_OR_EXPIRED = '인증정보가 만료되었거나 없습니다.';

/***************************
 *! REFRESH TOKEN 캠퍼스 토큰
 **************************/
export const REFRESH_TOKEN_FAIL = '캠퍼스 인증정보 갱신에 실패했습니다.';
export const REFRESH_TOKEN_UNEXPECTED_FAIL =
  '인증정보 갱신에 실패했습니다. 관리자에게 문의해주세요.';

/***************************
 *! ATTENDANCE 출석체크
 **************************/
export const ATTENDANCE_CHECK_SUCCESS = '출석체크가 완료되었습니다.';
export const VALIDATION_CODE_FAIL = '인증번호를 확인해주세요.';
export const ATTENDANCE_CHECK_SERVER_FAIL =
  '인증을 완료하지 못했습니다. 잠시 후 다시 시도해주세요.';
export const ATTENDANCE_CHECK_UNEXPECTED_FAIL =
  '인증을 완료하지 못했습니다. 관리자에게 문의해주세요.';
export const ATTENDANCE_CODE_SENT =
  '인증번호가 발송되었습니다.\n인증번호가 오지 않는 경우,\n 통신사에 070 번호차단 서비스 해제를 부탁드립니다.';
export const ATTENDANCE_CODE_SENT_FAIL = '인증번호 발송에 실패했습니다.';
