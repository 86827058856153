import { atom } from 'recoil';

const windowSizeState = atom({
  key: 'deviceSizeState',
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
});

export default windowSizeState;
