import { BUILD_USAGE, KDC_MD_URL_SET, ONLINE_MD_URL_SET } from '@constants';
import { Terms } from '@types';

export const getTermsAndConditionMarkdownUrl = (termType: Terms.TTerms) => {
  switch (BUILD_USAGE) {
    case 'kdc':
      return KDC_MD_URL_SET[termType];
    case 'online':
      return ONLINE_MD_URL_SET[termType];
    default:
      return '';
  }
};
