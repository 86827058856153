import { useEffect, useRef, useState } from 'react';

type TLoaderProps = {
  text: string;
  fontSize?: number;
};

type TLetterAnimationConfig = {
  letter: string;
  variable: number;
  size: number;
  ca: number;
  lineHeight: number;
};
export const MotifLoader = ({ text, fontSize = 64 }: TLoaderProps) => {
  const animationRef = useRef<number>(0);
  const loadinText = text.split('');

  const originList: TLetterAnimationConfig[] = [];
  const [orgList, setOrgList] = useState<any[]>([]);

  useEffect(() => {
    loadinText.forEach((letter, i) => {
      originList.push({
        letter: letter,
        variable: 500 + (i * 200) / 8,
        size: fontSize,
        lineHeight: 1.5,
        ca: 500 + (i * 200) / 8,
      });
    });
    setOrgList(originList);
  }, []);

  useEffect(() => {
    const animate = (time: any) => {
      const ctime = time * 0.001 * 3;

      originList.forEach((item: any, index: number) => {
        setOrgList((prev) => {
          return prev.map((prevItem, prevIndex) => {
            if (prevIndex === index) {
              return {
                ...prevItem,
                ca:
                  500 +
                  (item.variable - 500) * Math.abs(Math.sin(ctime - index / 5)),
              };
            }
            return prevItem;
          });
        });
      });
      animationRef.current = requestAnimationFrame(animate);
    };

    animationRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  return (
    <div>
      <MotifLoaderWrap orgList={orgList} />
    </div>
  );
};

type TLoaderWrapProps = {
  orgList: TLetterAnimationConfig[];
};
export const MotifLoaderWrap = ({ orgList }: TLoaderWrapProps) => {
  return (
    <div>
      {orgList.map((item, index) => {
        return (
          <span
            key={index}
            style={{
              fontFamily: 'Motif',
              fontSize: `${item.size}px`,
              lineHeight: item.lineHeight,
              fontVariationSettings: `"wght" ${item.ca}`,
            }}
          >
            {item.letter}
          </span>
        );
      })}
    </div>
  );
};
