import PropTypes from 'prop-types';
import classNames from 'classnames';

import './image.scss';

type TImageProps = {
  variant?: string;
  color?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  size?: string;
  label?: string;
  alt?: string;
  url: string;
};

export const Image = ({
  variant,
  color,
  backgroundColor,
  width,
  height,
  size,
  label,
  alt,
  url,
  ...props
}: TImageProps) => {
  return (
    <img
      className={classNames('sb-img', `sb-img--${size}`)}
      height={height}
      width={width}
      src={url}
      alt={alt}
      {...props}
    />
  );
};

Image.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.string,
};

Image.defaultProps = {
  size: 'sm',
  url: 'https://via.placeholder.com/300',
  alt: 'img alt 입니다.',
  height: '240',
};
