import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '@store/hooks';
import { selectDecodedToken } from '@store/slices';
import routes from '@routes';
import { useEffect } from 'react';

interface PublicOnlyProps {
  children: JSX.Element;
}
// TODO: DecodedToken이 없는 경우만 children render 이름 추후 고민
export default function PublicOnly({ children }: PublicOnlyProps) {
  const navigate = useNavigate();
  const decodedToken = useAppSelector(selectDecodedToken);

  useEffect(() => {
    if (decodedToken) {
      navigate(routes.common.SelectCampusPageRoute.path);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken]);

  // Token A, B 는 SelectCampusPage에서 처리하도록 하고 일단 localStorage에 토큰이 있으면 바로 SelectCampusPage로 보냄
  return children;
}
