export const PRE_TEST_GRADES = {
  없음: '코딩은 말로만 들어봤다',
  초급: '코딩을 시도해보긴 했다',
  중급: '나는 한 가지 언어를 제법 잘 다룬다',
  고급: '나는 여러 언어를 다뤄봤고, 새로운 언어도 금방 습득할 수 있다',
};

export const PRE_TEST_ANIMALS = {
  호기심: '오소리',
  열정: '캥거루',
  상생: '멧밭쥐',
  의지: '거북이',
};

export const PRE_TEST_ENG = {
  WIL: '의지',
  COR: '호기심',
  PAS: '열정',
  WIN: '상생',
};

export const PRE_TEST_INTERESTS = [
  `머신러닝`,
  `데이터 엔지니어링`,
  `아직은 모르겠다`,
];

export const ANIMAL_ICON_BASE_URL =
  'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/img/symbol';
