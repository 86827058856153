import PropTypes from 'prop-types';

import { Title, TextField, Button } from '@stories/atoms';
import { TextFieldWithButton } from '@stories/molecules';

import './attendancetemplate.scss';

export const AttendanceTemplate = ({
  onSubmit,
  onChange,
  handleTokenButtonClick,
  isSixDigitEntered,
  isPhoneNumberEntered,
  isTokenLoading,
  tel,
  verificationToken,
}) => {
  return (
    <div className="sb-attendance">
      <Title size="lg" label="출석체크" />
      <form onSubmit={onSubmit} className="sb-attendance__form">
        <TextFieldWithButton
          type="tel"
          size="md"
          label="전화번호"
          placeholder="- 없이 11자리 입력"
          buttonLabel={isTokenLoading ? '진행 중' : '발송하기'}
          isTextFieldDisabled={isTokenLoading || isPhoneNumberEntered}
          isButtonDisabled={!isPhoneNumberEntered || isTokenLoading}
          onChange={onChange}
          onClick={handleTokenButtonClick}
          name="tel"
          value={tel}
        />
        <TextField
          type="text"
          size="md"
          label="인증번호"
          placeholder="문자로 발송된 인증번호 6자리를 입력해주세요."
          name="token"
          onChange={onChange}
          inputMode="numeric"
          value={verificationToken}
          disabled={!isTokenLoading}
        />
        <Button
          type="submit"
          variant="primary"
          size="lg"
          disabled={!isSixDigitEntered}
        >
          인증번호 확인
        </Button>
      </form>
    </div>
  );
};

AttendanceTemplate.propsTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isTokenSent: PropTypes.bool,
  isSixDigitEntered: PropTypes.bool,
};

AttendanceTemplate.defaultProps = {
  onClick: undefined,
  onChange: undefined,
  isTokenLoading: false,
  isSixDigitEntered: false,
};
