import { atom } from 'recoil';
import { ModalProps } from '../../modalManager';
export default atom<Modal[]>({
  key: 'modal-manager-modals-state',
  default: [],
});

export interface Modal<T = any> extends ModalProps<T> {
  key: string;
  component: React.ComponentType<ModalProps>;
  close: (data: T) => void;
}
