import { ChatGPTIcon } from '@stories/assets';
import { X } from 'phosphor-react';
import React from 'react';

type TKDTBannerProps = {
  title?: string;
  message?: string;
  link: string;
  onClickHideBanner?: (e: any) => void;
};

type TReferralText = {
  id: number;
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
};
const REFERRAL_TEXT_KOR_LIST: TReferralText[] = [
  {
    id: 1,
    title: <p className={'font-bold'}>지금 야근 중이라면?! 🫠</p>,
    subTitle: '챗GPT 활용하고 칼퇴하기 🏃',
  },
  {
    id: 2,
    title: (
      <p className={'flex items-center font-bold'}>
        쿵 하면 짝!👏 척하면 챗!{' '}
        <span className={'inline-block h-4 w-4'}>
          <ChatGPTIcon />
        </span>
      </p>
    ),
    subTitle: '챗GPT로 스킬 UP 하기 🤸',
  },
  {
    id: 3,
    title: <p className={'font-bold'}>챗GPT와 오늘부터 1일 ❣️</p>,
    subTitle: '챗GPT와 협업하기 🤝',
  },
  {
    id: 4,
    title: <p className={'font-bold'}>생성형 AI 월드컵 ⚽</p>,
    subTitle: '나랑 가장 잘 맞는 Tool은?! 💘',
  },
];

const randomizeReferralText = (list: TReferralText[]) => {
  const randomIndex = Math.floor(Math.random() * list.length);
  return list[randomIndex];
};

export const ONLINEBanner = ({ link, onClickHideBanner }: TKDTBannerProps) => {
  const referralText = randomizeReferralText(REFERRAL_TEXT_KOR_LIST);

  return (
    <a
      className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#dddddd] to-[#ffdb3d] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#dddddd] to-[#ffdb3d] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900 md:block">
          {referralText.title}
        </p>
        <a
          id={`online-referral-${referralText.id.toString()}`}
          href={link}
          className="hidden flex-none rounded-md bg-gray-900 px-3.5 py-1 text-sm font-semibold text-[#ffdb3d] shadow-sm hover:bg-gray-700 hover:text-[#ffdb3d] hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900 sm:block"
          target="_blank"
          rel="noreferrer"
        >
          {referralText.subTitle}
        </a>
      </div>
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          onClick={onClickHideBanner}
        >
          <span className="sr-only">Dismiss</span>
          <X size={32} className={'h-5 w-5 text-gray-900'} />
        </button>
      </div>
    </a>
  );
};
